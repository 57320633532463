import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {Col, View} from '@unthinkable/react-core-components';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {TextRenderer} from '../../../components/form/Renderers';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {TableHeader} from '../../../components/table/Headers';
import {Tag} from '../../../components/chip/Chip';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {useTheme} from '@unthinkable/react-theme';
import {useAppStateContext} from '../../../providers/AppState';
import {IconButton} from '../../../components/button/IconButton';
import {getNumberOfLineStyle} from '@unthinkable/react-core-components/src/style-utility';

const RenderTrainer = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'row', alignItems: 'center'}}>
      <UserAvatar value={row?.mentor} size={25} />
      <View style={{gap: 4, flexDirection: 'column'}}>
        <TextRenderer value={row?.mentor?.name} />
        <GroupContentItem value={row?.mentor?.official_email_id} />
        <GroupContentItem value={(row?.mentor?.experience || 0.0) + 'yrs'} />
      </View>
    </View>
  );
};

const RenderBatch = ({row}) => {
  return (
    <View
      title={row.training_batch?.name}
      style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer
        style={{
          ...getNumberOfLineStyle({numberOfLines: 1}),
        }}
        value={row.training_batch?.name}
      />
      <Col>
        <GroupContentItem value={row?.training_batch?.training_program?.name} />
        <GroupContentItem
          value={`Batch ${row?.training_batch?.batch_number}`}
        />
      </Col>
    </View>
  );
};

const RenderDuration = ({row}) => {
  return (
    <GroupContentItem
      value={`${moment(row.training_batch?.from_date).format(
        'DD MMM YY',
      )} - ${moment(row.training_batch?.to_date).format('DD MMM YY')}`}
    />
  );
};

const RenderStatus = ({row}) => {
  const valueMap = {
    active: 'Ongoing',
    completed: 'Completed',
  };
  const colorMap = {
    active: 'Accent_1',
    completed: 'Success',
  };
  return (
    <Tag
      value={valueMap[row?.training_batch?.status]}
      color={colorMap[row?.training_batch?.status]}
    />
  );
};

const RenderTrainerFeedback = ({row}) => {
  const {feedbacks = []} = row;
  let feedbackCount = 0;
  const sum = feedbacks.reduce((acc, one) => {
    if (one?.overall_score) {
      feedbackCount++;
      acc = acc + (one.overall_score || 0);
    }
    return acc;
  }, 0);
  const avg_rating = sum / (feedbackCount || 1);
  return <RatingRenderer value={avg_rating} />;
};

const RenderType = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer
        value={row?.training_batch?.training_program?.course_type}
      />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.training_batch?.batch_type} />
      </RowWithSeparator>
    </View>
  );
};

export const AllTrainers = props => {
  const {navigation, status, extraFilter} = props;
  return (
    <Table
      api="/allTrainers"
      filter={{
        status,
        ...extraFilter,
      }}
      columns={[
        {
          header: 'Trainer',
          render: RenderTrainer,
          minWidth: 250,
        },
        // {
        //   header: 'Exp.',
        //   field: 'mentor.experience',
        //   type: 'text',
        //   width: 80,
        // },
        {
          header: 'Course Batch',
          render: RenderBatch,
          width: 200,
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        // {
        //   header: 'Type',
        //   type: 'colorTag',
        //   colorField: row =>
        //     row?.training_batch?.training_program?.course_type == 'Tech'
        //       ? '#FFF7D6'
        //       : '#F5E0FF',
        //   width: 120,
        // },
        // {
        //   field: 'training_batch.skill_metrics.name',
        //   header: 'Skill',
        //   type: 'text',
        // },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Duration',
          render: RenderDuration,
        },
        {
          header: 'Trainees',
          render: CountCellRender,
          count_field: 'trainee_count',
          align: 'right',
          width: 120,
        },
        {
          header: 'Trainer Feedback',
          render: RenderTrainerFeedback,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedbacks-rm', {
              mentor: row.mentor,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainers-overview', {
              mentor: row.mentor,
              status,
            }),
        },
        {
          title: 'Mark Trainer Inactive',
          onPress: ({row}) => {
            navigation.navigate('mark-trainer-inactive', {
              mentor: row.mentor,
            });
          },
          visible: status != 'active',
        },
      ]}
      {...props}
    />
  );
};

export const AllCompletedTrainers = props => {
  const {navigation, status, extraFilter} = props;
  return (
    <GroupTable
      {...props}
      filter={{
        status,
        ...extraFilter,
      }}
      api={`/allcompletedtrainers`}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Trainings`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Trainer..',
          render: RenderTrainer,
          minWidth: 250,
        },
        // {
        //   header: 'Exp.',
        //   field: 'mentor.experience',
        //   type: 'text',
        //   width: 80,
        // },
        {
          header: 'Course Batch',
          render: RenderBatch,
          width: 200,
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        // {
        //   header: 'Type',
        //   type: 'colorTag',
        //   colorField: row =>
        //     row?.training_batch?.training_program?.course_type == 'Tech'
        //       ? '#FFF7D6'
        //       : '#F5E0FF',
        //   width: 120,
        // },
        // {
        //   field: 'training_batch.skill_metrics.name',
        //   header: 'Skill',
        //   type: 'text',
        // },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Duration',
          render: RenderDuration,
        },
        {
          header: 'Trainees',
          render: CountCellRender,
          count_field: 'trainee_count',
          align: 'right',
          width: 120,
        },
        {
          header: 'Trainer Feedback',
          render: RenderTrainerFeedback,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedbacks-rm', {
              mentor: row.mentor,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainers-overview', {
              mentor: row.mentor,
              status,
            }),
        },
        {
          title: 'Mark Trainer Inactive',
          onPress: ({row}) => {
            navigation.navigate('mark-trainer-inactive', {
              mentor: row.mentor,
            });
          },
          visible: status != 'active',
        },
      ]}
    />
  );
  return (
    <Table
      api="/allcompletedtrainers"
      filter={{
        status,
        ...extraFilter,
      }}
      columns={[
        {
          header: 'Trainer',
          render: RenderTrainer,
        },
        // {
        //   header: 'Exp.',
        //   field: 'mentor.experience',
        //   type: 'text',
        //   width: 80,
        // },
        {
          header: 'Course Batch',
          render: RenderBatch,
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        // {
        //   header: 'Type',
        //   type: 'colorTag',
        //   colorField: row =>
        //     row?.training_batch?.training_program?.course_type == 'Tech'
        //       ? '#FFF7D6'
        //       : '#F5E0FF',
        //   width: 120,
        // },
        // {
        //   field: 'training_batch.skill_metrics.name',
        //   header: 'Skill',
        //   type: 'text',
        // },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Duration',
          render: RenderDuration,
        },
        {
          header: 'Trainees',
          render: CountCellRender,
          count_field: 'trainee_count',
          align: 'right',
          width: 120,
        },
        {
          header: 'Trainer Feedback',
          render: RenderTrainerFeedback,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedbacks-rm', {
              mentor: row.mentor,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainers-overview', {
              mentor: row.mentor,
              status,
            }),
        },
        {
          title: 'Mark Trainer Inactive',
          onPress: ({row}) => {
            navigation.navigate('mark-trainer-inactive', {
              mentor: row.mentor,
            });
          },
          visible: status != 'active',
        },
      ]}
      {...props}
    />
  );
};

export const AllTrainersOverview = props => {
  const {
    route: {
      params: {mentor},
    },
    navigation,
  } = props;

  const filterProps = useFilter();
  const {
    filterValues: {filter},
  } = filterProps;

  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  return (
    <Table
      api="/allTrainersOverview"
      renderHeader={() => (
        <TableHeader
          title="All Trainings"
          secondaryTitle={mentor.name}
          actions={[
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  label: 'Course Batch',
                  type: 'autoComplete',
                  field: 'training_batch',
                  api: '/trainingBatches',
                  suggestionField: 'name',
                  placeholder: 'Select Course Batch',
                },
              ]}
            />,
            <IconButton
              icon={Download}
              onPress={() => {
                download({
                  uri: '/exportAllTrainerOverView',
                  props: {
                    filter: {mentor, ...filter},
                  },
                });
              }}
            />,
          ]}
        />
      )}
      filter={{mentor, ...filter}}
      columns={[
        {
          header: 'Course Batch',
          render: RenderBatch,
          minWidth: 200,
        },
        {
          field: 'training_batch.training_program.course_type',
          header: 'Type',
          type: 'colorTag',
          colorField: row =>
            row?.training_batch?.training_program?.course_type == 'Tech'
              ? '#FFF7D6'
              : '#F5E0FF',
          width: 120,
        },
        {
          field: 'training_batch.skill_metrics.name',
          header: 'Skill',
          type: 'text',
        },
        {
          header: 'Trainees',
          render: CountCellRender,
          count_field: 'trainee_count',
          align: 'right',
          width: 120,
        },
        {
          header: 'Duration',
          render: RenderDuration,
        },
        {
          header: 'Status',
          render: RenderStatus,
        },
        {
          header: 'Trainer Feedback',
          render: RenderTrainerFeedback,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-trainer-feedbacks-rm', {
              mentor: mentor,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
