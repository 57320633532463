import React from 'react';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {useTheme} from '@unthinkable/react-theme';
import {IconButton} from '../../../components/button/IconButton';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {useFilter} from '../../../controllers/useFilter';
import {useAppStateContext} from '../../../providers/AppState';
import {PlanForDeployment} from './PlanForDeployment';
import {
  AllResourceOnBench,
  AllResourceOnBenchGroupByStatus,
  AvailableBenchResourceForDeliveryOwners,
  AvailableResource,
  OtherResourceOnBench,
  ReservedList,
  SocialAndInternalResource,
  UnavailableList,
  UnderTraining,
} from './UnavailableList';

export const EnggNonBilledTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const defaultFilter = {};
  // if (fromICMenu) {
  //   defaultFilter.is_manager = false;
  // }
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download, user} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllResourceOnBench
          {...props}
          addOnParams={{...params, is_AllBenchEmployee: true}}
          search={searchValue}
          period={period}
          tab="All"
          addOnFilter={{
            ...filter,
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {...params, is_AllBenchEmployee: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, is_AllBenchEmployee: true},
                addOnFilter: {
                  ...filter,
                },
              },
            });
          }}
        />,
      ],
    },

    available: {
      label: 'Available',
      view: (
        <AvailableResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Available"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromAvailableTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },

    unbilled_deployments: {
      label: 'Unbilled Deployments',
      view: (
        <AvailableResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Unbilled Deployments"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromUnbilledDeploymentsTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },

    reserved: {
      label: 'Reserved',
      view: (
        <ReservedList
          {...props}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            is_shadow: true,
          }}
          tab="Reserved"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        is_shadow: true,
      },
      api: '/reservedResources',
      params: {...params},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/reservedResourcesExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  is_shadow: true,
                },
              },
            });
          }}
        />,
      ],
    },

    underTraining: {
      label: 'Under Training',
      view: (
        <UnderTraining
          addOnParams={{...params, isUnderTraining: true}}
          search={searchValue}
          addOnFilter={{
            ...filter,
            bench_training_type: {$in: ['Offline', 'RFR']},
            is_actual_allocation: {$in: [null, false, true]},
          }}
          period={period}
          tab="Under Training"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        bench_training_type: {$in: ['Offline', 'RFR']},
        is_actual_allocation: {$in: [null, false, true]},
      },
      api: '/employees/internalresources',
      params: {...params, period, isUnderTraining: true},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/underTrainingReportExport`,
              props: {
                params: {...params, isUnderTraining: true},
                addOnFilter: {
                  ...filter,
                  bench_training_type: {$in: ['Offline', 'RFR']},
                  is_actual_allocation: {$in: [null, false, true]},
                  is_internal: {$in: [null, false, true]},
                },
              },
            });
          }}
        />,
      ],
    },

    unavailable: {
      label: 'Unavailable',
      view: (
        <UnavailableList
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            working_status: {$in: ['Unbillable', 'Not Available']},
          }}
          tab="Unavailable"
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/unavailable',
      addOnFilter: {
        ...filter,
        working_status: {$in: ['Unbillable', 'Not Available']},
      },
      limit: 2000,
      params: {...params, period},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/unAvaiableReportExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  working_status: {$in: ['Unbillable', 'Not Available']},
                },
              },
            });
          }}
        />,
        // <Button
        //   text="Download"
        //   onPress={() => {
        //     download({
        //       uri: `/unAvaiableReportExport`,
        //       props: {
        //         ...params,
        //         addOnFilter: {
        //           ...filter,
        //           working_status: {$in: ['Unbillable', 'Not Available']},
        //         },
        //       },
        //     });
        //   }}
        // />,
      ],
    },

    under_resignation: {
      label: 'Under Resignation',
      view: (
        <UnavailableList
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            resign_date_new: {$exists: true},
          }}
          tab="Under Resignation"
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/unavailable',
      addOnFilter: {
        ...filter,
        resign_date_new: {$exists: true},
      },
      limit: 2000,
      params: {...params, period},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/unAvaiableReportExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  resign_date_new: {$exists: true},
                },
              },
            });
          }}
        />,
        // <Button
        //   text="Download"
        //   onPress={() => {
        //     download({
        //       uri: `/unAvaiableReportExport`,
        //       props: {
        //         ...params,
        //         addOnFilter: {
        //           ...filter,
        //           resign_date_new: {$exists: true},
        //         },
        //       },
        //     });
        //   }}
        // />,
      ],
    },

    plan_deployment: {
      label: 'Plan For Deployment',
      view: (
        <PlanForDeployment
          params={params}
          search={searchValue}
          addOnFilter={filter}
          tab="Plan For Deployment"
        />
      ),
      api: '/employee/planForDeployment',
      limit: 2000,
      addOnFilter: filter,
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {...params},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
          ]}
        />,
        (user?.email === 'sushil.nagvan@daffodilsw.com' ||
          user?.email === 'aswathy.krishnankutty@unthinkable.co' ||
          user?.email === 'amit.singh@daffodildb.com') && (
          <IconButton
            icon={Download}
            onPress={() => {
              download({
                uri: `/employeeassignment/benchcomparison/export`,
                props: {
                  params: {...params},
                },
              });
            }}
          />
        ),
        // (
        //   <Button
        //     text="Download"
        //     onPress={() => {
        //       download({
        //         uri: `/employeeassignment/benchcomparison/export`,
        //         props: {
        //           params: {...params},
        //         },
        //       });
        //     }}
        //   />
        // )
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const SocialTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {department} = params;
  const defaultFilter = {
    department: department,
  };
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    social: {
      label: 'Social',
      view: (
        <SocialAndInternalResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Unbilled Deployments"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromUnbilledDeploymentsTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const InternalTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {department} = params;
  const defaultFilter = {
    department: department,
  };
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    internal: {
      label: 'Internal',
      view: (
        <SocialAndInternalResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Unbilled Deployments Internal"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, forInternalTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },

            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const TainingTab = props => {
  const {route: {params} = {}} = props;
  const {department} = params;
  const defaultFilter = {
    'employee.department': department,
    'employee.employee_status': 'Active',
  };
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    training: {
      label: 'Training',
      view: (
        <UnderTraining
          addOnParams={{...params, isUnderTraining: true}}
          search={searchValue}
          addOnFilter={{
            ...filter,
            bench_training_type: {$in: ['Offline', 'RFR']},
            is_actual_allocation: {$in: [null, false, true]},
          }}
          period={period}
          tab="Training"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        bench_training_type: {$in: ['Offline', 'RFR']},
        is_actual_allocation: {$in: [null, false, true]},
      },
      api: '/employees/internalresources',
      params: {...params, period, isUnderTraining: true},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/underTrainingReportExport`,
              props: {
                params: {...params, isUnderTraining: true},
                addOnFilter: {
                  ...filter,
                  bench_training_type: {$in: ['Offline', 'RFR']},
                  is_actual_allocation: {$in: [null, false, true]},
                  is_internal: {$in: [null, false, true]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const BenchTab = props => {
  const {route: {params} = {}, fromICMenu, fromDepartmentBench = false} = props;
  const {department} = params;
  let defaultFilter = {};
  if (department) {
    defaultFilter = {
      'employee.department': department,
      'employee.employee_status': 'Active',
    };
  }

  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download, user} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  // filter.is_manager = false;
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllResourceOnBench
          {...props}
          addOnParams={{...params, is_AllBenchEmployee: true}}
          search={searchValue}
          period={period}
          tab="All"
          addOnFilter={{
            ...filter,
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {...params, is_AllBenchEmployee: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, is_AllBenchEmployee: true},
                addOnFilter: {
                  ...filter,
                },
              },
            });
          }}
        />,
      ],
    },

    available: {
      label: 'Available',
      view: (
        <AvailableResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Available"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromAvailableTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },

    social: {
      label: 'Social',
      view: (
        <SocialAndInternalResource
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Unbilled Deployments"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromUnbilledDeploymentsTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },

    // internal: {
    //   label: 'Internal',
    //   view: (
    //     <SocialAndInternalResource
    //       {...props}
    //       params={params}
    //       search={searchValue}
    //       period={period}
    //       tab="Unbilled Deployments Internal"
    //       addOnFilter={{
    //         ...filter,
    //         is_proposed_deployment: {$in: [null, false]},
    //       }}
    //     />
    //   ),
    //   eventSourceId: 'allocatedResource',
    //   api: '/employees/bench',
    //   addOnFilter: {
    //     ...filter,
    //     is_proposed_deployment: {$in: [null, false]},
    //   },
    //   params: {...params, forInternalTab: true},
    //   limit: 2000,
    //   actions: [
    //     <SearchFilter
    //       value={searchValue}
    //       onChangeFilter={onChangeFilter}
    //       placeholder="Search"
    //     />,
    //     <GroupFilter
    //       filterValues={filterValues}
    //       applyFilter={applyFilter}
    //       filters={[
    //         {
    //           type: 'autoComplete',
    //           api: '/offices',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'sitting_location',
    //           label: 'Location',
    //           placeholder: 'Select',
    //           size: 6,
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Reporting To',
    //           api: '/employeeSuggestions',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           secondarySuggestionField: 'official_email_id',
    //           valueField: 'name',
    //           field: 'reporting_to',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Practice',
    //           api: '/practices',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'primary_practice',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Product',
    //           api: '/products',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'product',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Is CV Added',
    //           options: [
    //             {label: 'True', value: true},
    //             {label: 'False', value: false},
    //           ],
    //           field: 'is_cv_added',
    //           placeholder: 'Select',
    //           suggestionField: 'label',
    //           keyField: 'value',
    //           valueField: 'label',
    //         },
    //         {
    //           type: 'date',
    //           label: 'Resign Date',
    //           field: 'resign_date_new',
    //         },
    //         {
    //           type: 'date',
    //           label: 'Proposed Deployment',
    //           field: 'proposed_deployment_date',
    //         },
    //         {
    //           type: 'autoComplete',
    //           placeholder: 'Select',
    //           options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
    //           label: 'Experience Range',
    //           field: 'experience_range',
    //         },

    //         {
    //           type: 'autoComplete',
    //           placeholder: 'Select',
    //           options: [
    //             {label: 'CE', value: 'A+'},
    //             {label: 'OE', value: 'A'},
    //             {label: 'ME', value: 'B+'},
    //             {label: 'PE', value: 'B'},
    //             {label: 'DE', value: 'C'},
    //           ],
    //           field: 'project_feedback_rating',
    //           label: 'Last Rating',
    //           suggestionField: 'label',
    //           keyField: 'value',
    //           valueField: 'label',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Resource Language',
    //           api: '/tools',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           secondarySuggestionField: 'official_email_id',
    //           valueField: 'name',
    //           field: 'resource_language',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Delivery Owner',
    //           api: '/employeeSuggestions',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           secondarySuggestionField: 'official_email_id',
    //           valueField: 'name',
    //           field: 'delivery_owner',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Team Lead',
    //           api: '/employeeSuggestions',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           secondarySuggestionField: 'official_email_id',
    //           valueField: 'name',
    //           field: 'skill_leader',
    //         },
    //       ]}
    //     />,
    //     <IconButton
    //       icon={Download}
    //       onPress={() => {
    //         download({
    //           uri: `/employeeassignment/bench/export`,
    //           props: {
    //             params: {...params, fromAvailableTab: true},
    //             addOnFilter: {
    //               ...filter,
    //               is_proposed_deployment: {$in: [null, false]},
    //             },
    //           },
    //         });
    //       }}
    //     />,
    //   ],
    // },

    training: {
      label: 'Training',
      view: (
        <UnderTraining
          addOnParams={{...params, isUnderTraining: true}}
          search={searchValue}
          addOnFilter={{
            ...filter,
            bench_training_type: {$in: ['Offline', 'RFR']},
            is_actual_allocation: {$in: [null, false, true]},
          }}
          period={period}
          tab="Training"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        bench_training_type: {$in: ['Offline', 'RFR']},
        is_actual_allocation: {$in: [null, false, true]},
      },
      api: '/employees/internalresources',
      params: {...params, period, isUnderTraining: true},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/underTrainingReportExport`,
              props: {
                params: {...params, isUnderTraining: true},
                addOnFilter: {
                  ...filter,
                  bench_training_type: {$in: ['Offline', 'RFR']},
                  is_actual_allocation: {$in: [null, false, true]},
                  is_internal: {$in: [null, false, true]},
                },
              },
            });
          }}
        />,
      ],
    },

    reserved: {
      label: 'Reserved',
      view: (
        <ReservedList
          {...props}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            is_shadow: true,
          }}
          tab="Reserved"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        is_shadow: true,
      },
      api: '/reservedResources',
      params: {...params},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/reservedResourcesExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  is_shadow: true,
                },
              },
            });
          }}
        />,
      ],
    },

    unavailable: {
      label: 'Unavailable',
      view: (
        <UnavailableList
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            working_status: {$in: ['Unbillable', 'Not Available']},
          }}
          tab="Unavailable"
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/unavailable',
      addOnFilter: {
        ...filter,
        working_status: {$in: ['Unbillable', 'Not Available']},
      },
      limit: 2000,
      params: {...params, period},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/unAvaiableReportExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  working_status: {$in: ['Unbillable', 'Not Available']},
                },
              },
            });
          }}
        />,
      ],
    },

    under_resignation: {
      label: 'Under Resignation',
      view: (
        <UnavailableList
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            resign_date_new: {$exists: true},
            resign_withdraw: {$in: [null, false]},
          }}
          tab="Under Resignation"
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/unavailable',
      addOnFilter: {
        ...filter,
        resign_date_new: {$exists: true},
        resign_withdraw: {$in: [null, false]},
      },
      limit: 2000,
      params: {...params, period},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/unAvaiableReportExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  resign_date_new: {$exists: true},
                  resign_withdraw: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },
  };

  if (!fromDepartmentBench) {
    tabs['plan_deployment'] = {
      label: 'Plan For Deployment',
      view: (
        <PlanForDeployment
          params={params}
          search={searchValue}
          addOnFilter={filter}
          tab="Plan For Deployment"
        />
      ),
      api: '/employee/planForDeployment',
      limit: 2000,
      addOnFilter: filter,
      search: searchValue,
      searchFields: 'name',
      eventSourceId: 'EmployeeAssignment',
      params: {...params},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        (user?.email === 'sushil.nagvan@daffodilsw.com' ||
          user?.email === 'aswathy.krishnankutty@unthinkable.co' ||
          user?.email === 'amit.singh@daffodildb.com') && (
          <IconButton
            icon={Download}
            onPress={() => {
              download({
                uri: `/employeeassignment/benchcomparison/export`,
                props: {
                  params: {...params},
                },
              });
            }}
          />
        ),
      ],
    };
  }

  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledTagTab = props => {
  const {
    route: {params},
  } = props;
  const {department} = params;
  const defaultFilter = {department};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {
    filter,
    params: {period},
  } = filterValues;
  const tabs = {
    all: {
      label: 'All',
      view: (
        <AllResourceOnBenchGroupByStatus
          {...props}
          addOnParams={{...params, is_AllBenchEmployee: true}}
          search={searchValue}
          period={period}
          tab="All"
          extraAddOnFilter={{
            ...filter,
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {...params, is_AllBenchEmployee: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, is_AllBenchEmployee: true},
                addOnFilter: {
                  ...filter,
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledTrainingTagTab = props => {
  const {
    route: {params},
  } = props;
  const {department} = params;
  const defaultFilter = {department};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {
    filter,
    params: {period},
  } = filterValues;
  const tabs = {
    training: {
      label: 'Training',
      view: (
        <UnderTraining
          addOnParams={{...params, isUnderTraining: true}}
          search={searchValue}
          addOnFilter={{
            ...filter,
            bench_training_type: {$in: ['Offline', 'RFR']},
            is_actual_allocation: {$in: [null, false, true]},
          }}
          period={period}
          tab="Training"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        bench_training_type: {$in: ['Offline', 'RFR']},
        is_actual_allocation: {$in: [null, false, true]},
      },
      api: '/employees/internalresources',
      params: {...params, period, isUnderTraining: true},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          isLast={false}
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/underTrainingReportExport`,
              props: {
                params: {...params, isUnderTraining: true},
                addOnFilter: {
                  ...filter,
                  bench_training_type: {$in: ['Offline', 'RFR']},
                  is_actual_allocation: {$in: [null, false, true]},
                  is_internal: {$in: [null, false, true]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledModuleDevelopmentTagTab = props => {
  const {
    route: {params},
  } = props;
  const {department} = params;
  const defaultFilter = {department};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {
    filter,
    params: {period},
  } = filterValues;
  const tabs = {
    moduleDevelopment: {
      label: 'Module Development',
      view: (
        <AllResourceOnBench
          {...props}
          addOnParams={{
            ...params,
            is_AllBenchEmployee: true,
            fromModuleDevelopment: true,
          }}
          search={searchValue}
          period={period}
          tab="All"
          extraAddOnFilter={{
            ...filter,
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {
        ...params,
        is_AllBenchEmployee: true,
        fromModuleDevelopment: true,
      },
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          isLast={false}
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {
                  ...params,
                  forInternalTab: true,
                  extraAddOnFilter: {
                    is_self_module: true,
                  },
                },
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledShadowTagTab = props => {
  const {
    route: {params},
  } = props;
  const {department} = params;
  const defaultFilter = {department};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download} = useAppStateContext();
  const {
    filter,
    params: {period},
  } = filterValues;
  const tabs = {
    shadow: {
      label: 'Shadow',
      view: (
        <ReservedList
          {...props}
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            is_shadow: true,
          }}
          tab="Reserved"
        />
      ),
      eventSourceId: 'allocatedResource',
      addOnFilter: {
        ...filter,
        is_shadow: true,
      },
      api: '/reservedResources',
      params: {...params},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/reservedResourcesExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  is_shadow: true,
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledNotAvailableTab = props => {
  const {route: {params} = {}, fromICMenu, fromDepartmentBench = false} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download, user} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    unavailable: {
      label: 'Unavailable',
      view: (
        <UnavailableList
          params={params}
          search={searchValue}
          addOnFilter={{
            ...filter,
            working_status: {$in: ['Unbillable', 'Not Available']},
          }}
          tab="Unavailable"
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/unavailable',
      addOnFilter: {
        ...filter,
        working_status: {$in: ['Unbillable', 'Not Available']},
      },
      limit: 2000,
      params: {...params, period},
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/unAvaiableReportExport`,
              props: {
                ...params,
                addOnFilter: {
                  ...filter,
                  working_status: {$in: ['Unbillable', 'Not Available']},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const UnbilledOtherTab = props => {
  const {route: {params} = {}, fromICMenu, fromDepartmentBench = false} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });
  const {Download} = useTheme('icons');
  const {download, user} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  const tabs = {
    all: {
      label: 'Other',
      view: (
        <OtherResourceOnBench
          {...props}
          addOnParams={{
            ...params,
            is_AllBenchEmployee: true,
            formDepartmentOther: true,
          }}
          search={searchValue}
          period={period}
          tab="All"
          extraAddOnFilter={{
            ...filter,
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/depatmentTag/others',
      limit: 2000,
      addOnFilter: {
        ...filter,
      },
      params: {
        ...params,
        is_AllBenchEmployee: true,
        formDepartmentOther: true,
      },
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Skill Mentor',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              searchFields: ['name', 'official_email_id', 'employee_code'],
              field: 'skill_leader',
            },
            {
              type: 'autoComplete',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_manager',
              label: 'Is Manager',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
          ]}
        />,
        // <IconButton
        //   icon={Download}
        //   onPress={() => {
        //     download({
        //       uri: `/employeeassignment/bench/export`,
        //       props: {
        //         params: {...params, is_AllBenchEmployee: true},
        //         addOnFilter: {
        //           ...filter,
        //         },
        //       },
        //     });
        //   }}
        // />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};

export const DeliveryOwnersBenchTab = props => {
  const {route: {params} = {}, fromICMenu} = props;
  const {department} = params;
  const defaultFilter = {department};
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
    filter: defaultFilter,
  });
  const {Download} = useTheme('icons');
  const {download, user} = useAppStateContext();
  const {filter = {}, params: {period = {}} = {}} = filterValues || {};
  filter.is_manager = false;
  const tabs = {
    available: {
      label: 'Available',
      view: (
        <AvailableBenchResourceForDeliveryOwners
          {...props}
          params={params}
          search={searchValue}
          period={period}
          tab="Available"
          addOnFilter={{
            ...filter,
            is_proposed_deployment: {$in: [null, false]},
          }}
        />
      ),
      eventSourceId: 'allocatedResource',
      api: '/employees/bench',
      addOnFilter: {
        ...filter,
        is_proposed_deployment: {$in: [null, false]},
      },
      params: {...params, fromAvailableTab: true},
      limit: 2000,
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              field: 'sitting_location',
              label: 'Location',
              placeholder: 'Select',
              size: 6,
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Practice',
              api: '/practices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'primary_practice',
            },
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Is CV Added',
              options: [
                {label: 'True', value: true},
                {label: 'False', value: false},
              ],
              field: 'is_cv_added',
              placeholder: 'Select',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'date',
              label: 'Resign Date',
              field: 'resign_date_new',
            },
            {
              type: 'date',
              label: 'Proposed Deployment',
              field: 'proposed_deployment_date',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: ['0-1', '1-2', '2-4', '4-6', '6-10', '10+'],
              label: 'Experience Range',
              field: 'experience_range',
            },
            {
              type: 'autoComplete',
              placeholder: 'Select',
              options: [
                {label: 'CE', value: 'A+'},
                {label: 'OE', value: 'A'},
                {label: 'ME', value: 'B+'},
                {label: 'PE', value: 'B'},
                {label: 'DE', value: 'C'},
              ],
              field: 'project_feedback_rating',
              label: 'Last Rating',
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
            },
            {
              type: 'autoComplete',
              label: 'Resource Language',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
            },
          ]}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: `/employeeassignment/bench/export`,
              props: {
                params: {...params, fromAvailableTab: true},
                addOnFilter: {
                  ...filter,
                  is_proposed_deployment: {$in: [null, false]},
                },
              },
            });
          }}
        />,
      ],
    },
  };
  return <TabView tabs={tabs} params={params} {...props} />;
};
