import {
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {CommentMoreAction} from '../../../app-components/renders/CommentRender';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Chip, Tag} from '../../../components/chip/Chip';
import {Efficiency} from '../../../components/efficiency/Efficiency';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {RatingRenderer} from '../../../components/form-editors/rating/Rating';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {PROJECT_TYPE_ID} from '../../common/constants/SourceConstants';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useProjectActions} from '../actions/ProjectActions';
import {AvatarGroup} from '../../../components/avatar/Avatar';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import _ from 'lodash';
import {PROJECT_TYPES} from '../constants/ProjectConstant';
import {IconWithBackground} from '../../../components/icons/IconWithBackground';

const TYPE = {
  header: 'Type',
  width: 160,
  render: ({row}) => {
    const {new_project_type, projecttype} = row;
    if (!(new_project_type || projecttype)) {
      return null;
    }
    return (
      <Tag
        value={PROJECT_TYPES[new_project_type || projecttype]?.label}
        color={PROJECT_TYPES[new_project_type || projecttype]?.color}
      />
    );
  },
};

const mergeProject = ({params, navigation}) => ({
  title: 'Merge Project',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`merge-detail`, {
      ...params,
      source: {_id: row?._id},
      title: 'Merge Project',
      secondaryTitle: row?.project,
      targetField: {
        api: `/projects`,
        suggestionField: 'project',
        valueField: 'project',
      },
      uri: '/projects/merge',
      eventSourceId: 'Project',
    });
  },
});

const renderProject = ({projectDetail, showCustomer = true}) => {
  return {
    header: 'Project',
    minWidth: 260,
    render: ({row}) => {
      let icon;
      let color = 'BRAND_UPPER_LOW';
      if (row.tool === 'Locomo') {
        icon = 'LocomoTool';
      } else if (row.tool === 'Jira') {
        icon = 'JiraIcon';
        color = 'SECONDARY_MEDIUM';
      } else {
        icon = 'OtherTool';
      }
      return (
        <Row
          style={{
            flex: 1,
            rowGap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          gap={8}>
          <Row
            style={{
              borderRadius: 2,
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <IconWithBackground icon={icon} color={color} />
          </Row>
          <PrimaryColumnCard
            primaryTitle={row?.project}
            items={[
              {
                value: row?.code,
              },
              // showCustomer && {
              //   value: row?.customer?.name,
              // },
              {
                value: `${
                  row?.project_start_date
                    ? moment(row?.project_start_date).format('DD MMM YY')
                    : ''
                } - ${
                  row?.project_end_date
                    ? moment(row?.project_end_date).format('DD MMM YY')
                    : ''
                }`,
              },
            ]}
          />
        </Row>
      );
    },
    onPress: projectDetail,
  };
};

const DURATION = {
  header: 'Duration',
  width: 180,
  align: 'left',
  render: ({row, styles}) => {
    return (
      <Text style={{...styles.rowText, color: '#737373'}}>
        {row.project_start_date &&
          moment(row.project_start_date).format('DD MMM YY')}
        -
        {row.project_end_date &&
          moment(row.project_end_date).format('DD MMM YY')}
      </Text>
    );
  },
};

const CSM = {
  header: 'CSM/PM',
  field: 'owner_id',
  type: 'userAvatar',
  align: 'center',
  width: 80,
};

const totalPotential = ({tabName}) => {
  return {
    header: 'Total Pot.',
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.total_potential && `${row?.total_potential} MM`}
        </Text>
      );
    },
    visible: () => tabName === 'sdlcplus',
  };
};

const totalEstEffort = ({tabName}) => {
  return {
    header: 'Est. Effort',
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.total_potential && `${row?.total_potential} MM`}
        </Text>
      );
    },
    visible: () => tabName === 'sdlcplus',
  };
};

const totalBilledHours = () => {
  return {
    header: 'Actual Effort',
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.totalBilledHours && `${row?.totalBilledHours} MM`}
        </Text>
      );
    },
  };
};

const remainingPotential = ({tabName}) => {
  return {
    header: 'Remaining Pot.',
    aggregate: true,
    field: 'remaining_hours',
    width: 140,
    type: 'number',
    render: ({row, styles: {rowText = {}}}) => {
      return (
        <Text
          style={{
            ...rowText,
          }}>
          {row?.remaining_hours && `${row?.remaining_hours} MM `}
        </Text>
      );
    },
    visible: () => tabName === 'sdlcplus',
  };
};

const orderEngg = ({tabName}) => {
  return {
    render: CountCellRender,
    count_field: 'orderEngg',
    type: 'chip',
    header: 'Order Engg.',
    aggregate: true,
    field: 'orderEngg',
    align: 'right',
    // onPress: ({row}) => {
    //   const {project_team} = row;
    //   navigation.navigate(`employee-workload-for-project`, {
    //     employeeFilter: {_id: {$in: project_team}},
    //   });
    // },
    visible: () => tabName !== 'sdlcplus',
    width: 100,
  };
};

const avgExperience = ({tabName}) => {
  return {
    count_field: 'avg_experience',
    field: 'avg_experience',
    header: 'Avg Exp.',
    render: ({row, styles}) => {
      return (
        <Text style={{...styles.rowText, color: '#A3A3A3'}}>
          {row?.avg_experience} y
        </Text>
      );
    },
    // visible: () => tabName !== 'sdlcplus',
    align: 'right',
    suffix: 'y',
    width: 80,
  };
};
const teamAllocation = ({tabName, navigation}) => {
  return {
    render: CountCellRender,
    count_field: 'team',
    type: 'chip',
    header: 'Allocated',
    aggregate: true,
    field: 'team',
    align: 'right',
    onPress: ({row}) => {
      const {project_team} = row;
      navigation.navigate(`employee-workload-for-billed-and-unbilled`, {
        employeeFilter: {_id: {$in: project_team}},
        employee_team: {project: {_id: row?._id, project: row?.project}},
        formProjectAllocation: true,
      });
    },
    // visible: () => tabName !== 'sdlcplus',
    width: 100,
  };
};

const escalation = ({project_status}) => {
  return {
    render: CountCellRender,
    count_field: 'escalation',
    header: 'Open Esc',
    type: 'chip',
    aggregate: true,
    field: 'escalation',
    align: 'center',
    onPress: ({row}) => {
      navigation.navigate('escalation-tab', {
        ...params,
        ...row,
      });
    },
    visible: ({row}) => {
      if (project_status === 'a') {
        return true;
      } else return false;
    },
    width: 100,
  };
};

const lastEfficiency = ({tabName}) => {
  return {
    header: 'Eff.(Last week)',
    width: 120,
    align: 'right',
    // visible: () => tabName !== 'sdlcplus',
    render: ({row, styles}) => {
      return <Efficiency value={row?.efficiency_percentage} />;
    },
  };
};

const onHoldDate = ({tabName}) => {
  return {
    header: 'Since',
    align: 'center',
    type: 'date',
    formatOptions: {
      format: 'DD MMM YY',
    },
    field: 'on_hold_date',
  };
};

const onHoldReason = ({tabName}) => {
  return {
    header: 'Reason',
    field: 'on_hold_reason',
  };
};

const lastCsat = ({project_status}) => {
  return {
    header: 'Last CSAT',
    field: 'past_sprint_csat_score',
    width: 130,
    render: ({row, styles}) => {
      return (
        <View>
          <RatingRenderer value={row.past_sprint_csat_score} />
          <Text style={{...styles.rowText, color: '#A3A3A3'}}>
            {moment(row.last_csat_date).format('DD MMM YY')}
          </Text>
        </View>
      );
    },
    visible: ({row}) => {
      if (project_status === 'a') {
        return true;
      } else return false;
    },
    align: 'center',
  };
};

const projectPlanStatus = ({navigation}) => {
  return {
    type: 'text',
    field: 'project_plan_status',
    header: 'Project Plan Status',
    width: 200,
    align: 'right',
    render: ({row}) => {
      if (row?.project_plan_status == 'Approval Pending') {
        return (
          <>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('project-order-mapping-list-for-am', {
                  project: {_id: row?._id},
                });
              }}>
              <Chip
                displayTextColor
                color={'INFORMATION'}
                value={'Approval Pending'}
              />
            </TouchableOpacity>
          </>
        );
      }
      if (row?.project_plan_status == 'Submission Pending') {
        return (
          <Chip
            color={'Outline'}
            textColor={'NEUTRAL_LOW'}
            value={'Submission Pending'}
          />
        );
      }
      if (row?.project_plan_status == 'Rejected') {
        return <Chip displayTextColor color={'ERROR'} value={'Rejected'} />;
      }
      if (row?.project_plan_status == 'Approved') {
        return <Chip displayTextColor color={'SUCCESS'} value={'Approved'} />;
      }
    },
  };
};

const onHold = ({project_status}) => {
  return {
    header: 'Since',
    field: 'on_hold_date',
    type: 'date',
    width: 150,
    visible: () => {
      if (project_status === 'h') {
        return true;
      } else return false;
    },
  };
};

const activeDuration = ({project_status}) => {
  return {
    header: 'Active Duration',
    width: 180,
    visible: () => {
      if (project_status === 'c') {
        return true;
      } else return false;
    },
    render: ({row, styles}) => {
      const {project_start_date, completed_on} = row;
      if (completed_on && project_start_date) {
        return (
          <Text style={{...styles.rowText, color: '#A3A3A3'}}>
            {project_start_date &&
              moment(project_start_date).format('DD MMM YY')}{' '}
            -{completed_on && moment(completed_on).format('DD MMM YY')}
          </Text>
        );
      }
    },
  };
};

const FOR = {
  header: 'For',
  field: 'customer.name',
  type: 'colorTag',
  colorField: randomColor,
  minWidth: 180,
};

const CATEGORY = {
  header: 'Category',
  field: 'project_category',
  type: 'colorTag',
  colorField: randomColor,
};

const HOURS = {
  header: 'Hours',
  field: 'total_potential',
  aggregate: true,
  formatOptions: {
    maximumFractionDigits: 2,
  },
  type: 'number',
  render: ({row, styles: {rowText = {}}}) => {
    return (
      <Text
        style={{
          ...rowText,
        }}>
        {row?.total_potential && `${row?.total_potential} MM`}
      </Text>
    );
  },
};

const comments = props => {
  return CommentMoreAction({
    ...props,
    params: ({row}) => ({
      comment_source: {_id: PROJECT_TYPE_ID, label: 'Project'},
      comment_source_id: {_id: row?._id, name: row?.project},
    }),
  });
};

const OWNER = {
  header: 'Owner',
  field: 'owner_id',
  type: 'userAvatarChip',
  width: 160,
};

const CUSTOMER = {
  header: 'Customer',
  minWidth: 160,
  field: 'customer.name',
};

const ACTIVE_SINCE = {
  header: 'Since',
  field: 'createdAt',
  type: 'date',
  formatOptions: {format: 'DD MMM YY'},
  width: 120,
};

export const PerformanceBilledReportTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    project_status,
    tab,
  } = props;
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    projectPlanDetails,
    activityLogDetail,
    editProjectRouteForNonAdmin,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams, tab}}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={[
        // ACTIVE_SINCE,
        renderProject({projectDetail}),
        CUSTOMER,
        project_status == 'c' && TYPE,
        project_status == 'c' && OWNER,
        project_status !== 'c' && CSM,
        project_status !== 'c' && totalPotential({tabName}),
        project_status !== 'c' && remainingPotential({tabName}),
        project_status !== 'c' && orderEngg({tabName}),
        project_status !== 'c' && teamAllocation({tabName, navigation}),
        project_status !== 'c' && avgExperience({tabName}),
        project_status !== 'h' &&
          project_status !== 'c' &&
          lastEfficiency({tabName}),
        project_status === 'h' && onHoldDate({tabName}),
        project_status === 'h' && onHoldReason({tabName}),
        // {
        //   header: 'Sub type',
        //   field: 'new_project_sub_type.project_sub_type',
        //   type: 'colorTag',
        // },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProject,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        {
          title: 'View All Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                project_id: row?._id,
              },
            });
          },
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) =>
            row.project_status == 'h' || row.project_status == 'c',
        },
        {
          title: 'Mark Hold',
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) =>
            row.project_status !== 'h' && row.project_status !== 'c',
        },
        {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
          visible: ({row}) => row?.projecttype == 'f',
        },
        {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
          visible: ({row}) => row?.projecttype == 'f',
        },
        tabName !== 'sdlcplus' && {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
          visible: ({row}) => row.project_status != 'c',
        },
        tabName == 'sdlcplus' && {
          title: 'Add Target',
          onPress: ({row}) => {
            navigation.navigate('add-fixed-order-projection', {
              projectionfilter: {
                customer: row?.customer?._id,
                project: row?._id,
              },
              row: {project: {_id: row?._id, project: row?.project}},
            });
          },
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
        // {
        //   title: 'View Project Plans',
        //   onPress: projectPlanDetails,
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'View Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-fixed-employee-request-tab', {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'Order Mapped',
        //   onPress: ({row}) => {
        //     navigation.navigate(
        //       row?.projecttype == 'f' ? `fixed-order-map` : `tm-order-map`,
        //       {
        //         row: {
        //           ...row,
        //           projectTab: 'Billed',
        //         },
        //       },
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const PerformanceAllBilledReportTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    project_status,
    tab,
    // groupBy,
  } = props;
  const icons = useTheme('icons');
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    projectPlanDetails,
    activityLogDetail,
    editProjectRouteForNonAdmin,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams, tab}}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      // addonGroupBy={groupBy?.addonGroupBy}
      // groupRow={groupBy?.groupRow}
      // allowDynamicGrouping={true}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
        },
        {
          header: 'Project',
          field: 'project',
          type: 'text',
          onPress: projectDetail,
        },

        {
          header: 'ASM/PM',
          field: 'owner_id',
          type: 'userAvatar',
          align: 'center',
          width: 80,
        },
        {
          header: 'Allocation',
          field: 'team',
          type: 'number',
          aggregate: true,
        },
        {
          header: 'Avg Exp',
          field: 'avg_experience',
          type: 'number',
          aggregate: true,
        },
        {
          header: 'Module Leads',
          render: ({row, ...rest}) => {
            const value = row['modules'] || [];
            const moduleOwners = value
              .map(_value => {
                return _value.owner_id;
              })
              .filter(_value => _value);
            const uniqModuleOwners = _.uniqBy(
              moduleOwners,
              _value => _value._id,
            );
            const avatars = uniqModuleOwners.map((_value, index) => {
              return <UserAvatar value={_value} key={index} size={24} />;
            });
            if (avatars.length)
              return (
                <AvatarGroup avatarSize={24} avatars={avatars} {...rest} />
              );
            else {
              return (
                <Image source={icons.Owner} style={{height: 24, width: 24}} />
              );
            }
          },
          minWidth: 200,
          onPress: ({row}) => {
            navigation.navigate('project-modules', {
              project: row,
            });
          },
        },
        {
          header: 'Label',
          field: 'new_project_sub_type.project_sub_type',
          type: 'colorTag',
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProject,
        },
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        {
          title: 'View All Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                project_id: row?._id,
              },
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) => row.project_status == 'h',
        },
        {
          title: 'Mark Hold',
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status !== 'h',
        },
        {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
          visible: ({row}) => row?.projecttype == 'f',
        },
        {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
          visible: ({row}) => row?.projecttype == 'f',
        },
        tabName !== 'sdlcplus' && {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        tabName == 'sdlcplus' && {
          title: 'Add Target',
          onPress: ({row}) => {
            navigation.navigate('add-fixed-order-projection', {
              projectionfilter: {
                customer: row?.customer?._id,
                project: row?._id,
              },
              row: {project: {_id: row?._id, project: row?.project}},
            });
          },
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
        // {
        //   title: 'View Project Plans',
        //   onPress: projectPlanDetails,
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'View Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-fixed-employee-request-tab', {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => row?.projecttype == 'f',
        // },
        // {
        //   title: 'Order Mapped',
        //   onPress: ({row}) => {
        //     navigation.navigate(
        //       row?.projecttype == 'f' ? `fixed-order-map` : `tm-order-map`,
        //       {
        //         row: {
        //           ...row,
        //           projectTab: 'Billed',
        //         },
        //       },
        //     );
        //   },
        // },
      ]}
    />
  );
};

export const PerformanceBilledUnassignedTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    project_status,
    tab,
  } = props;
  const {
    projectDetail,
    editProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    activityLogDetail,
    editProjectRouteForNonAdmin,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  const markHold = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'h',
      },
    });
  };

  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams, tab}}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={[
        renderProject({projectDetail}),
        CUSTOMER,
        CSM,
        totalPotential({tabName}),
        remainingPotential({tabName}),
        orderEngg({tabName}),
        teamAllocation({tabName, navigation}),
        avgExperience({tabName}),
        lastEfficiency({tabName}),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProject,
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        {
          title: 'View All Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                project_id: row?._id,
              },
            });
          },
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) => row.project_status == 'h',
        },
        {
          title: 'Mark Hold',
          // confirm: {
          //   title: 'Mark Hold',
          //   message: 'Are you sure you want to mark hold this project?',
          //   confirmText: 'Hold',
          // },
          // onPress: markHold,
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status == 'a',
        },
        {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
          visible: ({row}) => row?.projecttype == 'f',
        },
        {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
          visible: ({row}) => row?.projecttype == 'f',
        },
        tabName !== 'sdlcplus' && {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        tabName == 'sdlcplus' && {
          title: 'Add Target',
          onPress: ({row}) => {
            navigation.navigate('add-fixed-order-projection', {
              projectionfilter: {
                customer: row?.customer?._id,
                project: row?._id,
              },
              row: {project: {_id: row?._id, project: row?.project}},
            });
          },
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
      ]}
    />
  );
};

export const PerformanceBilledCompletedReportTable = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    tab,
    groupBy,
  } = props;
  const {projectDetail, activityLogDetail} = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  return (
    <Table
      {...props}
      limit={500}
      params={{...filterParams, tab}}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={[
        renderProject({projectDetail}),
        CUSTOMER,
        CSM,
        totalPotential({tabName}),
        teamAllocation({tabName, navigation}),
        totalBilledHours({tabName}),
        {
          header: 'Closed On',
          type: 'date',
          field: 'completed_on',
          formatOptions: {
            format: 'DD MMM YYYY',
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'View All Allocations',
          onPress: ({row}) => {
            navigation.navigate('all-allocated-resource-list', {
              projectFilter: {
                project_id: row?._id,
              },
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) =>
            row.project_status == 'h' || row.project_status == 'c',
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
      ]}
    />
  );
};

export const PerformanceBilledReportTableForAm = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    project_status,
  } = props;
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    projectPlanDetails,
    activityLogDetail,
    editProjectRouteForNonAdmin,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  const markHold = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'h',
      },
    });
  };
  return (
    <Table
      {...props}
      limit={500}
      params={filterParams}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      columns={[
        // renderProject({projectDetail}),
        // CSM,
        // teamAllocation({tabName, navigation}),
        // avgExperience({tabName}),
        // lastEfficiency({tabName}),
        // lastCsat({project_status}),
        // escalation({project_status}),
        // onHold({project_status}),
        // activeDuration({project_status}),
        // projectPlanStatus({navigation}),
        renderProject({projectDetail}),
        project_status == 'c' && OWNER,
        CUSTOMER,
        project_status !== 'c' && CSM,
        project_status !== 'c' && totalPotential({tabName}),
        project_status !== 'c' && remainingPotential({tabName}),
        project_status !== 'c' && orderEngg({tabName}),
        project_status !== 'c' && teamAllocation({tabName, navigation}),
        project_status !== 'c' && avgExperience({tabName}),
        project_status !== 'h' &&
          project_status !== 'c' &&
          lastEfficiency({tabName}),
        project_status === 'h' && onHoldDate({tabName}),
        project_status === 'h' && onHoldReason({tabName}),
        projectPlanStatus({navigation}),
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProjectRouteForNonAdmin,
        },
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) => row.project_status == 'h',
        },
        {
          title: 'Mark Hold',
          // confirm: {
          //   title: 'Mark Hold',
          //   message: 'Are you sure you want to mark hold this project?',
          //   confirmText: 'Hold',
          // },
          // onPress: markHold,
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status == 'a',
        },
        tabName == 'sdlcplus' && {
          title: 'View Project Plans',
          onPress: projectPlanDetails,
        },
        tabName !== 'sdlcplus' && {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};

export const PerformanceBilledReportTableForPm = props => {
  let {
    navigation,
    route: {params},
    filterValues: {params: filterParams = {}},
    searchValue,
    addOnFilter = {},
    tabName,
    project_status,
  } = props;
  const {
    projectDetail,
    projectTeam,
    editProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    projectPlanDetails,
    activityLogDetail,
    editProjectRouteForNonAdmin,
  } = useProjectActions({
    navigation,
    params,
  });
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const markActive = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'a',
      },
    });
  };

  const markHold = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'h',
      },
    });
  };
  return (
    <Table
      {...props}
      limit={500}
      params={filterParams}
      search={searchValue}
      addOnFilter={addOnFilter}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      columns={[
        // renderProject({ projectDetail}),
        // CSM,
        // totalPotential({tabName}),
        // remainingPotential({tabName}),
        // teamAllocation({tabName, navigation}),
        // avgExperience({tabName}),
        // lastEfficiency({tabName}),
        // lastCsat({project_status}),
        // escalation({project_status}),
        // onHold({project_status}),
        // activeDuration({project_status}),
        // projectPlanStatus({navigation}),
        renderProject({projectDetail}),
        project_status == 'c' && OWNER,
        CUSTOMER,
        project_status !== 'c' && CSM,
        project_status !== 'c' && totalPotential({tabName}),
        project_status !== 'c' && remainingPotential({tabName}),
        project_status !== 'c' && orderEngg({tabName}),
        project_status !== 'c' && teamAllocation({tabName, navigation}),
        project_status !== 'c' && avgExperience({tabName}),
        project_status !== 'h' &&
          project_status !== 'c' &&
          lastEfficiency({tabName}),
        project_status === 'h' && onHoldDate({tabName}),
        project_status === 'h' && onHoldReason({tabName}),
      ]}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editProjectRouteForNonAdmin,
        },
        {
          title: 'Team Members',
          onPress: projectTeam,
        },
        {
          title: 'Mark Complete',
          confirm: {
            title: 'Complete',
            message: 'Are you sure you want to mark complete this project?',
            confirmText: 'Complete',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Active',
            message: 'Are you sure you want to mark active this project?',
            confirmText: 'Active',
          },
          onPress: markActive,
          visible: ({row}) => row.project_status == 'c',
        },
        {
          title: 'Mark Hold',
          // confirm: {
          //   title: 'Mark Hold',
          //   message: 'Are you sure you want to mark hold this project?',
          //   confirmText: 'Hold',
          // },
          // onPress: markHold,
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status == 'a',
        },
        tabName == 'sdlcplus' && {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
        },
        tabName == 'sdlcplus' && {
          title: 'View Project Plans',
          onPress: projectPlanDetails,
        },
        tabName == 'sdlcplus' && {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
        },
        tabName == 'sdlcplus' && {
          title: 'View Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-fixed-employee-request-tab', {
              row,
            });
          },
        },
        tabName !== 'sdlcplus' && {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        tabName == 'sdlcplus' && {
          title: 'Add Target',
          onPress: ({row}) => {
            navigation.navigate('add-fixed-order-projection', {
              projectionfilter: {
                customer: row?.customer?._id,
                project: row?._id,
              },
            });
          },
        },
        {
          title: 'Client Feedbacks',
          onPress: ({row}) => {
            navigation.navigate('client-feedback-tabs', {
              project: row,
            });
          },
        },
        comments({
          navigation,
          params,
        }),
        {
          title: 'Activity Logs',
          onPress: activityLogDetail,
        },
      ]}
    />
  );
};

export const PerformanceUnbilledReportTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    tab: props_tab,
    project_status,
    tabName,
    fromUnbilled = false,
    addOnParamFilter: propsAddOnParams,
  } = props;
  const {fromUnbilled: unbilled} = params;
  let tab = props_tab || params?.tab;
  let addOnParamFilter = propsAddOnParams || params?.addOnParamFilter;
  const {
    projectDetail,
    editUnbilledProject,
    projectOrderMappings,
    projectPlanDetails,
  } = useProjectActions({
    navigation,
    params,
  });
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const {params: filterParams} = filterValues;
  return (
    <Table
      {...props}
      limit={500}
      renderHeader={() => (
        <TableHeader
          title={`${tab} Unbilled Projects`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'autoComplete',
                  options: [
                    'Client Managed',
                    'Daffodil Managed',
                    'Hybrid Managed',
                    'Daffodil Managed - Support',
                  ],
                  field: 'project_sub_category',
                  label: 'Managed By',
                },
                {
                  label: 'Tool',
                  field: 'tool',
                  placeholder: 'Select Tool',
                  type: 'autoComplete',
                  options: ['Locomo', 'Other'],
                },
                {
                  label: 'Feedback Mode',
                  field: 'feedback_mode',
                  placeholder: 'Select Feedback Mode',
                  type: 'autoComplete',
                  options: ['Automatic', 'Manual'],
                },
                {
                  label: 'Last automatic feedback request',
                  field: 'automatic_feedback_request_success_date',
                  type: 'date',
                },
                {
                  label: 'Last automatic feedback failure',
                  field: 'automatic_feedback_request_failure_date',
                  type: 'date',
                },
              ]}
            />,
            tab !== 'Closed' && (
              <AddButton
                title={'Project'}
                view={'add-unbilled-project'}
                params={{product: params?.product?._id}}
              />
            ),
          ]}
        />
      )}
      params={{
        ...filterParams,
        tab,
        fromUnbilled: unbilled ? unbilled : fromUnbilled,
      }}
      search={searchValue}
      addOnFilter={{...filter, ...filterValues.filter, ...addOnParamFilter}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={
        project_status !== 'h' && project_status !== 'c'
          ? [
              renderProject({
                projectDetail,
                showCustomer: false,
              }),
              FOR,
              CATEGORY,
              HOURS,
              {
                header: 'Man Month Estimate',
                field: 'man_month_estimate',
                type: 'number',
                width: 200,
              },
              totalPotential({tabName}),
              CSM,
              teamAllocation({tabName, navigation}),
              // avgExperience({tabName}),
              lastEfficiency({tabName}),
              // onHold({project_status}),
              // activeDuration({project_status}),
            ]
          : [
              renderProject({projectDetail}),
              CUSTOMER,
              CSM,
              totalEstEffort({tabName: 'sdlcplus'}),
              project_status == 'h' &&
                // remainingPotential({tabName: 'sdlcplus'}),
                teamAllocation({tabName, navigation}),
              project_status == 'h' && onHoldDate({tabName}),
              project_status == 'h' && onHoldReason({tabName}),
              project_status == 'c' && totalBilledHours({tabName}),
              project_status == 'c' && {
                header: 'Closed On',
                type: 'date',
                field: 'completed_on',
                formatOptions: {
                  format: 'DD MMM YYYY',
                },
              },
            ]
      }
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editUnbilledProject,
        },
        {
          title: 'Mark closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to mark closed this project?',
            confirmText: 'Closed',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Hold',
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
          visible: ({row}) => row.project_status != 'h',
        },
        // {
        //   title: 'Plan Projects',
        //   onPress: projectOrderMappings,
        // },
        // {
        //   title: 'View Project Plans',
        //   onPress: projectPlanDetails,
        // },
        // {
        //   title: 'Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-employee-request-for-fixed', {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'View Request resource',
        //   onPress: ({row}) => {
        //     navigation.navigate('project-wise-fixed-employee-request-tab', {
        //       row,
        //     });
        //   },
        // },
        {
          title: 'Assign Internal Allocation',
          onPress: ({row}) => {
            navigation.navigate('internal-allocation', {
              row,
            });
          },
        },
        // {
        //   title: 'Internal Resource Request',
        //   onPress: ({row}) => {
        //     navigation.navigate('internal-resource-request', {
        //       row,
        //     });
        //   },
        // },
        {
          title: 'Request estimate revision',
          onPress: ({row}) => {
            navigation.navigate('request-estimate-revision', {
              project: row,
            });
          },
        },
        // {
        //   title: 'Order Mapped',
        //   onPress: ({row}) => {
        //     navigation.navigate(`fixed-order-map`, {
        //       row: {
        //         ...row,
        //         projectTab: 'NonBilled',
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const PerformanceUnbilledReportUnAssignedTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    tab,
    project_status,
    tabName,
  } = props;
  const {
    projectDetail,
    editUnbilledProject,
    projectOrderMappings,
    projectPlanDetails,
  } = useProjectActions({
    navigation,
    params,
  });
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const {params: filterParams} = filterValues;
  return (
    <Table
      {...props}
      limit={500}
      renderHeader={() => (
        <TableHeader
          title={`${tab} Unbilled Projects`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'autoComplete',
                  options: [
                    'Client Managed',
                    'Daffodil Managed',
                    'Hybrid Managed',
                    'Daffodil Managed - Support',
                  ],
                  field: 'project_sub_category',
                  label: 'Managed By',
                },
                {
                  label: 'Tool',
                  field: 'tool',
                  placeholder: 'Select Tool',
                  type: 'autoComplete',
                  options: ['Locomo', 'Other'],
                },
                {
                  label: 'Feedback Mode',
                  field: 'feedback_mode',
                  placeholder: 'Select Feedback Mode',
                  type: 'autoComplete',
                  options: ['Automatic', 'Manual'],
                },
                {
                  label: 'Last automatic feedback request',
                  field: 'automatic_feedback_request_success_date',
                  type: 'date',
                },
                {
                  label: 'Last automatic feedback failure',
                  field: 'automatic_feedback_request_failure_date',
                  type: 'date',
                },
              ]}
            />,
            <AddButton
              title={'Project'}
              view={'add-unbilled-project'}
              params={{product: params?.product?._id}}
            />,
          ]}
        />
      )}
      params={{...filterParams, tab}}
      search={searchValue}
      addOnFilter={{...filter, ...filterValues.filter}}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      onRowPress={({row}) => {
        navigation.navigate('project-detail-non-editable', {project: row});
      }}
      columns={[
        renderProject({projectDetail, showCustomer: false}),
        FOR,
        CATEGORY,
        HOURS,
        CSM,
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editUnbilledProject,
        },
        {
          title: 'Mark closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to mark closed this project?',
            confirmText: 'Closed',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Mark Hold',
          onPress: ({row}) => {
            navigation.navigate('project-hold', {
              row,
            });
          },
        },
        {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
        },
        {
          title: 'View Project Plans',
          onPress: projectPlanDetails,
        },
        {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
        },
        {
          title: 'View Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-fixed-employee-request-tab', {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const PerformanceUnbilledReportTableAm = props => {
  let {
    navigation,
    route: {params},
    filter,
    tab,
    project_status,
    tabName,
    fromUnbilled = false,
  } = props;
  const {
    projectDetail,
    editUnbilledProject,
    projectOrderMappings,
    projectOrderMappingsForTm,
    projectPlanDetails,
  } = useProjectActions({
    navigation,
    params,
  });
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {params: filterParams} = filterValues;
  return (
    <Table
      {...props}
      limit={500}
      renderHeader={() => (
        <TableHeader
          title={`${tab} Unbilled Projects`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'autoComplete',
                  options: [
                    'Client Managed',
                    'Daffodil Managed',
                    'Hybrid Managed',
                    'Daffodil Managed - Support',
                  ],
                  field: 'project_sub_category',
                  label: 'Managed By',
                },
                {
                  label: 'Tool',
                  field: 'tool',
                  placeholder: 'Select Tool',
                  type: 'autoComplete',
                  options: ['Locomo', 'Other'],
                },
                {
                  label: 'Feedback Mode',
                  field: 'feedback_mode',
                  placeholder: 'Select Feedback Mode',
                  type: 'autoComplete',
                  options: ['Automatic', 'Manual'],
                },
                {
                  label: 'Last automatic feedback request',
                  field: 'automatic_feedback_request_success_date',
                  type: 'date',
                },
                {
                  label: 'Last automatic feedback failure',
                  field: 'automatic_feedback_request_failure_date',
                  type: 'date',
                },
              ]}
            />,
            <AddButton
              title={'Project'}
              view={'add-unbilled-project'}
              params={{product: params?.product?._id}}
            />,
          ]}
        />
      )}
      params={{...filterParams, fromUnbilled}}
      search={searchValue}
      addOnFilter={{
        ...filter,
        ...filterValues.filter,
        account_owner: employeeId?._id,
      }}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      columns={
        project_status !== 'c'
          ? [
              renderProject({
                projectDetail,
                showCustomer: false,
              }),
              FOR,
              // {
              //   header: 'Type',
              //   render: ({row}) => {
              //     const {projecttype} = row;
              //     let projectType = '';
              //     if (projecttype === 'tm') {
              //       projectType = 'T&M';
              //     } else if (projecttype === 'i') {
              //       projectType = 'Internal';
              //     } else if (projecttype === 'f') {
              //       projectType = 'Fixed';
              //     }
              //     return (
              //       <PrimaryColumnCard
              //         primaryTitle={row.type}
              //         items={[
              //           {
              //             value: projectType,
              //           },
              //         ]}
              //       />
              //     );
              //   },
              // },
              teamAllocation({tabName, navigation}),
              avgExperience({tabName}),
              lastEfficiency({tabName}),
              CSM,
              onHold({project_status}),
              activeDuration({project_status}),
              projectPlanStatus({navigation}),
            ]
          : [
              renderProject({projectDetail}),
              CUSTOMER,
              CSM,
              totalPotential({tabName: 'sdlcplus'}),
              teamAllocation({tabName, navigation}),
              totalBilledHours({tabName}),
              {
                header: 'Closed On',
                type: 'date',
                field: 'completed_on',
                formatOptions: {
                  format: 'DD MMM YYYY',
                },
              },
            ]
      }
      moreActions={() => [
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('project-detail-non-editable', {project: row});
          },
        },
        {
          title: 'Mark closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to mark closed this project?',
            confirmText: 'Closed',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'View Project Plans',
          onPress: projectPlanDetails,
        },
        {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        // {
        //   title: 'Order Mapped',
        //   onPress: ({row}) => {
        //     navigation.navigate(`fixed-order-map`, {
        //       row: {
        //         ...row,
        //         projectTab: 'NonBilled',
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const PerformanceUnbilledReportTablePm = props => {
  let {
    navigation,
    route: {params},
    filter,
    tab,
    project_status,
    tabName,
    fromUnbilled = false,
  } = props;
  const {
    projectDetail,
    editUnbilledProject,
    projectOrderMappings,
    projectPlanDetails,
    projectOrderMappingsForTm,
  } = useProjectActions({
    navigation,
    params,
  });
  const {applyFilter, filterValues, onChangeFilter, searchValue} = useFilter({
    params: {
      period: params.period || getCurrentMonth(),
    },
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['Project', 'performance-report'],
  });

  const markComplete = ({row}) => {
    invoke({
      uri: `/projects/${row?._id}`,
      props: {
        project_status: 'c',
        completed_on: new Date(),
      },
    });
  };

  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  const {params: filterParams} = filterValues;
  return (
    <Table
      {...props}
      limit={500}
      renderHeader={() => (
        <TableHeader
          title={`${tab} Unbilled Projects`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Customer',
                  api: '/customers',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  valueField: 'name',
                  field: 'customer',
                },
                {
                  type: 'autoComplete',
                  options: [
                    'Client Managed',
                    'Daffodil Managed',
                    'Hybrid Managed',
                    'Daffodil Managed - Support',
                  ],
                  field: 'project_sub_category',
                  label: 'Managed By',
                },
                {
                  label: 'Tool',
                  field: 'tool',
                  placeholder: 'Select Tool',
                  type: 'autoComplete',
                  options: ['Locomo', 'Other'],
                },
                {
                  label: 'Feedback Mode',
                  field: 'feedback_mode',
                  placeholder: 'Select Feedback Mode',
                  type: 'autoComplete',
                  options: ['Automatic', 'Manual'],
                },
                {
                  label: 'Last automatic feedback request',
                  field: 'automatic_feedback_request_success_date',
                  type: 'date',
                },
                {
                  label: 'Last automatic feedback failure',
                  field: 'automatic_feedback_request_failure_date',
                  type: 'date',
                },
              ]}
            />,
            <AddButton
              title={'Project'}
              view={'add-unbilled-project'}
              params={{product: params?.product?._id}}
            />,
          ]}
        />
      )}
      params={{...filterParams, fromUnbilled}}
      search={searchValue}
      addOnFilter={{
        ...filter,
        ...filterValues.filter,
        owner_id: employeeId?._id,
      }}
      eventSourceId={'performance-report'}
      searchFields={['project']}
      api={'/project/performance-report'}
      columns={
        project_status !== 'c'
          ? [
              renderProject({
                projectDetail,
                showCustomer: false,
              }),
              // {
              //   header: 'Type',
              //   render: ({row}) => {
              //     const {projecttype} = row;
              //     let projectType = '';
              //     if (projecttype === 'tm') {
              //       projectType = 'T&M';
              //     } else if (projecttype === 'i') {
              //       projectType = 'Internal';
              //     } else if (projecttype === 'f') {
              //       projectType = 'Fixed';
              //     }
              //     return (
              //       <PrimaryColumnCard
              //         primaryTitle={row.type}
              //         items={[
              //           {
              //             value: projectType,
              //           },
              //         ]}
              //       />
              //     );
              //   },
              // },
              FOR,
              teamAllocation({tabName, navigation}),
              avgExperience({tabName}),
              lastEfficiency({tabName}),
              CSM,
              onHold({project_status}),
              activeDuration({project_status}),
              projectPlanStatus({navigation}),
            ]
          : [
              renderProject({projectDetail}),
              CUSTOMER,
              CSM,
              totalPotential({tabName: 'sdlcplus'}),
              teamAllocation({tabName, navigation}),
              totalBilledHours({tabName}),
              {
                header: 'Closed On',
                type: 'date',
                field: 'completed_on',
                formatOptions: {
                  format: 'DD MMM YYYY',
                },
              },
            ]
      }
      moreActions={() => [
        {
          title: 'Edit',
          onPress: editUnbilledProject,
        },
        {
          title: 'Mark closed',
          confirm: {
            title: 'Closed',
            message: 'Are you sure you want to mark closed this project?',
            confirmText: 'Closed',
          },
          onPress: markComplete,
          visible: ({row}) => row.project_status != 'c',
        },
        {
          title: 'Plan Projects',
          onPress: projectOrderMappings,
        },
        {
          title: 'View Project Plans',
          onPress: projectPlanDetails,
        },
        {
          title: 'Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-employee-request-for-fixed', {
              row,
            });
          },
        },
        {
          title: 'View Request resource',
          onPress: ({row}) => {
            navigation.navigate('project-wise-fixed-employee-request-tab', {
              row,
            });
          },
        },
        {
          title: 'View Order Map',
          onPress: projectOrderMappingsForTm,
        },
        // {
        //   title: 'Order Mapped',
        //   onPress: ({row}) => {
        //     navigation.navigate(`fixed-order-map`, {
        //       row: {
        //         ...row,
        //         projectTab: 'NonBilled',
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const projectEmployees = props => {
  const {
    navigation,
    route: {params},
  } = props;
  return (
    <Table
      {...props}
      renderHeader={() => <TableHeader title={`Project Team`} />}
      eventSourceId={['escalationreasons']}
      api={`/employees/active-employees`}
      addOnFilter={{_id: {$in: params.project_team}}}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row.color}
                official_email_id={row.official_email_id}
              />
            );
          },
        },
        {
          header: 'Employee Code',
          field: 'employee_code',
          type: 'text',
        },
        {
          header: 'Experience',
          field: 'experience',
          type: 'text',
        },
      ]}
    />
  );
};
