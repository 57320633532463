import React from 'react';
import { LeftMenuView } from '../../../components/menu/LeftMenuView';
import { RecurringTaskAssignmentTabs } from '../../task/views/RecurringTaskAssignmentTabs';
import { ReviewTasksTabs } from '../../task/views/ReviewTasksTabs';
import { BacklogOrganizationTaskTable } from '../../task/views/BacklogOrganizationTaskTable';
import { ArchivedOrganizationTaskTable } from '../../task/views/ArchivedOrganizationTaskTable';
import { StrategicTaskTabs } from '../../task/views/StrategicTaskTabs';
import { OrganizationActiveTasksGroupByPlan } from '../../task/views/OrganizationActiveTasksGroupByPlan';
import { ThreadGoalTabs, ThreadIssuesTabs } from '../../task/views/ThreadGoalAndIssueTabs';
import { TabView } from '../../../components/tab';
import { CompletedTasksTable } from './TasksTable';
import { ThreadMeetingNotesTable } from './ThreadMeetingNotesTable';
import { CommentScreen } from '../../pmt/views/CommentTable';
import { BorderLessButton } from '../../../components/button/AddButton';
import { useFetchData } from '../../../controllers/useFetchData';

export const ThreadTaskMenus = props => {
    const { route: { params } = {} } = props;
    const { thread = {} } = params;

    const menus = [
        {
            label: 'Tasks',
            view: <ThreadTasksTabs />,
        },
        {
            label: 'Goals',
            view: <ThreadGoalTabs />,
        },
        {
            label: 'Issues',
            view: <ThreadIssuesTabs />,
        },
        {
            label: 'Meeting Notes',
            view: <ThreadMeetingNotesTable />,
        },
        {
            label: 'Chats',
            view: <CommentScreen />
        },
        {
            label: 'Recurring',
            view: <RecurringTaskAssignmentTabs />,
            api: '/mastertaskassignments',
            eventSourceId: 'MasterTaskAssignment',
            filter: {
                status: 'Active',
                thread: thread?._id,
            },
        },
    ];

    return <LeftMenuView {...props} params={params} menus={menus} />;
};

export const ThreadTasksTabs = props => {
    const {
        route: { params },
        navigation,
        user
    } = props;

    const { employee_team, userId, thread = {}, threadGoalId, threadMeetingNotes, threadNameNotVisible, threadGoalNameNotVisible} = params;
    const threadFilter = thread?._id ? { thread: thread._id , thread_goal: threadGoalId , thread_meeting_notes: threadMeetingNotes} : {};

    const { data } = useFetchData({
        api: '/projecttasks',
        filter: {
            status: 'archived',
            type: {
                $in: ['Task', 'Continuous'],
            },
            project_id: null,
            ...threadFilter
        },
        eventSourceId: 'Task',
        count: true,
    });

    return (
        <TabView
            actions={[
                <BorderLessButton
                    text={`Archived (${data?.count || 0})`}
                    onPress={() => {
                        navigation.navigate('archived-tasks', {
                            ...params,
                            userId,
                            thread,
                            threadGoalId,
                        });
                    }}
                />
            ]}
            tabs={{
                backlog: {
                    label: 'Plan',
                    api: '/organizationtasks',
                    filter: {
                        status: 'backlog',
                        type: {
                            $in: ['Task', 'Continuous'],
                        },
                        project_id: null,
                        ...threadFilter,
                    },
                    eventSourceId: 'Task',
                    view: <BacklogOrganizationTaskTable threadNameNotVisible={threadNameNotVisible} threadGoalNameNotVisible={threadGoalNameNotVisible}/>,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan threadNameNotVisible={threadNameNotVisible} threadGoalNameNotVisible={threadGoalNameNotVisible}/>,
                    api: '/organizationtasks',
                    params: { teamId: employee_team?._id, userId },
                    eventSourceId: 'Task',
                    filter: {
                        status: 'active',
                        type: {
                            $in: ['Task', 'Continuous'],
                        },
                        project_id: null,
                        ...threadFilter,
                    },
                },
                completed: {
                    label: 'Completed',
                    view: <CompletedTasksTable threadNameNotVisible={threadNameNotVisible} threadGoalNameNotVisible={threadGoalNameNotVisible}/>,
                },
                // archived: {
                //     label: 'Archived',
                //     view: <ArchivedOrganizationTaskTable />,
                //     api: '/organizationtasks',
                //     params: { teamId: employee_team?._id, userId },
                //     filter: {
                //         status: 'archived',
                //         type: {
                //             $in: ['Task', 'Continuous'],
                //         },
                //         project_id: null,
                //         ...threadFilter,
                //     },
                //     eventSourceId: ['Task'],
                // },
            }}
            {...props}
        />
    );
};
