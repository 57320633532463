import React from 'react';
import {Table} from '../../../components/table/Table';
import moment from 'moment';
import {
  ComparisonRender,
  AggregateComparisonRender,
} from '../../../components/table/Renders';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
const RenderInvoiceType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (
    row?.invoice_type &&
    (row?.invoice_type == 'Asset' || row?.invoice_type == 'Debit')
  ) {
    return (
      <>
        <Tag value={row?.invoice_type} color={colors?.ACCENT5_MEDIUM} />
      </>
    );
  } else if (
    row?.invoice_type &&
    (row?.invoice_type == 'Expense' || row?.invoice_type == 'Empcost')
  ) {
    return (
      <>
        <Tag value={row?.invoice_type} color={colors?.ACCENT3_MEDIUM} />
      </>
    );
  } else if (
    row?.invoice_type &&
    (row?.invoice_type == 'Salary' || row?.invoice_type == 'Cost')
  ) {
    return <Tag value={row?.invoice_type} color={colors?.ACCENT6_MEDIUM} />;
  } else if (
    row?.invoice_type &&
    (row?.invoice_type == 'Debit' ||
      row?.invoice_type == 'Depreciation' ||
      row?.invoice_type == 'Invoice')
  ) {
    return <Tag value={row?.invoice_type} color={colors?.ACCENT4_MEDIUM} />;
  } else if (
    row?.invoice_type &&
    (row?.invoice_type == 'Other' || row?.invoice_type == 'Provision')
  ) {
    return <Tag value={row?.invoice_type} color={colors?.ACCENT2_MEDIUM} />;
  } else {
    return null;
  }
};

export const useInvoiceActions = ({navigation}) => {
  const activityLogDetailForInvoice = ({row}) => {
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'product', select: {name: 1}},
        {path: 'organization', select: {name: 1}},
        {path: 'location', select: {name: 1}},
        {path: 'adjusted_by', select: {name: 1}},
      ],
      api: `/vendorinvoices/${row?._id}/activityLogs`,
      displayFields: {
        invoice_type: {
          label: 'invoice Type',
        },
        purpose: {
          label: 'Description',
        },
        exp_amount: {
          label: 'Expense Amount',
        },
        bill_number: {
          label: 'Bill Number',
        },
        schedule_from_date: {
          label: 'Schedule From Date',
        },
        schedule_to_date: {
          label: 'Schedule To Date',
        },
        total_tax_amount: {
          label: 'Total Tax Amount',
        },
        pending_amount: {
          label: 'Pending Amount',
        },
        paid_amount: {
          label: 'Paid Amount',
        },
        adjusted_by: {
          label: 'Adjusted By',
          field: 'adjusted_by.name',
        },
        currency: {
          label: 'Currency',
          field: 'currency.currency',
        },
        conversion_rate: {
          label: 'Exchange Rate',
          field: 'conversion_rate',
        },
        product: {
          label: 'Product',
          field: 'product.name',
        },
        organization: {
          label: 'Organization',
          field: 'organization.name',
        },
        location: {
          label: 'Location',
          field: 'location.name',
        },
        voucher_type_id: {
          label: 'Voucher Type',
          field: 'voucher_type_id.name',
        },
      },
    });
  };

  return {
    activityLogDetailForInvoice,
  };
};

// export const VendorInvoiceTable = props => {
//   const {navigation, route} = props;
//   const params = route?.params;
//   const {period} = params;

//   let previousPeriod = {};
//   if (period.from) {
//     previousPeriod.from = moment
//       .utc(period.from)
//       .subtract(1, 'M')
//       .startOf('Month')
//       .toDate();
//     previousPeriod.to = moment
//       .utc(period.from)
//       .subtract(1, 'M')
//       .endOf('Month')
//       .toDate();
//   }

//   return (
//     <Table
//       api={`/vendorinvoices/direct-expense`}
//       params={{...params, invoice_type: 'Expense'}}
//       limit={5000}
//       columns={[
//         {
//           type: 'text',
//           field: 'payee_id.name',
//           header: 'Name',
//         },
//         {
//           width: 300,
//           type: 'currency',
//           field: 'voucher_amount',
//           compareField: 'pre_voucher_amount',
//           header: 'Amount',
//           aggregate: {
//             render: AggregateComparisonRender,
//           },
//           onPress: ({row}) => {
//             navigation.navigate('vendor-invoices', {
//               ...params,
//               payee_id: row.payee_id?._id,
//             });
//           },
//           onComparePress: ({row}) => {
//             navigation.navigate('vendor-invoices', {
//               ...params,
//               payee_id: row.payee_id?._id,
//               period: previousPeriod,
//             });
//           },
//           render: ComparisonRender,
//         },
//       ]}
//     />
//   );
// };

export const VendorInvoiceTable = props => {
  let {
    route: {params = {}},
    navigation,
    searchValue,
    addOnFilter,
    filterValues,
  } = props;
  if (filterValues && filterValues.filter) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const {user} = useAppStateContext();
  const {employee = {}} = user;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'VendorInvoiceView',
  });
  const invokePut = useInvoke({
    method: 'put',
    eventSourceId: 'VendorInvoiceView',
  });
  if(params?.addOnFilter){
    addOnFilter = {...addOnFilter,...params.addOnFilter}
  }
  const {download} = useAppStateContext();
  const {activityLogDetailForInvoice} = useInvoiceActions({
    navigation,
    params,
  });
  return (
    <Table
      api="/vendorInvoices/all"
      search={searchValue}
      eventSourceId="VendorInvoiceView"
      searchFields="invoice_no"
      addOnFilter={addOnFilter}
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-invoice-details', {
          row,
        })
      }
      selection={{
        actions: [
          {
            title: 'Change Department',
            onPress: ({selectedIds}) => {
              navigation.navigate('change-department', {
                selectedIds,
              });
            },
          },
        ],
      }}
      columns={[
        {
          type: 'text',
          header: 'Vendor',
          field: 'payee_id',
          type: 'userAvatarChip',
          minWidth: 250,
        },
        {
          type: 'text',
          header: 'Invoice No.',
          field: 'invoice_no',
          width: 150,
        },
        {
          type: 'text',
          header: 'Type',
          field: 'invoice_type',
          render: RenderInvoiceType,
          width: 150,
        },
        {
          type: 'date',
          header: 'Date',
          field: 'invoice_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 150,
        },
        {
          aggregate: true,
          header: 'Amount',
          type: 'currency',
          field: 'voucher_amount',
          toFixed: 2,
          width: 150,
        },
        {
          aggregate: true,
          header: 'Payable',
          type: 'currency',
          field: 'payable_amount',
          toFixed: 2,
          width: 150,
        },
        {
          aggregate: true,
          header: 'Pending',
          type: 'currency',
          field: 'pending_amount',
          toFixed: 2,
          width: 150,
        },
        {
          type: 'date',
          header: 'Due Date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          field: 'due_date',
          width: 150,
        },
      ]}
      {...props}
      moreActions={[
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {bill_id: row._id},
            });
          },
        },
        {
          title: 'Payments',
          onPress: ({row}) => {
            navigation.navigate(`invoice-payment`, {
              extraFilter: {'payment_detail.invoice_id': row._id},
            });
          },
        },
        {
          title: 'Not Update Voucher',
          onPress: ({row}) => {
            navigation.navigate('mark-not-update-voucher', {
              row,
            });
          },
          visible:() => {
            if (
              user.email === 'sushil.nagvan@daffodilsw.com'
            ) {
              return true;
            }
          }
        },
        {
          title: 'Approve',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to approve this invoice?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/approve/${row._id}`,
              props: {
                is_approved: true,
                approved_by: {_id: employee?._id},
              },
            });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to delete this invoice?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/invoice/delete/${row._id}`,
            });
          },
          visible: () => {
            if (
              user.email === 'sushil.nagvan@daffodilsw.com' ||
              user.email === 'amit.singh@daffodildb.com'
            ) {
              return true;
            }
          },
        },
        {
          title: 'Print Voucher',
          onPress: ({row}) => {
            download({
              uri: '/voucher/print-voucher',
              props: {
                bill_id: row._id,
              },
            });
          },
        },
        {
          title: 'Add Debit Note',
          onPress: ({row}) => {
            navigation.navigate('add-debit-note', {
              row,
            });
          },
        },
        {
          title: 'Settle Invoice',
          onPress: ({row}) => {
            navigation.navigate('settle-vendor-invoice', {
              row,
            });
          },
        },
        {
          title: 'Unapprove',
          confirm: {
            title: 'Unapprove',
            message: 'Are you sure you want to unapprove this invoice?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/unapprove/${row._id}`,
            });
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForInvoice,
        },
      ]}
    />
  );
};

export const PurchaseCategoryOverview = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;

  const {products, organizations, addOnFilter = {}, ...rest} = params;
  if (products) {
    addOnFilter.product = {$in: products?.map(item => item._id)};
  }

  if (organizations) {
    addOnFilter.organization = {$in: organizations?.map(item => item._id)};
  }
  return (
    <Table
      api="/getPurchaseCategoryOverview"
      eventSourceId="VendorInvoiceView"
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-invoice-details', {
          row,
        })
      }
      params={params}
      columns={[
        {
          header: 'Vendor',
          field: 'payee_id',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Purpose',
          field: 'purpose',
          minWidth: 350,
        },
        {
          type: 'text',
          header: 'Invoice No.',
          field: 'invoice_no',
          width: 150,
        },
        {
          aggregate: true,
          header: 'Amount',
          type: 'number',
          field: 'purchase_amount',
          toFixed: 2,
          width: 150,
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
      ]}
      {...props}
    />
  );
};

export const PurchaseCategoryDirectOverview = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {addOnFilter = {}, products, organizations, ...rest} = params;
  if (products) {
    addOnFilter.product = {$in: products?.map(item => item._id)};
  }

  if (organizations) {
    addOnFilter.organization = {$in: organizations?.map(item => item._id)};
  }
  return (
    <Table
      {...props}
      api="/getPurchaseCategoryDiretOverview"
      eventSourceId="VendorInvoiceView"
      onRowPress={({row}) =>
        navigation.navigate('edit-voucher', {
          row: {
            _id: row?.voucher_id?._id,
            voucher_no: row?.voucher_id?.voucher_no,
          },
        })
      }
      params={{addOnFilter,...rest}}
      columns={[
        {
          type: 'text',
          header: 'Voucher No.',
          field: 'voucher_id.voucher_no',
          width: 150,
        },
        {
          header: 'Purpose',
          field: 'voucher_id.narration',
          minWidth: 350,
        },
        {
          aggregate: true,
          header: 'Amount',
          type: 'number',
          field: 'amount',
          toFixed: 2,
          width: 150,
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
        {
          header: 'Entity',
          field: 'voucher_id.entity.name',
          type: 'userAvatarChip',
          width: 250,
        },
      ]}
    />
  );
};

export const PurchaseCategoryOverviewForSalary = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {
    period,
    departmentId,
    addOnFilter = {},
    vendorId,
    products,
    organizations,
  } = params;
  const filters = {};
  if (products) {
    filters.product = {$in: products};
  }
  if (organizations) {
    filters.organization = {$in: organizations};
  }
  return (
    <Table
      {...props}
      api="/vendorInvoices/all"
      eventSourceId="VendorInvoiceView"
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-invoice-details', {
          row,
        })
      }
      filter={{
        ...filters,
        ...addOnFilter,
        invoice_type: {
          $in: ['Salary', 'salary'],
        },
        invoice_date: {
          $gte: period.from,
          $lte: period.to,
        },
        department: departmentId,
        payee_id: vendorId,
      }}
      columns={[
        {
          header: 'Employee',
          field: 'payee_id',
          type: 'userAvatarChip',
          width: 250,
        },
        {
          header: 'Purpose',
          field: 'purpose',
          minWidth: 350,
        },
        {
          type: 'text',
          header: 'Invoice No.',
          field: 'invoice_no',
          width: 150,
        },
        {
          aggregate: true,
          header: 'Amount',
          type: 'number',
          field: 'voucher_amount',
          toFixed: 2,
          width: 150,
          formatOptions: {
            maximumFractionDigits: 0,
          },
        },
      ]}
    />
  );
};
