import {Text, View} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {Form} from '../../../components/form/Form';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const calculatePeriod = ({fromDate, toDate, currentDate, term}) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);
  const current = new Date(currentDate);
  let monthStartDate, monthEndDate;
  switch (term?.toLowerCase()) {
    case 'monthly':
      monthStartDate = new Date(from);
      while (true) {
        monthEndDate = new Date(monthStartDate);
        monthEndDate.setDate(monthEndDate.getDate() + 30); // Add 30 days
        if (monthStartDate <= current && current < monthEndDate) {
          break;
        }
        if (monthEndDate > to) {
          monthEndDate = to;
        }
        monthStartDate.setDate(monthStartDate.getDate() + 30);
      }
      break;

    case 'quaterly':
      monthStartDate = new Date(from);
      while (true) {
        monthEndDate = new Date(monthStartDate);
        monthEndDate.setDate(monthEndDate.getDate() + 90);
        if (monthStartDate <= current && current < monthEndDate) {
          break;
        }
        if (monthEndDate > to) {
          monthEndDate = to;
          break;
        }

        monthStartDate.setDate(monthStartDate.getDate() + 90);
      }
      break;

    case 'half yearly':
      monthStartDate = new Date(from);
      while (true) {
        monthEndDate = new Date(monthStartDate);
        monthEndDate.setDate(monthEndDate.getDate() + 180);
        if (monthStartDate <= current && current < monthEndDate) {
          break;
        }
        if (monthEndDate > to) {
          monthEndDate = to;
          break;
        }
        monthStartDate.setDate(monthStartDate.getDate() + 180);
      }
      break;

    case 'yearly':
      monthStartDate = new Date(from);
      while (true) {
        monthEndDate = new Date(monthStartDate);
        monthEndDate.setDate(monthEndDate.getDate() + 365);
        if (monthStartDate <= current && current < monthEndDate) {
          break;
        }
        if (monthEndDate > to) {
          monthEndDate = to;
          break;
        }
        monthStartDate.setFullYear(monthStartDate.getFullYear() + 1);
      }
      break;

    default:
      throw new Error(
        'Invalid term provided. Please use monthly, quarterly, half-yearly, or yearly.',
      );
  }
  if (monthStartDate < from) {
    monthStartDate = from;
  }
  if (monthEndDate > to) {
    monthEndDate = to;
  }

  return {
    StartDate: monthStartDate.toISOString().split('T')[0],
    EndDate: monthEndDate.toISOString().split('T')[0],
  };
};

const Expensecomputaions = fetch => ({
  'set consumed amount && budget amount': {
    compute: async value => {
      if (value.mode_of_payment === 'Recurring') {
        let vendorConsumed = 0;
        let vendorBudget = 0;
        let currentDate = getZeroTimeDate(new Date());
        let monthStartDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1,
        );
        let monthEndDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0,
        );
        const {data: vendorData} = await fetch({
          uri: '/budgetrequests',
          props: {
            filter: {
              // vendor: value.vendor._id,
              status: 'Approved',
              approver: value.approver._id,
              budget_category: value.budget_category,
              from_date: {$lte: currentDate},
              to_date: {$gte: currentDate},
              team: value.team?._id,
            },
            fields: {
              amount: 1,
              currency: {_id: 1, currency: 1},
              approver: {_id: 1, name: 1},
              team: {_id: 1, name: 1},
              term: 1,
              budget_category: {_id: 1, name: 1},
              from_date: 1,
              to_date: 1,
            },
            only: true,
          },
        });
        if (vendorData?._id) {
          vendorBudget = vendorData.amount;
          const {StartDate, EndDate} = calculatePeriod({
            fromDate: vendorData.from_date,
            toDate: vendorData.to_date,
            currentDate: new Date(),
            term: vendorData.term,
          });
          if (StartDate && EndDate) {
            monthStartDate = getZeroTimeDate(StartDate);
            monthEndDate = getZeroTimeDate(EndDate);
          }
        }
        const {data: vendorConsumedData} = await fetch({
          uri: '/expenserequests',
          props: {
            filter: {
              // vendor: value.vendor._id,
              approver: value?.approver._id,
              budget_category: value?.budget_category,
              status: 'Approved',
              bill_date: {$gte: monthStartDate, $lte: monthEndDate},
            },
          },
        });
        if (vendorConsumedData.length > 0) {
          vendorConsumed = vendorConsumedData.reduce((acc, curr) => {
            return acc + curr.amount;
          }, 0);
        }
        return {
          vendor_consumed_amount: vendorConsumed || 0,
          vendor_approved_amount: vendorBudget,
          currency: vendorData?.currency,
          approver: vendorData?.approver
            ? vendorData?.approver
            : value?.approver,
          team: vendorData?.team ? vendorData?.team : value?.team,
        };
      }
    },
    multi: true,
    dependencies: ['budget_category', 'invoice_date'],
  },
  'set organization,product and currency': {
    compute: async value => {
      const {data: vendorData} = await fetch({
        uri: '/budgetrequests',
        props: {
          filter: {
            vendor: value.vendor._id,
            status: 'Approved',
          },
          fields: {
            organization: {_id: 1, name: 1},
            product: {_id: 1, name: 1},
          },
          only: true,
        },
      });
      return {
        organization: vendorData?.organization,
        product: vendorData?.product,
      };
    },
    multi: true,
    dependencies: ['vendor'],
  },
  'set Department and hsn Code': {
    compute: async value => {
      const {data: vendorData} = await fetch({
        uri: '/vendors',
        props: {
          filter: {
            _id: value.vendor._id,
          },
          fields: {
            department: {_id: 1, name: 1},
            vendor_hsn_code: 1,
            location_id: {_id: 1, name: 1},
          },
          only: true,
        },
      });
      return {
        department: vendorData?.department,
        hsn_number: vendorData?.vendor_hsn_code,
        location: vendorData?.location_id,
      };
    },
    multi: true,
    dependencies: ['vendor'],
  },
});

const ConsumedVsBudgetRender = (_, props) => {
  if (
    props?.values?.vendor_consumed_amount >= 0 &&
    props?.values?.vendor_approved_amount
  ) {
    return (
      <View style={{margin: 5}}>
        <Text style={{fontSize: 12, color: '#737373'}}>Consumed vs Budget</Text>
        <Text
          style={{
            fontSize: 13,
            padding: 5,
            color: '#000000',
          }}>{`${props?.values.vendor_consumed_amount}/${props?.values.vendor_approved_amount}`}</Text>
      </View>
    );
  } else {
    return (
      <Text style={{fontSize: 12, color: '#737373', margin: 5}}>
        Consumed vs Budget
      </Text>
    );
  }
};

export const ExpenseRequest = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false} = params;
  // let {onSubmit} = useFormSubmit({
  //   uri: '/expenserequests',
  //   eventSourceId: 'expenserequest',
  // });
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'expenserequest',
  });

  const {
    user: {employee},
    fetch,
  } = useAppStateContext();
  return (
    <Form
      api={`/expenserequest/all/${row?._id}`}
      onSubmit={data => {
        if (
          data.amount + data.vendor_consumed_amount >
          data.vendor_approved_amount
        ) {
          data = {...data, reffer_to_coo: true};
        }
        invoke({
          uri: '/expenserequests',
          props: {...data},
        });
      }}
      defaultValues={{
        status: 'Draft',
        created_on: new Date(),
        expense_across: 'Single month',
        mode_of_payment: 'One Time',
        requester: employee,
        reffer_to_coo: false,
      }}
      readOnly={readOnly}
      computations={{...Expensecomputaions(fetch)}}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Payment type',
          field: 'mode_of_payment',
          type: 'radioGroup',
          valueField: 'label',
          options: [
            {label: 'One Time', value: 'One Time'},
            {label: 'Recurring', value: 'Recurring'},
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [employee?._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
            {
              label: 'Budget Category',
              type: 'autoComplete',
              field: 'budget_category',
              api: '/budgetcategories',
              suggestionField: 'name',
              valueField: 'name',
              defaultExpanded: true,
              visible: ({values}) => {
                if (values.mode_of_payment === 'Recurring') {
                  return true;
                }
              },
              size: 6,
            },
          ],
        },
        {
          label: 'Expense details',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'Vendor',
                  type: 'autoComplete',
                  field: 'vendor',
                  api: `/vendors`,
                  size: 6,
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Consumed vs Budget(per month)',
                  size: 3,
                  type: 'text',
                  defaultExpanded: true,
                  render: ConsumedVsBudgetRender,
                  visible: ({values}) => {
                    if (values.mode_of_payment === 'Recurring') {
                      return true;
                    }
                  },
                  readOnly: true,
                },
                {
                  label: 'Currency',
                  field: 'currency.currency',
                  type: 'text',
                  size: 3,
                  readOnly: true,
                  defaultExpanded: true,
                  visible: ({values}) => {
                    if (values.mode_of_payment === 'Recurring') {
                      return true;
                    }
                  },
                },
                {
                  label: 'service/product description',
                  type: 'text',
                  field: 'description',
                  size: 6,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Amount',
                  type: 'number',
                  field: 'amount',
                  size: 3,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Currency',
                  type: 'autoComplete',
                  field: 'currency',
                  api: `/currencies`,
                  suggestionField: 'currency',
                  valueField: 'currency',
                  size: 3,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Invoice number',
                  field: 'bill_number',
                  type: 'text',
                  size: 3,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Invoice date',
                  field: 'bill_date',
                  type: 'date',
                  size: 3,
                  required: true,
                  defaultExpanded: true,
                },
                {
                  label: 'Payment Mode',
                  field: 'payment_type',
                  type: 'autoComplete',
                  suggestionField: 'value',
                  valueField: 'value',
                  options: ['Neft', 'Credit Card'],
                },
                {
                  label: 'Expense across',
                  field: 'expense_across',
                  type: 'radioGroup',
                  required: true,
                  valueField: 'label',
                  options: [
                    {label: 'Single Month', value: 'Single Month'},
                    {label: 'Multiple Month', value: 'Period'},
                  ],
                  size: 12,
                  defaultExpanded: true,
                  visible: ({values}) => {
                    if (values.mode_of_payment === 'One Time') {
                      return true;
                    }
                  },
                },
                {
                  label: 'Start date',
                  field: 'provision_from_date',
                  type: 'date',
                  size: 6,
                  visible: ({values}) => {
                    if (values.expense_across === 'Period') return true;
                  },
                  defaultExpanded: true,
                },
                {
                  label: 'End date',
                  field: 'provision_to_date',
                  type: 'date',
                  size: 6,
                  defaultExpanded: true,
                  visible: ({values}) => {
                    if (values.expense_across === 'Period') return true;
                  },
                },
                {
                  label: 'Po number',
                  field: 'po_id',
                  type: 'autoComplete',
                  api: '/purchaseorders',
                  suggestionField: 'po_no',
                  valueField: 'po_no',
                  size: 6,
                  // required: true,
                  filter: ({values}) => {
                    return {
                      payee_id: values.vendor,
                      is_approved: true,
                      is_expense_created: {$in: [null, false]},
                    };
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'HSN details',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'HSN Code',
                  field: 'hsn_number',
                  type: 'text',
                  required: true,
                  size: 6,
                  defaultExpanded: true,
                },
                {
                  label: 'HSN description',
                  field: 'hsn_description',
                  type: 'text',
                  required: true,
                  size: 6,
                },
              ],
            },
          ],
        },
        {
          label: 'Supporting documents',
          fields: [
            {
              field: 'document',
              placeholder: 'Invoice',
              // label: 'Invoice',
              download: true,
              required: true,
              type: 'file',
              options: {bucketName: 'manaze'},
              multiple: true,
              size: 6,
            },
            {
              field: 'approved_mail',
              placeholder: 'Approved mail',
              options: {bucketName: 'manaze'},
              download: true,
              // required: true,
              // label: 'Approved mail',
              type: 'file',
              size: 6,
            },
          ],
        },

        {
          label: 'Accounting',
          fields: [
            {
              label: 'Organization',
              type: 'autoComplete',
              field: 'organization',
              api: `/organizations`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Product',
              type: 'autoComplete',
              field: 'product',
              api: '/products',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            {
              label: 'Department',
              type: 'autoComplete',
              field: 'department',
              api: '/departments',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              // required: true,
            },
            {
              label: 'Tax Location',
              type: 'autoComplete',
              field: 'location',
              api: '/offices',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddExpenseRequest = props => {
  return <ExpenseRequest header="Expense Request" {...props} />;
};

export const EditExpenseRequest = props => {
  return (
    <ExpenseRequest mode="edit" header={'Update Expense Request'} {...props} />
  );
};

export const RejectExpenseReason = props => {
  const {route: {params} = {}} = props;

  const {row, reffer_to_coo} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/expenserequests`,
    eventSourceId: 'expenserequest',
  });
  let {
    user: {employee},
  } = useAppStateContext();
  return (
    <Form
      api={`/expenserequests/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_on: new Date(),
            rejected_by: reffer_to_coo
              ? '53a437e96dc89c02007cbcc7'
              : employee._id,
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Reject reason',
          field: 'reject_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
