import React from 'react';
import {Col} from '@unthinkable/react-core-components';
import {InlineForm} from '../../../components/form/Form';
import {TableHeader} from '../../../components/table/Headers';
import {RecursiveTable, Table} from '../../../components/table/Table';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useInvoke} from '../../../controllers/useInvoke';

export const DepartmentTagTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department} = params;

  const {onSubmit} = useFormSubmit({
    uri: `/departmentTags`,
    eventSourceId: ['DepartmentTag'],
  });
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'DepartmentTag',
  });

  return (
    <Table
      eventSourceId="DepartmentTag"
      api={`/departmenttags`}
      renderHeader={() => (
        <Col>
          <TableHeader
            title="Department Tags"
            secondaryTitle={department?.name}
          />
          <InlineForm
            layoutFields={[
              {
                field: 'name',
                type: 'text',
                label: 'Name',
              },
              {
                field: 'path',
                type: 'text',
                label: 'Path',
              },
              {
                field: 'parent',
                type: 'autoComplete',
                label: 'Parent',
                api: `/departmentTags`,
                suggestionField: 'name',
                valueField: 'name',
              },
            ]}
            defaultValues={{
              department: department,
            }}
            onSubmit={onSubmit}
          />
        </Col>
      )}
      fields={{
        name: 1,
        path: 1,
        parent: {name: 1},
      }}
      filter={{
        department: department,
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'path',
          header: 'Path',
        },
        {
          type: 'text',
          field: 'parent.name',
          header: 'Parent',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-department-tag', {
              departmentTag: row,
            });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete Department Tag',
            message: `Are you sure you want to delete department tag ?`,
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/departmentTags/${row?._id}`,
            });
          },
        },
      ]}
    />
  );
};

export const DepartmentTagsWithNavigationTable = props => {
  const {
    route: {params},
    navigation,
  } = props;
  const {department, title} = params;

  return (
    <RecursiveTable
      eventSourceId="DepartmentTag"
      defaultExpanded={true}
      api={`/depatmentTags/heirarchy`}
      renderHeader={() => <TableHeader title="Tags" />}
      addOnFilter={{
        department: department,
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(row.path, {
          tag: row,
          navTitle: row.name,
          title: title,
          department: department,
        });
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'number',
          field: 'count',
          header: 'Count',
        },
      ]}
    />
  );
};
