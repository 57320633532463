import {View} from '@unthinkable/react-core-components';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import React from 'react';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {
  UserAvatarChip,
  UserAvatarGroup,
  UserAvatarGroupChip,
} from '../../../components/avatar/UserAvatar';
import {AddButton} from '../../../components/button/AddButton';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';
import {TextRenderer} from '../../../components/form/Renderers';
import {TabView} from '../../../components/tab';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';

const RenderDuration = ({row}) => {
  return (
    <GroupContentItem
      value={`${moment(row?.from_date).format('DD MMM YY')} - ${moment(
        row?.to_date,
      ).format('DD MMM YY')}`}
    />
  );
};

const MentorRender = props => {
  const {row} = props;
  if (
    row?.mentorship_type == 'Single Trainer' ||
    row?.mentorship_type == 'Session-wise Trainers'
  ) {
    return <UserAvatarGroup {...props} value={[row.mentor]} />;
  } else if (row.mentorship_type == 'Resource-wise Trainers') {
    return <UserAvatarGroup {...props} value={row.mentors} maxAvatars={1} />;
  } else if (row.mentorship_type == 'Topic-wise Trainers') {
    return (
      <UserAvatarGroup
        {...props}
        maxAvatars={1}
        value={row.topic_wise_mentors}
      />
    );
  }
};

const RenderBatch = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column', flex: 1}}>
      <TextRenderer value={row?.name} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.training_program?.name} />
        <GroupContentItem value={`Batch ${row?.batch_number}`} />
      </RowWithSeparator>
    </View>
  );
};

const MenteeRender = props => {
  const {row} = props;
  if (row?.mentorship_type == 'Single Trainer') {
    const newProps = {
      ...props,
      count_field: 'trainee_count',
    };
    return <CountCellRender {...newProps} />;
  } else {
    return <CountCellRender {...props} />;
  }
};

const RenderAttendance = props => {
  const {row} = props;
  const {pending_attendance_count, last_marked_attendance} = row;
  if (pending_attendance_count)
    return (
      <View>
        <TextRenderer
          {...props}
          value={'Mark'}
          style={{
            color: '#007AFF',
          }}
        />
        <GroupContentItem value={`${pending_attendance_count} pending`} />
      </View>
    );
  return (
    <View>
      <TextRenderer
        {...props}
        value={'Marked'}
        style={{
          color: '#34C759',
        }}
      />
      {last_marked_attendance?.date && (
        <GroupContentItem
          value={`${moment(last_marked_attendance.date).format('DD MMM YY')}`}
        />
      )}
    </View>
  );
};

const RenderFeedback = ({row}) => {
  return <RatingRenderer value={row?.avg_feedback} />;
};

const RenderTrainingFeedback = ({row}) => {
  return (
    <View>
      <TextRenderer value={'View'} style={{color: '#007AFF'}} />
      <GroupContentItem
        value={`${row?.active_training_feedbacks || 0} responses`}
      />
    </View>
  );
};

const RenderType = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer value={row?.training_program?.course_type} />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.batch_type} />
      </RowWithSeparator>
    </View>
  );
};

export const TrainingBatchTable = props => {
  const {
    route: {params},
    navigation,
    fromTrainerCourseBatches,
    status,
  } = props;

  const {product} = params;

  const invoke = useInvoke({
    eventSourceId: 'TrainingBatches',
    method: 'put',
  });
  const dropBatch = ({row}) => {
    invoke({
      uri: `/trainingBatches/${row?._id}`,
      props: {
        status: 'dropped',
        dropped_on: getZeroTimeDate(new Date()),
      },
    });
  };

  const completeBatch = ({row}) => {
    invoke({
      uri: `/trainingBatches/${row?._id}`,
      props: {
        status: 'completed',
        completed_on: getZeroTimeDate(new Date()),
      },
    });
  };

  return (
    <Table
      api="/getTrainingBatches"
      filter={{
        product,
      }}
      eventSourceId={['TrainingBatches', 'EmployeeTrainingsFromBatch']}
      fields={{
        name: 1,
        skill_metrics: {
          name: 1,
        },
        training_program: {
          name: 1,
          course_type: 1,
        },
        mentorship_type: 1,
        mentor: {
          name: 1,
        },
        from_date: 1,
        to_date: 1,
        feedback_cycle: 1,
        trainee_count: {
          _id: 1,
        },
        batch_number: 1,
        mentorship_type: 1,
        mentors: {
          name: 1,
          color: 1,
        },
        topic_wise_mentors: {
          mentor: {
            name: 1,
            color: 1,
          },
        },
        status: 1,
        batch_type: 1,
        reason: 1,
        purpose: {
          name: 1,
        },
        my_trainee_count: {
          _id: 1,
        },
        active_training_feedbacks: {
          _id: 1,
        },
        tools: {
          name: 1,
        },
      }}
      sort={{
        from_date: -1,
      }}
      columns={[
        {
          header: 'Batch',
          render: RenderBatch,
          minWidth: 250,
        },
        {
          field: 'tools',
          header: 'Skill',
          render: MultiColorTag,
          labelField: 'name',
          titleField: 'name',
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        {
          header: 'Purpose',
          field: 'purpose.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Duration',
          render: RenderDuration,
        },
        {
          render: MentorRender,
          maxAvatars: 3,
          header: 'Trainers',
          width: 140,
          onPress: ({row}) => {
            const mentorFilter = {};
            if (
              row?.mentorship_type == 'Single Trainer' ||
              row?.mentorship_type == 'Session-wise Trainers'
            ) {
              mentorFilter['mentor'] = [row?.mentor];
            } else if (row.mentorship_type == 'Resource-wise Trainers') {
              mentorFilter['mentor'] = row?.mentors;
            } else if (row.mentorship_type == 'Topic-wise Trainers') {
              mentorFilter['mentor'] = row?.topic_wise_mentors;
            }
            navigation.navigate('mentor-training-with-batch', {
              ...mentorFilter,
              name: row?.name,
            });
          },
          visible: !fromTrainerCourseBatches,
        },
        {
          align: 'right',
          header: 'Trainees',
          isNestedObject: true,
          render: MenteeRender,
          count_field: 'trainee_count',
          width: 80,
          onPress: ({row}) => {
            navigation.navigate('employee-training-with-batch', {
              training_batch: row,
            });
          },
          onPlusPress: ({row}) => {
            navigation.navigate('employee-training-with-batch', {
              training_batch: row,
            });
          },
          visible: !fromTrainerCourseBatches,
        },
        {
          align: 'right',
          header: 'Trainees',
          isNestedObject: true,
          render: MenteeRender,
          count_field: 'my_trainee_count',
          width: 120,
          onPress: ({row}) => {
            navigation.navigate('my-mentees-tabs', {
              training_batch: row,
            });
          },
          visible: !!fromTrainerCourseBatches,
        },
        {
          header: 'Attendance',
          render: RenderAttendance,
          visible: !!fromTrainerCourseBatches,
          onPress: ({row}) => {
            navigation.navigate('view-training-batch-attendance', {
              training_batch: row,
            });
          },
        },
        {
          header: 'Feedback',
          render: RenderTrainingFeedback,
          visible: !fromTrainerCourseBatches,
          onPress: ({row}) => {
            navigation.navigate('trainee-to-training-feedback-table', {
              training_batch: row,
            });
          },
        },
        {
          header: 'Feedback',
          render: RenderFeedback,
          visible: !!fromTrainerCourseBatches,
          onPress: ({row}) => {
            navigation.navigate('trainee-feedback-for-trainer', {
              training_batch: row,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-training-batch', {
              ...params,
              training_batch: row,
            });
          },
        },
        {
          title: 'Complete',
          onPress: completeBatch,
          visible: ({row}) => row.status == 'active',
        },
        {
          title: 'Drop',
          confirm: {
            title: 'Drop',
            message: 'Are you sure you want to Drop?',
          },
          variant: 'error',
          onPress: dropBatch,
        },
        {
          title: 'View Attendance',
          onPress: ({row}) => {
            navigation.navigate('view-training-batch-attendance', {
              training_batch: row,
            });
          },
        },
        {
          title: 'Mark Attendance',
          onPress: ({row}) => {
            navigation.navigate('mark-training-batch-attendance', {
              training_batch: row,
              addOnFilter: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const InactiveTrainerCourses = props => {
  const {
    route: {params},
  } = props;
  const {mentor} = params;
  return (
    <TrainingBatchTable
      renderHeader={() => (
        <TableHeader title="Course Batches" secondaryTitle={mentor.name} />
      )}
      addOnFilter={{
        $or: [
          {mentor: mentor?._id},
          {mentors: mentor?._id},
          {'topic_wise_mentors.mentor': mentor?._id},
        ],
      }}
      {...props}
    />
  );
};

export const TrainingBatchTabs = props => {
  const {
    route: {params},
    fromSkillLeader,
    extraFilter = {},
    fromTrainerCourseBatches,
  } = props;

  const {
    user: {employee},
  } = useAppStateContext();
  if (fromSkillLeader) {
    extraFilter['skill_leader'] = employee?._id;
  }

  const tabs = {
    ongoing: {
      label: 'Active',
      view: (
        <TrainingBatchTable
          key={'ongoing'}
          fromTrainerCourseBatches={fromTrainerCourseBatches}
          addOnFilter={{
            status: 'active',
            ...extraFilter,
          }}
        />
      ),
      actions: [
        ...(!fromTrainerCourseBatches
          ? [
              <AddButton
                title="Course Batch"
                view="add-training-batch"
                params={params}
              />,
            ]
          : []),
      ],
      api: '/trainingBatches',
      eventSourceId: 'TrainingBatches',
      filter: {
        status: 'active',
        product: 'params?.product',
        ...extraFilter,
      },
    },
    completed: {
      label: 'Completed',
      view: (
        <TrainingBatchTable
          key={'completed'}
          fromTrainerCourseBatches={fromTrainerCourseBatches}
          addOnFilter={{
            status: 'completed',
            ...extraFilter,
          }}
          status="completed"
        />
      ),
      actions: [
        ...(!fromTrainerCourseBatches
          ? [
              <AddButton
                title="Course Batch"
                view="add-training-batch"
                params={params}
              />,
            ]
          : []),
      ],
      api: '/trainingBatches',
      eventSourceId: 'TrainingBatches',
      filter: {
        status: 'completed',
        product: 'params?.product',
        ...extraFilter,
      },
    },
    dropped: {
      label: 'Dropped',
      view: (
        <TrainingBatchTable
          key={'dropped'}
          fromTrainerCourseBatches={fromTrainerCourseBatches}
          addOnFilter={{
            status: 'dropped',
            ...extraFilter,
          }}
          status="dropped"
        />
      ),
      api: '/trainingBatches',
      eventSourceId: 'TrainingBatches',
      filter: {
        status: 'dropped',
        product: 'params?.product',
        ...extraFilter,
      },
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Course Batches" />}
      tabs={tabs}
      {...props}
    />
  );
};

export const MyMenteesBatchTabs = props => {
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    ongoing: {
      label: 'Ongoing',
      view: (
        <TrainingBatchTable
          api={`/employee/${employee?._id}/myMentees`}
          addOnFilter={{
            status: 'active',
            $or: [{mentor: employee._id}, {mentors: employee._id}],
          }}
        />
      ),
      api: '/trainingBatches',
      filter: {
        status: 'active',
        $or: [{mentor: employee._id}, {mentors: employee._id}],
      },
    },
    completed: {
      label: 'Completed',
      view: (
        <TrainingBatchTable
          api={`/employee/${employee?._id}/myMentees`}
          addOnFilter={{
            status: 'completed',
            $or: [{mentor: employee._id}, {mentors: employee._id}],
          }}
        />
      ),
      api: '/trainingBatches',
      filter: {
        status: 'completed',
        $or: [{mentor: employee._id}, {mentors: employee._id}],
      },
    },
  };
  return (
    <TabView
      headerComponent={<TableHeader title="Training Batch" />}
      tabs={tabs}
      {...props}
    />
  );
};
