import React from 'react';
import {Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {AddButton} from '../../../components/button/AddButton';
import {NavHeader} from '../../../components/nav/NavHeader';
import {
  AggregateComparisonRender,
  ComparisonRender,
} from '../../../components/table/Renders';
import {Row, Text} from '@unthinkable/react-core-components';
import {LineSeparator} from '@unthinkable/react-layout';
import {UserAvatarGroup} from '../../../components/avatar/UserAvatar';
import {MoreActions} from '../../../components/moreAction/MoreAction';
import {useInvoke} from '../../../controllers/useInvoke';
import {STATUS} from './TargetAudienceTable';
import ColorDiffCellRender from '../../../app-components/renders/ColorDiffCellRender';
import {INMAIL_CAMPAIGN_TYPE_ID} from '../../common/constants/SourceConstants';

export const InmailCampaignHeader = props => {
  return (
    <NavHeader
      title="Inmail Campaign"
      actions={[
        <AddButton
          title="Inmail Campaign"
          view="add-inmailcampaign"
          params={props?.route?.params}
        />,
        <MoreActions
          actions={[
            {
              title: 'Import Inmail Prospects',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin prospects',
                  model: 'PotentialCustomer',
                });
              },
            },
            {
              title: 'Import Inmail Responses',
              onPress: () => {
                navigation.navigate('import-list', {
                  ...props?.route?.params,
                  source: 'linkedin responses',
                  model: 'PotentialCustomer',
                });
              },
            },
          ]}
        />,
      ]}
      {...props}
    />
  );
};

const RendersAcceptedPercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.accepted_percentage
          ? row?.accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.accepted_percentage || row?.overall_accepted_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_accepted_percentage
          ? row?.overall_accepted_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const RendersPositivePercentage = props => {
  const {row, styles = {}} = props;
  const {rowText = {}} = styles;
  return (
    <Row style={{justifyContent: 'flex-end'}} gap={4}>
      <Text style={{...rowText}}>
        {row?.positive_result_percentage
          ? row?.positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
      {row?.positive_result_percentage ||
      row?.overall_positive_result_percentage ? (
        <LineSeparator />
      ) : (
        void 0
      )}
      <Text style={{...rowText, color: '#A3A3A3'}}>
        {row?.overall_positive_result_percentage
          ? row?.overall_positive_result_percentage?.toFixed(2)
          : void 0}
      </Text>
    </Row>
  );
};

const LinkedInCampaignOwner = ({row}) => {
  const owners = [];
  if (row.profile_owner) {
    owners.push(row.profile_owner);
  }
  if (row.assigned_to) {
    owners.push(row.assigned_to);
  }
  return <UserAvatarGroup value={owners} />;
};

export const InmailCampaignTable = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    searchValue,
    filter,
    tabFilter = {},
    api,
    groupBy,
  } = props;
  params = {...params, ...filterParams};
  api = api || `/inmail-campaign`;

  const prospectsCountForCampaignWithoutPeriod = props => {
    const {row} = props;
    navigation.navigate(`show-prospect-count-list`, {
      ...params,
      row,
      filter: {
        // added_on: periodFilter,
        campaign_id: row?._id,
      },
    });
  };

  const showDetails = props => {
    navigation.navigate('view-campaign-prospect-details', {
      campaign_id: {
        _id: props?.row._id,
        name: props?.row.name,
        communication_type: props?.row?.communication_type,
      },
      defaultValues: {
        campaign_id: {_id: props?.row._id, name: props?.row.name},
      },
      filter: {
        campaign_id: props?.row._id,
      },
    });
  };

  const invoke = useInvoke({
    close: false,
    method: 'put',
    eventSourceId: ['inmailCampaignAdded'],
  });

  const {
    acceptedProspectsOld,
    requestSentProspects,
    inmailMessages,
    uqls,
    preuqls,
    qualifiedLeads,
    prequalifiedLeads,
    CampaignProspectOnClickMessageSend,
    CampaignProspectOnClickMessageSendFromOld,
    editInmailCampaign,
    prospectsCountForCampaign,
    prospectCountForAutoCampaigns,
    positiveProspectsCountForCampaign,
    loadRandomDataFromList,
    // prospectsCountForCampaignWithoutPeriod
  } = leadControllers({
    ...props,
    params: {...params, fromInmailCampaign: true},
  });
  return (
    <Table
      eventSourceId={[
        'outreachAdded',
        'inmailCampaignAdded',
        'Task',
        'importData',
      ]}
      api={api}
      params={params}
      addOnFilter={{...filter, ...tabFilter}}
      // limit={5000}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      search={searchValue}
      searchFields={'name'}
      onRowPress={showDetails}
      columns={[
        {
          // field: 'name',
          header: 'Name',
          // type: 'text',
          // width: 250,
          // responsive: 'sm',
          render: ColorDiffCellRender,
        },
        {
          width: 80,
          header: 'Owner',
          field: 'assigned_to',
          type: 'userAvatar',
        },
        // {
        //   width: 80,
        //   header: 'Profile',
        //   field: 'assigned_to',
        //   type: 'userAvatar',
        // },
        // {
        //   render: CountCellRender,
        //   width: 120,
        //   header: 'Outreach Contents',
        //   align: 'right',
        //   count_field: 'campaign_message_count',
        //   responsive: 'sm',
        //   onPlusPress: props => {
        //     const {row} = props;
        //     navigation.navigate(`add-outreach-content`, {
        //       ...params,
        //       row,
        //       campaign_type: '64d0fd55ab78dfead48c144b',
        //     });
        //   },
        //   onPress: inmailMessages,
        // },
        {
          width: 100,
          type: 'number',
          field: 'prospect_count',
          header: 'Prospect',
          aggregate: true,
          onPress: ({row}) => {
            const {system_controlled} = row || {};
            system_controlled
              ? prospectCountForAutoCampaigns({row})
              : prospectsCountForCampaign({row});
          },
        },
        {
          type: 'number',
          field: 'request_send_count',
          header: 'Requests',
          aggregate: true,
          onPress: requestSentProspects,
          width: 100,
        },
        {
          type: 'number',
          header: 'Accepted %',
          field: 'accepted_percentage',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          // render: RendersAcceptedPercentage,
          onPress: acceptedProspectsOld,
          width: 100,
        },
        {
          type: 'number',
          field: 'message_send_count_all',
          header: 'Message Sent',
          aggregate: true,
          onPress: CampaignProspectOnClickMessageSendFromOld,
          width: 120,
        },
        {
          type: 'number',
          header: 'Positive %',
          field: 'positive_result_percentage',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          // render: RendersPositivePercentage,
          onPress: positiveProspectsCountForCampaign,
          width: 110,
        },
        {
          type: 'number',
          field: 'lead_count',
          compareField: 'previous_lead_count',
          header: 'Lead',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: uqls,
          onComparePress: preuqls,
          render: ComparisonRender,
          width: 110,
        },
        {
          type: 'number',
          field: 'ql_count',
          compareField: 'previous_ql_count',
          header: 'QL',
          aggregate: {
            render: AggregateComparisonRender,
          },
          onPress: qualifiedLeads,
          onComparePress: prequalifiedLeads,
          render: ComparisonRender,
          width: 110,
        },
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: editInmailCampaign,
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Add Messages',
          onPress: ({row}) => {
            navigation.navigate(`campaign-messages`, {
              campaign_id: {
                _id: row._id,
                name: row.name,
                communication_type: row?.communication_type,
                system_controlled: row?.system_controlled,
              },
              defaultValues: {
                campaign_id: {_id: row._id, name: row.name},
              },
              filter: {
                campaign_id: row._id,
              },
            });
          },
        },
        {
          title: 'Load Data',
          onPress: ({row}) =>
            loadRandomDataFromList({
              row: {...row, campaign_type: INMAIL_CAMPAIGN_TYPE_ID},
            }),
          visible: ({row}) => row?.system_controlled,
        },
        {
          title: 'View Analytics',
          onPress: props => {
            navigation.navigate('show-outreach-analytics', {
              row: {
                ...props?.row,
                campaignAnalytics: {campaign_id: props?.row._id},
              },
              fromRow: true,
            });
          },
          visible: ({row}) => row?.system_controlled,
        },
        {
          title: 'AI Prompt',
          onPress: ({row}) => {
            navigation.navigate('ai-prompts-table', {
              addOnFilter: {for_crm: true, _id: row?.personalization_prompt_id},
            });
          },
          visible: ({row}) =>
            row?.system_controlled && row?.campaign_method === 'AI',
        },
        {
          title: 'Finetune Usecase',
          onPress: ({row}) => {
            navigation.navigate('fine-tune-use-case-list', {
              addOnFilter: {_id: row?.usecase_personalization_id},
            });
          },
          visible: ({row}) =>
            row?.system_controlled && row?.campaign_method === 'AI',
        },
        {
          title: 'Set Actual ScheduleOn',
          onPress: ({row}) => {
            navigation.navigate('set-actual-schedule-on', {
              campaign_id: row?._id,
            });
          },
          visible: ({row}) => row?.system_controlled,
        },
        {
          title: 'Confirm',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Confirmed Date',
              header: 'Confirmed Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'confirmed_on',
              status: STATUS.CONFIRMED,
            });
          },
          visible: ({row}) => row?.status == STATUS.IN_EXPERIMENT,
        },
        {
          title: 'Retire',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Retired Date',
              header: 'Retired Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'retired_on',
              status: STATUS.RETIRED,
            });
          },
          visible: ({row}) =>
            row?.status != STATUS.RETIRED && row?.status != STATUS.FAILED,
        },
        {
          title: 'Fail',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'Failed Date',
              header: 'Failed Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'failed_on',
              status: STATUS.FAILED,
            });
          },
          visible: ({row}) => row?.status != STATUS.FAILED,
        },
        {
          title: 'Push back to experiment',
          onPress: ({row}) => {
            navigation.navigate('dialogue-box-for-date', {
              label: 'In Experiment Date',
              header: 'In Experiment Date',
              uri: `/campaigns/${row?._id}`,
              eventSourceId: ['outreachAdded', 'inmailCampaignAdded', 'Task'],
              field: 'experiment_on',
              status: STATUS.IN_EXPERIMENT,
            });
          },
          visible: ({row}) => row?.status == STATUS.RETIRED,
        },
        {
          title: 'Update Channel',
          onPress: ({row}) => {
            console.log('row:::::', row);
            row = {
              ...row,
              api: `/campaigns`,
            };
            navigation.navigate('update-channel', {
              ...row,
            });
          },
        },
        {
          title: 'Prospect',
          onPress: prospectsCountForCampaignWithoutPeriod,
        },
      ]}
    />
  );
};
