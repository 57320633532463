import React from 'react';
import {Table} from '../../../components/table/Table';
import {useLeftMenuContext} from '../../../components/menu/LeftMenuContext';
import {TabView} from '../../../components/tab';
import {useFilter} from '@unthinkable/react-filters';
import {SearchFilter} from '../../../components/filter/Filters';

export const DepartmentWiseTable = props => {
  const {
    navigation,
    searchValue,
    route: {params},
  } = props;

  const {navigateTo} = useLeftMenuContext() || {};
  return (
    <Table
      search={searchValue}
      searchFields={['name']}
      api="/depatmentactiveinactive"
      eventSourceId={['DepartmentRole']}
      onRowPress={({row}) => {
        let department = row._id;
        if (department === '5848ffe17d89cd3f17c7335c') {
          department = {
            $in: [
              '6128b78a6f91a06849407634',
              '5848ffe17d89cd3f17c7335c',
              '589e9ea16a39a6208813d617',
              '5849228434d56b42ec1aabd2',
              '599c0536ac02221f8db97504',
            ],
          };
        }

        // if (navigateTo) {
        //   navigateTo('employee-department', {
        //     ...params,
        //     department,
        //     departments_id: row?._id,
        //     departments: row,
        //     title: row.name,
        //     breadcrumb: {
        //       dropdown: {
        //         field: 'departments',
        //       },
        //     },
        //   });
        // } else {
        navigation.navigate('department-wise-employee-menus', {
          ...params,
          department,
          title: row.name,
        });
        // }
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Employees Count',
          field: 'department_employee',
          type: 'number',
          width: 150,
        },
      ]}
      {...props}
    />
  );
};

export const DepartmentTabs = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {onChangeFilter, searchValue} = useFilter({});
  const tabs = {
    Active: {
      label: 'Active',
      view: (
        <DepartmentWiseTable
          tab="Active"
          addOnFilter={{is_active: true}}
          searchValue={searchValue}
          {...props}
        />
      ),
    },
    Inactive: {
      label: 'Inactive',
      view: (
        <DepartmentWiseTable
          tab="Inactive"
          searchValue={searchValue}
          addOnFilter={{is_active: {$in: [null, false]}}}
          {...props}
        />
      ),
    },
  };
  return (
    <TabView
      tabs={tabs}
      actions={[
        <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
      ]}
      params={params}
      {...props}
    />
  );
};
