import React from 'react';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {getCurrentMonth} from '@unthinkable/react-filters/src/DateUtility';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {HRAppraisalTable} from './EmployeeAppraisalTable';

const getUtcMonthFirstDate = date => {
  if (!date) {
    return null;
  }
  let dateT = new Date(date);
  dateT.setUTCDate(1);
  dateT.setUTCHours(0);
  dateT.setUTCMinutes(0);
  dateT.setUTCSeconds(0);
  dateT.setUTCMilliseconds(0);
  return dateT;
};

const appraisalCommonFilters = [
  {
    type: 'multiAutoComplete',
    label: 'Employee',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    secondarySuggestionField: 'official_email_id',
    valueField: 'name',
    field: 'employee',
    searchFields: ['name', 'official_email_id', 'employee_code'],
  },
  {
    type: 'multiAutoComplete',
    label: 'Practice Head',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    secondarySuggestionField: 'official_email_id',
    valueField: 'name',
    field: 'practice_head',
    searchFields: ['name', 'official_email_id', 'employee_code'],
  },
  {
    type: 'multiAutoComplete',
    label: 'People Manager',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    secondarySuggestionField: 'official_email_id',
    valueField: 'name',
    field: 'employee.functional_manager',
  },
  {
    type: 'date',
    label: 'Effective Date',
    field: 'effective_date',
  },
  {
    type: 'date',
    label: 'Closed Date',
    field: 'closed_date',
  },
  {
    type: 'date',
    label: 'Joining Date',
    field: 'employee.joining_date',
  },
  {
    type: 'autoComplete',
    label: 'Department',
    api: '/departments',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'employee.department',
  },
  {
    type: 'autoComplete',
    label: 'Designation',
    api: '/designations',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'employee.designation',
  },
  {
    type: 'autoComplete',
    label: 'Approver',
    api: '/employeeSuggestions',
    placeholder: 'Select',
    suggestionField: 'name',
    valueField: 'name',
    field: 'approved_by_unit_ph',
    searchFields: ['name', 'official_email_id', 'employee_code'],
    secondarySuggestionField: 'official_email_id',
  },
];

export const HRPendingAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}, ...rest} = props || {};
  const {department} = params;
  let departmentFilter = {};
  if (department) {
    departmentFilter = {
      "employee.department": department,
      "employee.employee_status": 'Active',
    }
  }
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
    filter: {
      ...departmentFilter
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const currentDate = getZeroTimeDate(new Date());
  const monthFirstDate = getUtcMonthFirstDate(currentDate);
  params = {...params, user};
  let tabs = {};
  tabs['People Manager'] = {
    label: 'Manager Rating Due',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
          people_manager_appraisal_status: {$in: [null, false]},
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
      people_manager_appraisal_status: {$in: [null, false]},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['Unit Head Due'] = {
    label: 'Unit Head',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
          unit_appraisal_status: {$in: [null, false]},
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
      unit_appraisal_status: {$in: [null, false]},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['Employee Acceptance'] = {
    label: 'Acceptance Due',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
          people_manager_appraisal_status: true,
          unit_appraisal_status: true,
          offer_accepted_by_employee: {$in: [null, false]},
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
      people_manager_appraisal_status: true,
      unit_appraisal_status: true,
      offer_accepted_by_employee: {$in: [null, false]},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['Pending'] = {
    label: 'Pending',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
          effective_date: {$lt: getZeroTimeDate(monthFirstDate)},
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
      effective_date: {$lt: getZeroTimeDate(monthFirstDate)},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['Due'] = {
    label: 'Due',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
          effective_date: {$gte: getZeroTimeDate(monthFirstDate)},
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
      effective_date: {$gte: getZeroTimeDate(monthFirstDate)},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['OverDue'] = {
    label: 'OverDue',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          effective_date: {
            $lt: getZeroTimeDate(
              new Date(new Date().setDate(new Date().getDate() + 1)),
            ),
          },
          status: 'Active',
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      effective_date: {
        $lt: getZeroTimeDate(
          new Date(new Date().setDate(new Date().getDate() + 1)),
        ),
      },
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['PendingAppraisal'] = {
    label: 'All',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          status: 'Active',
        }}
        tab="Pending"
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  return (
    <TabView
      tabs={tabs}
      // params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRInProcessAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}, ...rest} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const currentDate = getZeroTimeDate(new Date());
  const monthFirstDate = getUtcMonthFirstDate(currentDate);
  params = {...params, user};
  let tabs = {};
  tabs['Appraisal Amount Accepted'] = {
    label: 'Amount Accepted',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Appraisal Amount Accepted"
        addOnFilter={{
          ...filterValues.filter,
          $or: [
            {
              processed_manually_by_hr: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: 'Extend',
              manually_processed_onhold_extend: true,
            },
            {
              ifIncrement_asPer_aggrement: 'OnHold',
              manually_processed_onhold_extend: true,
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: 'AsPerAggrement',
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: {$in: [null, false]},
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
          ],
          status: 'Active',
          hrPayroll_appraisal_status: {$in: [null, false]},
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      $or: [
        {
          processed_manually_by_hr: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: 'Extend',
          manually_processed_onhold_extend: true,
        },
        {
          ifIncrement_asPer_aggrement: 'OnHold',
          manually_processed_onhold_extend: true,
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: 'AsPerAggrement',
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: {$in: [null, false]},
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
      ],
      status: 'Active',
      hrPayroll_appraisal_status: {$in: [null, false]},
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  tabs['Letter Shared'] = {
    label: 'Letter Shared',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Letter Shared"
        addOnFilter={{
          ...filterValues.filter,
          hrPayroll_appraisal_status: true,
          hrPayroll_closed_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      hrPayroll_appraisal_status: true,
      hrPayroll_closed_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  tabs['Extended'] = {
    label: 'Extended',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        tab="Extended"
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          offer_accepted_by_employee: {$in: [null, false]},
          hrPayroll_appraisal_status: {$in: [null, false]},
          manually_processed_onhold_extend: {$in: [null, false]},
          ifIncrement_asPer_aggrement: 'Extend',
          status: 'Active',
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      offer_accepted_by_employee: {$in: [null, false]},
      hrPayroll_appraisal_status: {$in: [null, false]},
      manually_processed_onhold_extend: {$in: [null, false]},
      ifIncrement_asPer_aggrement: 'Extend',
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  tabs['Promotion Recommendations'] = {
    label: 'Promotion Recommendations',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Promotion Recommendations"
        addOnFilter={{
          ...filterValues.filter,
          forPromotion_unit_manager: true,
          unit_appraisal_status: true,
          hrPayroll_closed_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      forPromotion_unit_manager: true,
      unit_appraisal_status: true,
      hrPayroll_closed_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };

  tabs['On Hold'] = {
    label: 'On Hold',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="On Hold"
        addOnFilter={{
          ...filterValues.filter,
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: {$ne: true},
          ifIncrement_asPer_aggrement: 'OnHold',
          hrPayroll_appraisal_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      ...filter,
      people_manager_appraisal_status: true,
      offer_accepted_by_employee: {$ne: true},
      ifIncrement_asPer_aggrement: 'OnHold',
      hrPayroll_appraisal_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  return (
    <TabView
      tabs={tabs}
      // params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRProcessedAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}, ...rest} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  const currentDate = getZeroTimeDate(new Date());
  const monthFirstDate = getUtcMonthFirstDate(currentDate);
  params = {...params, user};
  let tabs = {};
  tabs['Closed Appraisal'] = {
    label: 'Closed Appraisal',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Closed Appraisal"
        addOnFilter={{
          status: 'Closed',
          $or: [
            {
              hrPayroll_appraisal_status: true,
            },
            {
              processed_manually_by_hr: true,
            },
          ],
          hrPayroll_closed_status: true,
          ...filterValues.filter,
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      status: 'Closed',
      $or: [
        {
          hrPayroll_appraisal_status: true,
        },
        {
          processed_manually_by_hr: true,
        },
      ],
      hrPayroll_closed_status: true,
      ...filter,
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  tabs['Inactive Appraisal'] = {
    label: 'Inactive Appraisal',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Inactive Appraisal"
        addOnFilter={{
          status: 'Inactive',
          ...filterValues.filter,
        }}
        user={user}
        {...rest}
      />
    ),
    addOnFilter: {
      status: 'Inactive',
      ...filter,
    },
    api: '/employeehrappraisal',
    limit: 5000,
  };
  return (
    <TabView
      tabs={tabs}
      // params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRAppraisalAmountAcceptedTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};
  tabs['Appraisal Amount Accepted'] = {
    label: 'Appraisal Amount Accepted',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Appraisal Amount Accepted"
        addOnFilter={{
          ...filterValues.filter,
          $or: [
            {
              processed_manually_by_hr: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: 'Extend',
              manually_processed_onhold_extend: true,
            },
            {
              ifIncrement_asPer_aggrement: 'OnHold',
              manually_processed_onhold_extend: true,
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: 'AsPerAggrement',
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
            {
              ifIncrement_asPer_aggrement: {$exists: false},
              people_manager_appraisal_status: true,
              offer_accepted_by_employee: true,
            },
          ],
          status: 'Active',
          hrPayroll_appraisal_status: {$exists: false},
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      $or: [
        {
          processed_manually_by_hr: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: 'Extend',
          manually_processed_onhold_extend: true,
        },
        {
          ifIncrement_asPer_aggrement: 'OnHold',
          manually_processed_onhold_extend: true,
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: 'AsPerAggrement',
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
        {
          ifIncrement_asPer_aggrement: {$exists: false},
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: true,
        },
      ],
      status: 'Active',
      hrPayroll_appraisal_status: {$exists: false},
    },
    api: '/employeehrappraisal',
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'multiAutoComplete',
              label: 'Employee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'employee',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
          ]}
        />,
      ]}
    />
  );
};

export const HRLetterSharedAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['Letter Shared'] = {
    label: 'Letter Shared',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Letter Shared"
        addOnFilter={{
          ...filterValues.filter,
          hrPayroll_appraisal_status: true,
          hrPayroll_closed_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      hrPayroll_appraisal_status: true,
      hrPayroll_closed_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRClosedAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['Closed Appraisal'] = {
    label: 'Closed Appraisal',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Closed Appraisal"
        addOnFilter={{
          ...filterValues.filter,
          $or: [
            {
              hrPayroll_appraisal_status: true,
            },
            {
              processed_manually_by_hr: true,
            },
          ],
          hrPayroll_closed_status: true,
          status: 'Closed',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      $or: [
        {
          hrPayroll_appraisal_status: true,
        },
        {
          processed_manually_by_hr: true,
        },
      ],
      hrPayroll_closed_status: true,
      status: 'Closed',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRExtendedAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['Extended'] = {
    label: 'Extended',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        tab="Extended"
        filterValues={filterValues}
        addOnFilter={{
          ...filterValues.filter,
          offer_accepted_by_employee: {$in: [null, false]},
          hrPayroll_appraisal_status: {$in: [null, false]},
          manually_processed_onhold_extend: {$in: [null, false]},
          ifIncrement_asPer_aggrement: 'Extend',
          status: 'Active',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      offer_accepted_by_employee: {$in: [null, false]},
      hrPayroll_appraisal_status: {$in: [null, false]},
      manually_processed_onhold_extend: {$in: [null, false]},
      ifIncrement_asPer_aggrement: 'Extend',
      status: 'Active',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HROnHoldAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['On Hold'] = {
    label: 'On Hold',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="On Hold"
        addOnFilter={{
          ...filterValues.filter,
          people_manager_appraisal_status: true,
          offer_accepted_by_employee: {$ne: true},
          ifIncrement_asPer_aggrement: 'OnHold',
          hrPayroll_appraisal_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      people_manager_appraisal_status: true,
      offer_accepted_by_employee: {$ne: true},
      ifIncrement_asPer_aggrement: 'OnHold',
      hrPayroll_appraisal_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRPromotionRecommendationsAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['Promotion Recommendations'] = {
    label: 'Promotion Recommendations',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Promotion Recommendations"
        addOnFilter={{
          ...filterValues.filter,
          forPromotion_unit_manager: true,
          unit_appraisal_status: true,
          hrPayroll_closed_status: {$ne: true},
          status: 'Active',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      forPromotion_unit_manager: true,
      unit_appraisal_status: true,
      hrPayroll_closed_status: {$ne: true},
      status: 'Active',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};

export const HRInactiveAppraisalTab = props => {
  let {user = {}, route: {params = {}} = {}} = props || {};
  const {applyFilter, filterValues, searchValue, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, user};
  let tabs = {};

  tabs['Inactive Appraisal'] = {
    label: 'Inactive Appraisal',
    eventSourceId: 'Appraisal',
    view: (
      <HRAppraisalTable
        searchValue={searchValue}
        filterValues={filterValues}
        tab="Inactive Appraisal"
        addOnFilter={{
          ...filterValues.filter,
          status: 'Inactive',
        }}
        user={user}
        {...props}
      />
    ),
    addOnFilter: {
      ...filter,
      status: 'Inactive',
    },
    api: '/employeehrappraisal',
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[...appraisalCommonFilters]}
        />,
      ]}
    />
  );
};
