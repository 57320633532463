import React, {useEffect, useRef, useState} from 'react';
import {TextAreaRenderer} from '../../../components/form-editors/Editors';
import {ScrollView, View} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';
import {NoDataFound} from '../../../components/table/Table';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

export const ViewLogs = props => {
  const {
    route: {params},
  } = props;

  const {env_id, status, build_number} = params;
  const {fetch} = useAppStateContext();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const scrollViewRef = useRef(null);

  const fetchData = async () => {
    const {data, loading} = await fetch({
      uri: `/repositoryEnvironments/${env_id}/builds/${build_number}/getlogs`,
    });
    setLoading(loading);
    setValue(data);
  };

  useEffect(() => {
    fetchData();
    if (status === 'in progress') {
      const intervalId = setInterval(fetchData, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [value]);

  if (loading) {
    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <ActivityIndicator />
      </View>
    );
  }

  if (!loading && !value) {
    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <NoDataFound />
      </View>
    );
  }

  return (
    <ScrollView ref={scrollViewRef}>
      <TextAreaRenderer value={value} />
    </ScrollView>
  );
};
