import moment from 'moment';
import {Row, Text, View} from '@unthinkable/react-core-components';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {Tag} from '../../../components/chip/Chip';
import {Table} from '../../../components/table/Table';
import {renderStatus} from './EmployeeAttendanceTable';
import {useInvoke} from '../../../controllers/useInvoke';

export const LeaveRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
    tab,
  } = props;
  let sort = {};
  if (tab == 'open') {
    sort = {requested_on: -1};
  } else if (tab == 'close') {
    sort = {updatedAt: -1};
  }
  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams, periods};
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'leaveManagement',
  });
  return (
    <Table
      api={'/leaverequest/self'}
      params={params}
      eventSourceId={'leaveManagement'}
      addOnFilter={filter}
      search={searchValue}
      sort={{...sort}}
      // onRowPress={({row}) => {
      //   navigation.navigate(`update-leave-request`, {
      //     row,
      //   });
      // }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Row>
                <UserAvatar value={row?.employee?.reporting_to?.[0]} />
                <Text
                  style={{
                    ...rowText,
                    padding: 3,
                    marginLeft: 2,
                    color: '#A3A3A3',
                  }}>
                  {row?.requested_on &&
                    moment(row?.requested_on).format('DD MMM')}
                </Text>
              </Row>
            );
          },
          width: 250,
        },
        {
          header: 'Duration',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.from_date && moment(row?.from_date).format('DD MMM')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {row?.to_date && moment(row?.to_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
          width: 250,
        },
        {
          header: 'First Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.first_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
          width: 250,
        },
        {
          header: 'Second Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.second_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
          width: 250,
        },

        {
          header: 'Status',
          align: 'center',
          render: renderStatus,
          visible: () => tab && tab === 'closed',
        },
      ]}
      moreActions={[
        {
          title: 'Delete',
          onPress: ({row}) => {
            invoke({
              uri: `/requests/${row?._id}`,
            });
          },
          visible: ({row}) => {
            if (!row.status) {
              return true;
            }
          },
        },
      ]}
    />
  );
};
