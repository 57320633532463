import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';

export const ToolForm = props => {
  const {
    route: {params},
  } = props;
  const {row, parent, practice} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/tools',
    eventSourceId: ['Tools'],
  });
  return (
    <Form
      api={`/tools/${row?._id}`}
      fields={{
        name: 1,
        practice: {name: 1},
        parent: {
          name: 1,
        },
        tool_type: 1,
      }}
      defaultValues={{
        parent: parent?._id,
        practice: practice?._id,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          placeholder: 'Skill',
          field: 'name',
          type: 'text',
        },
        {
          label: 'Skill Type',
          field: 'tool_type',
          type: 'autoComplete',
          options: ['Tech', 'Non-Tech', 'BA', 'QA', 'DevOps', 'Product Design'],
        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'autoComplete',
          api: '/tools',
          suggestionField: 'name',
          valueField: 'name',
          visible: !parent,
        },
      ]}
      {...props}
    />
  );
};

export const AddToolForm = props => {
  return <ToolForm header={'Add Skill'} {...props} />;
};

export const EditToolForm = props => {
  return <ToolForm header={'Edit Skill'} mode="edit" {...props} />;
};
