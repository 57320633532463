import React from 'react';
import { TabView } from '../../../components/tab';
import { ProjectMyActiveTasksTable, ProjectMyCompletedTasksTable } from './MyProjectTaskMenus';
import { BacklogOrganizationTaskTable } from './BacklogOrganizationTaskTable';
import { FavoritesTaskTable, OpenTasksTable } from './TasksTable';
import { OrganizationActiveTasksGroupByPlan, OrganizationCompletedTasks } from './OrganizationActiveTasksGroupByPlan';
import { ArchivedOrganizationTaskTable } from './ArchivedOrganizationTaskTable';
import { BorderLessButton } from '../../../components/button/AddButton';
import { useFetchData } from '../../../controllers/useFetchData';
import { useAppStateContext } from '../../../providers/AppState';
import { ThreadGoalTabs } from './ThreadGoalAndIssueTabs';

export const MyProjectTaskTabs = props => {
    const {
        route: { params },
        navigation,
        user
    } = props;

    return (
        <TabView
            tabs={{
                active: {
                    label: 'Active',
                    view: <ProjectMyActiveTasksTable />,
                },
                completed: {
                    label: 'Completed',
                    view: <ProjectMyCompletedTasksTable />,
                },
            }}
            {...props}
        />
    );
};

export const MyCustomTaskTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const { user } = useAppStateContext();

    return (
        <TabView
            tabs={{
                open: {
                    label: 'Open',
                    view: <OpenTasksTable />,
                },
                backlog: {
                    label: 'Plan',
                    view: <BacklogOrganizationTaskTable userTeam={true}/>,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan />,
                },
                completed: {
                    label: 'Completed',
                    view: <OrganizationCompletedTasks />,
                },
                archived: {
                    label: 'Archived',
                    view: <ArchivedOrganizationTaskTable  user={user}/>,
                },
            }}
            {...props}
        />
    );
};

export const MyFavouriteTaskTabs = props => {
    const {
        route: { params },
        navigation,
    } = props;

    const {user} = useAppStateContext(); 

    return (
        <TabView
            tabs={{
                backlog: {
                    label: 'Plan',
                    view: <BacklogOrganizationTaskTable favorite={true} />,
                },
                active: {
                    label: 'Active',
                    view: <OrganizationActiveTasksGroupByPlan favorite={true} />,
                },
                completed: {
                    label: 'Completed',
                    view: <OrganizationCompletedTasks favorite={true} />,
                },
                archived: {
                    label: 'Archived',
                    view: <ArchivedOrganizationTaskTable favorite={true} user={user} />,
                },
            }}
            {...props}
        />
    );
};

export const MyThreadGoals = props => {
    props = {...props, myThreadGoals: true};
    let {route: {params = {}}} = props;
    params = {...params, threadNameNotVisible: true};
    props = {...props, route: {...props.route, params}};

    return <ThreadGoalTabs {...props}/>
}