import React from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useTheme} from '@unthinkable/react-theme';
import {Tag} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';

const activityLogVendorPayment = ({row, navigation}) => {
  navigation.navigate(`activity-logs`, {
    _id: row?._id,
    populate: [{path: 'owner', select: {name: 1}}],
    api: `/payments/${row?._id}/activityLogs`,
    displayFields: {
      name: {
        label: 'Vendor',
      },
    },
  });
};

const RenderPaymentType = props => {
  const {
    row,
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  if (row?.payment_type && row?.payment_type == 'Prepayment') {
    return (
      <>
        <Tag value={'Advance'} color={colors?.ACCENT5_MEDIUM} />
        {/* <Text
          style={{
            ...CAPTION_LARGE,
            color: colors.NEUTRAL_MEDIUM,
            paddingLeft: 10,
          }}>
          {moment(row?.lost_date).format('DD MMM, YY')}
        </Text> */}
      </>
    );
  } else if (row?.payment_type && row?.payment_type == 'Bill') {
    return (
      <>
        <Tag value={'Bill'} color={colors?.ACCENT3_MEDIUM} />
      </>
    );
  } else if (row?.payment_type && row?.payment_type == 'Tax Payment') {
    return (
      <>
        <Tag value={'Tax Payment'} color={colors?.ACCENT7_MEDIUM} />
      </>
    );
  } else if (row?.payment_type && row?.payment_type == 'Loan') {
    return <Tag value={'Loan'} color={colors?.ACCENT6_MEDIUM} />;
  } else if (row?.payment_type && row?.payment_type == 'Direct') {
    return <Tag value={'Direct'} color={colors?.ACCENT4_MEDIUM} />;
  } else if (row?.payment_type && row?.payment_type == 'Other') {
    return <Tag value={'Other'} color={colors?.ACCENT2_MEDIUM} />;
  } else {
    return null;
  }
};

export const VendorPaymentTable = props => {
  let {
    route: {params = {}},
    navigation,
    addOnFilter,
    filterValues,
    searchValue,
  } = props;
  const {extraFilter = {}} = params;
  if (filterValues && filterValues.filter) {
    addOnFilter = {...filterValues.filter, ...addOnFilter};
  }
  const {user, download} = useAppStateContext();
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'vendorPayment',
  });
  return (
    <Table
      eventSourceId={['vendorPayment']}
      search={searchValue}
      api={`/vendorpayments`}
      addOnFilter={{...addOnFilter, ...extraFilter}}
      searchFields="payment_no"
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-payment-details', {
          row,
        })
      }
      selection={{
        actions: [
          {
            title: 'Send Payment Mail',
            confirm: {
              title: 'Send Payment Mail',
              message: 'Are you sure you want to send payment mail?',
            },
            onPress: ({selectedIds}) => {
              invoke({
                uri: '/sendPaymentMail',
                props: {
                  selectedIds,
                },
              });
            },
          },
          {
            title: 'Download',
            onPress: ({selectedIds}) => {
              download({
                uri: '/exportpayments',
                props: {
                  addOnFilter: {
                    ...filterValues.filter,
                    _id: {$in: selectedIds},
                  },
                },
              });
            },
          },
        ],
      }}
      columns={[
        {
          header: 'Vendor',
          field: 'payee_id',
          type: 'userAvatarChip',
          minWidth: 200,
        },
        {
          header: 'Type',
          field: 'payment_type',
          type: 'colorTag',
          render: RenderPaymentType,
          type: 'text',
          width: 200,
        },
        {
          header: 'Payment No',
          field: 'payment_no',
          type: 'text',
          width: 150,
        },
        {
          header: 'Date',
          type: 'date',
          field: 'payment_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 150,
        },
        {
          header: 'Amount',
          type: 'currency',
          field: 'total_payment_amount',
          aggregate: true,
          width: 150,
        },
        {
          header: 'Pending',
          type: 'currency',
          field: 'advance_amount_pending',
          // aggregate: true,
          width: 150,
        },
      ]}
      moreActions={[
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {payment_id: row._id},
            });
          },
        },
        {
          title: 'Delete Payment',
          confirm: {
            title: 'Delete Payment',
            message: 'Are you sure you want to delete?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/payment/delete-payment/${row._id}`,
              props: {
                row,
              },
            });
          },
          visible: () => {
            if (
              user.email === 'sushil.nagvan@daffodilsw.com' ||
              user.email === 'amit.singh@daffodildb.com'
            ) {
              return true;
            }
          },
        },
        {
          title: 'Adjust Payment',
          confirm: {
            title: 'Adjust Payment',
            message: 'Are you sure you want to Adjust Payment?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/payment/adjust-payment/${row._id}`,
              props: {
                row,
              },
            });
          },
          visible : ({row}) => {
              if(row?.salary_detail?.length){
                return true
              }
              return false
          }
        },
        {
          title: 'Activity Logs',
          onPress: ({row}) => activityLogVendorPayment({row, navigation}),
        },
      ]}
    />
  );
};


export const VendorAdvancePaymentTable = props => {
  let {
    route: {params = {}},
    navigation,
    filterValues,
    searchValue,
  } = props;
  const {addOnFilter = {}} = params;
  if (filterValues && filterValues.filter) {
    addOnFilter = {...filterValues.filter, ...addOnFilter};
  }
  return (
    <Table
      eventSourceId={['vendorPayment']}
      search={searchValue}
      params={{...params}}
      api={`/pendingPaymentsDetail`}
      addOnFilter={{...addOnFilter}}
      searchFields="payment_no"
      onRowPress={({row}) =>
        navigation.navigate('edit-vendor-payment-details', {
          row,
        })
      }
      columns={[
        {
          header: 'Payment No',
          field: 'payment_no',
          type: 'text',
        },
        {
          header: 'Date',
          type: 'date',
          field: 'payment_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          header: 'Amount',
          type: 'currency',
          field: 'advance_amount_pending',
          aggregate: true,
        },
      ]}
    />
  );
};