import {Text} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Durations} from './components/Durations';
import {TableHeader} from '../../../components/table/Headers';
import {getCurrentMonth} from '@unthinkable/react-filters';

const assignShadow = ({navigation, params}) => ({
  title: 'Assign Shadow',
  onPress: props => {
    const {row} = props;
    const {
      _id,
      employee,
      skill_requirement = {},
      milestone_id = {},
      ...rest
    } = row || {};

    navigation.navigate('assign-shadow-form', {
      ...params,
      allocated_data: {
        ...rest,
        skill_requirement: skill_requirement?._id,
        milestone_id: milestone_id?._id,
        front_facing_resource: employee,
        from_date: getZeroTimeDate(new Date()),
      },
    });
  },
  visible: ({row}) => {
    return ['Billable'].includes(row?.assigned_type) && !row?.is_shadow;
  },
});

const stopAllocation = ({navigation, params}) => ({
  title: 'Stop Allocation',
  onPress: props => {
    const {row} = props;
    navigation.navigate('stop-allocation-form', {
      ...params,
      row,
    });
  },
  // visible: ({row}) => {
  //   return row?.assigned_type == 'Billable';
  // },
});

const stopNonProjectAllocation = ({navigation, params}) => ({
  title: 'Stop Allocation',
  onPress: props => {
    const {row} = props;
    navigation.navigate('stop-non-project-allocation-form', {
      ...params,
      row,
    });
  },
});
const updateAllocation = ({navigation, params}) => ({
  title: 'Update Allocation',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-allocation-form', {
      ...params,
      row,
    });
  },
  // visible: ({row}) => {
  //   return row?.assigned_type == 'Billable';
  // },
});

const changeAllocation = ({navigation, params}) => ({
  title: 'Add Other Resource',
  onPress: props => {
    const {row} = props || {};
    const {
      _id,
      to_date,
      skill_requirement = {},
      milestone_id = {},
      ...rest
    } = row || {};
    navigation.navigate('change-allocation-form', {
      ...params,
      allocated_data: {
        ...rest,
        to_date: skill_requirement?.to_date || milestone_id?.end_date,
        skill_requirement: skill_requirement?._id,
        milestone_id: milestone_id?._id,
        from_date: getZeroTimeDate(
          new Date().setDate(new Date().getDate() + 1),
        ),
      },
    });
  },
  visible: ({row}) => {
    return row?.assigned_type == 'Billable';
  },
});

const addReplacement = ({navigation, params}) => ({
  title: 'Add Replacement',
  onPress: props => {
    const {row} = props || {};
    const {
      _id,
      to_date,
      skill_requirement = {},
      milestone_id,
      ...rest
    } = row || {};
    navigation.navigate('change-allocation-form', {
      ...params,
      allocated_data: {
        ...rest,
        to_date: skill_requirement?.to_date || milestone_id?.end_date,
        skill_requirement: skill_requirement?._id,
        milestone_id: milestone_id?._id,
        from_date: getZeroTimeDate(
          new Date().setDate(new Date().getDate() + 1),
        ),
      },
      type: 'nonbillable',
      updated_data: {_id, to_date},
    });
  },
  visible: ({row}) => {
    return row?.assigned_type == 'Non-Billable';
  },
});

const assignTrainee = ({navigation, params}) => ({
  title: 'Assign Trainee',
  onPress: props => {
    const {row} = props || {};
    let {employee} = row || {};
    navigation.navigate('assign-trainee-form', {
      employee,
    });
  },
  visible: ({row}) => {
    return row?.assigned_type == 'Non-Billable';
  },
});

const assignAsReplacement = ({navigation, params}) => ({
  title: 'Assign As Replacement',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-replacement', {
      row,
    });
  },
  visible: ({row}) => {
    return row?.assigned_type == 'Non-Billable';
  },
});

const assignTraining = ({navigation, params}) => ({
  title: 'Assign Training',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-training', {
      row,
    });
  },
  visible: ({row}) => {
    return row?.assigned_type == 'Non-Billable';
  },
});

const updateLevelOwner = ({navigation, params}) => ({
  title: 'Update Level Owner',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-levels-owners', {
      row,
    });
  },
});
const AllocationActiviyHistory = ({navigation, params}) => ({
  title: 'Activity History',
  onPress: props => {
    const {row} = props;
    navigation.navigate(`activity-logs`, {
      _id: row._id,
      populate: [
        {path: 'employee', select: {name: 1}},
        {
          path: 'order',
          select: {order_number: 1},
        },
      ],
      api: `/employeeassignments/${row?._id}/activityLogs`,
      displayFields: {
        Employee: {
          label: 'Employee',
          field: 'employee.name',
        },
        orderNo: {
          label: 'Order No',
          field: 'order.order_number',
        },
        from_date: {
          label: 'From Date',
          field: 'from_date',
        },
        to_date: {
          label: 'To Date',
          field: 'to_date',
        },
        allocated_hours: {
          label: 'Allocated Hours',
          field: 'hours',
        },
        assigned_type: {
          label: 'Assigned Type',
          field: 'assigned_type',
        },
      },
    });
  },
});

const DetailResourceAllocatedList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {
    addOnFilter = {},
    filterParams = {},
    employeeFilter = {},
    fixedOrderFilter = {},
    mileStoneFilter = {},
    fromNonProject,
    fromReport,
    api,
    date,
    navigationParams = {},
    skipAddMember,
  } = params;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period, date};

  let filtersData = [
    {
      type: 'autoComplete',
      label: 'Employee',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'employee',
    },
    {
      type: 'autoComplete',
      label: 'Skill',
      api: '/skillmetrics',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'skill_metrics',
    },
    {
      label: 'From date',
      type: 'date',
      field: 'period',
      asParam: true,
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project_id',
    },

    {
      type: 'autoComplete',
      options: ['Billable', 'Non-Billable'],
      field: 'assigned_type',
      label: 'Type',
    },
    {
      type: 'autoComplete',
      options: [
        'Client Managed',
        'Daffodil Managed',
        'Hybrid Managed',
        'Daffodil Managed - Support',
      ],
      field: 'project_sub_category',
      label: 'Project SubCategory',
    },
  ];
  if (Object.keys(employeeFilter).length > 0) {
    filtersData = [
      {
        type: 'autoComplete',
        label: 'Orders',
        api: '/orders',
        placeholder: 'Select',
        suggestionField: 'order_number',
        valueField: 'order_number',
        field: 'order',
      },
      {
        type: 'autoComplete',
        label: 'Project',
        api: '/projects',
        placeholder: 'Select',
        suggestionField: 'project',
        valueField: 'project',
        field: 'project_id',
      },
      {
        label: 'From date',
        type: 'date',
        field: 'period',
        asParam: true,
      },
      {
        type: 'autoComplete',
        options: ['Billable', 'Non-Billable'],
        field: 'assigned_type',
        label: 'Type',
      },
      {
        type: 'autoComplete',
        options: [
          'Client Managed',
          'Daffodil Managed',
          'Hybrid Managed',
          'Daffodil Managed - Support',
        ],
        field: 'project_sub_category',
        label: 'Project SubCategory',
      },
      {
        type: 'autoComplete',
        label: 'Skill',
        api: '/skillmetrics',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'skill_metrics',
      },
      {
        type: 'autoComplete',
        label: 'Tools',
        api: '/tools',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'tool',
      },
    ];
  }
  return (
    <Table
      api={api ? api : '/allocatedList'}
      eventSourceId={['allocatedResource', 'EmployeeTeam']}
      renderHeader={() => (
        <TableHeader
          title="Allocated Resource"
          actions={[
            !skipAddMember && fromNonProject && (
              <AddButton
                title="Member"
                view="assign-resource-on-team"
                params={{
                  ...params,
                  is_internal: true,
                }}
              />
            ),
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={filtersData}
            />,
          ]}
        />
      )}
      addOnFilter={{
        ...filter,
        ...addOnFilter,
        ...employeeFilter,
        ...filterParams,
        ...fixedOrderFilter,
        ...mileStoneFilter,
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          minWidth: 180,
        },
        {
          header: 'Project',
          field: 'project_id.project',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.project_id?.project}
                items={[
                  {
                    value: row?.order?.order_number,
                  },
                ]}
              />
            );
          },
          visible: () => (fromReport ? false : true),
        },
        {
          header: 'Skill',
          field: 'resource_tool.name',
          type: 'colorTag',
          colorField: 'resource_tool.color',
        },
        {
          header: 'Delivery No.',
          field: 'project_resource_working_data.delivery_number',
          type: 'colorTag',
        },
        {
          header: 'Duration',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: 'Alloc. Hrs',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          width: 150,
          visible: () => (fromReport ? false : true),
        },
        {
          header: 'Cost',
          field: 'ctc_amount',
          type: 'currency',
          visible: () => (fromReport ? true : false),
        },
      ]}
      moreActions={() =>
        !fromNonProject
          ? [
              assignShadow({navigation, params}),
              stopAllocation({navigation, params}),
              updateAllocation({navigation, params}),
              changeAllocation({navigation, params}),
              assignAsReplacement({navigation, params}),
              assignTraining({navigation, params}),
            ]
          : [stopNonProjectAllocation({navigation, params})]
      }
      params={{...params, ...navigationParams}}
    />
  );
};

// Akshat Garg - 02/09/24 - detail resource allocation department wise
export const DetailResourceAllocatedDepartmentList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {
    addOnFilter = {},
    filterParams = {},
    employeeFilter = {},
    fixedOrderFilter = {},
    mileStoneFilter = {},
    fromNonProject,
    fromReport,
    api,
    date,
    navigationParams = {},
    skipAddMember,
  } = params;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period, date};

  let filtersData = [
    {
      type: 'autoComplete',
      label: 'Employee',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      secondarySuggestionField: 'official_email_id',
      valueField: 'name',
      field: 'employee',
    },
    {
      type: 'autoComplete',
      label: 'Skill',
      api: '/skillmetrics',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'skill_metrics',
    },
    {
      label: 'From date',
      type: 'date',
      field: 'period',
      asParam: true,
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project_id',
    },

    {
      type: 'autoComplete',
      options: ['Billable', 'Non-Billable'],
      field: 'assigned_type',
      label: 'Type',
    },
    {
      type: 'autoComplete',
      options: [
        'Client Managed',
        'Daffodil Managed',
        'Hybrid Managed',
        'Daffodil Managed - Support',
      ],
      field: 'project_sub_category',
      label: 'Project SubCategory',
    },
  ];
  if (Object.keys(employeeFilter).length > 0) {
    filtersData = [
      {
        type: 'autoComplete',
        label: 'Orders',
        api: '/orders',
        placeholder: 'Select',
        suggestionField: 'order_number',
        valueField: 'order_number',
        field: 'order',
      },
      {
        type: 'autoComplete',
        label: 'Project',
        api: '/projects',
        placeholder: 'Select',
        suggestionField: 'project',
        valueField: 'project',
        field: 'project_id',
      },
      {
        label: 'From date',
        type: 'date',
        field: 'period',
        asParam: true,
      },
      {
        type: 'autoComplete',
        options: ['Billable', 'Non-Billable'],
        field: 'assigned_type',
        label: 'Type',
      },
      {
        type: 'autoComplete',
        options: [
          'Client Managed',
          'Daffodil Managed',
          'Hybrid Managed',
          'Daffodil Managed - Support',
        ],
        field: 'project_sub_category',
        label: 'Project SubCategory',
      },
      {
        type: 'autoComplete',
        label: 'Skill',
        api: '/skillmetrics',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'skill_metrics',
      },
      {
        type: 'autoComplete',
        label: 'Tools',
        api: '/tools',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'tool',
      },
    ];
  }
  return (
    <Table
      api={api ? api : '/allocatedList'}
      eventSourceId={['allocatedResource', 'EmployeeTeam']}
      renderHeader={() => (
        <TableHeader
          title="Allocated Resource"
          actions={[
            !skipAddMember && fromNonProject && (
              <AddButton
                title="Member"
                view="assign-resource-on-team"
                params={{
                  ...params,
                  is_internal: true,
                }}
              />
            ),
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={filtersData}
            />,
          ]}
        />
      )}
      addOnFilter={{
        ...filter,
        ...addOnFilter,
        ...employeeFilter,
        ...filterParams,
        ...fixedOrderFilter,
        ...mileStoneFilter,
      }}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          minWidth: 180,
        },
        {
          header: 'Skill',
          field: 'employee.resource_language.name',
          type: 'colorTag',
          colorField: 'resource_tool.color',
          align: 'center',
        },
        {
          header: 'Exp',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {`${(row?.employee?.total_experience_months / 12).toFixed(1)}`}
              </Text>
            );
          },
          align: 'right',
        },
        {
          header: 'Duration',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: 'Alloc. Hrs',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
      ]}
      moreActions={() =>
        !fromNonProject
          ? [
              assignShadow({navigation, params}),
              stopAllocation({navigation, params}),
              updateAllocation({navigation, params}),
              changeAllocation({navigation, params}),
              assignAsReplacement({navigation, params}),
              assignTraining({navigation, params}),
            ]
          : [stopNonProjectAllocation({navigation, params})]
      }
      params={{...params, ...navigationParams}}
    />
  );
};

export const TrainingResourceAllocatedList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {addOnFilter = {}, filterParams = {}, employeeFilter = {}} = params;
  const {applyFilter, filterValues} = useFilter({});
  const {filter, params: {period = {}} = {}} = filterValues || {};
  params = {...params, period};

  let filtersData = [
    {
      type: 'autoComplete',
      label: 'Employee',
      api: '/employeeSuggestions',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'employee',
    },
    {
      type: 'autoComplete',
      label: 'Skill',
      api: '/skillmetrics',
      placeholder: 'Select',
      suggestionField: 'name',
      valueField: 'name',
      field: 'skill_metrics',
    },
    {
      label: 'From date',
      type: 'date',
      field: 'period',
      asParam: true,
    },
    {
      type: 'autoComplete',
      label: 'Project',
      api: '/projects',
      placeholder: 'Select',
      suggestionField: 'project',
      valueField: 'project',
      field: 'project_id',
    },

    {
      type: 'autoComplete',
      options: ['Billable', 'Non-Billable'],
      field: 'assigned_type',
      label: 'Type',
    },
    {
      type: 'autoComplete',
      options: [
        'Client Managed',
        'Daffodil Managed',
        'Hybrid Managed',
        'Daffodil Managed - Support',
      ],
      field: 'project_sub_category',
      label: 'Project SubCategory',
    },
  ];
  if (Object.keys(employeeFilter).length > 0) {
    filtersData = [
      {
        type: 'autoComplete',
        label: 'Skill',
        api: '/skillmetrics',
        placeholder: 'Select',
        suggestionField: 'name',
        valueField: 'name',
        field: 'skill_metrics',
      },
      {
        type: 'autoComplete',
        label: 'Project',
        api: '/projects',
        placeholder: 'Select',
        suggestionField: 'project',
        valueField: 'project',
        field: 'project_id',
      },
      {
        label: 'From date',
        type: 'date',
        field: 'period',
        asParam: true,
      },
      {
        type: 'autoComplete',
        options: ['Billable', 'Non-Billable'],
        field: 'assigned_type',
        label: 'Type',
      },
      {
        type: 'autoComplete',
        options: [
          'Client Managed',
          'Daffodil Managed',
          'Hybrid Managed',
          'Daffodil Managed - Support',
        ],
        field: 'project_sub_category',
        label: 'Project SubCategory',
      },
    ];
  }
  return (
    <Table
      api={'/trainingassignment'}
      eventSourceId={'allocatedResource'}
      renderHeader={() => (
        <TableHeader
          title="Allocated Resource"
          actions={[
            <GroupFilter
              filterValues={filterValues}
              applyFilter={applyFilter}
              filters={filtersData}
            />,
          ]}
        />
      )}
      addOnFilter={{
        ...filter,
        ...addOnFilter,
        ...employeeFilter,
        ...filterParams,
      }}
      columns={[
        {header: 'Employee', field: 'employee', type: 'userAvatarChip'},
        {
          type: 'text',
          header: 'Experience',
          field: 'employee.experience',
        },
        {header: 'Project', field: 'project_id.project'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: 'Allocated Hours',
          align: 'center',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          width: 150,
        },
      ]}
      moreActions={() => [
        assignShadow({navigation, params}),
        stopAllocation({navigation, params}),
        changeAllocation({navigation, params}),
        assignAsReplacement({navigation, params}),
        assignTraining({navigation, params}),
      ]}
      params={params}
    />
  );
};

export const AllocationList = props => {
  let {
    filterParams,
    period,
    route: {params},
    navigation,
  } = props;
  if (params.filterParams) {
    filterParams = params?.filterParams;
  }
  if (params.fromAttendence) {
    period = params?.period;
  }
  params = {...params, period};

  return (
    <Table
      api={'/allocatedList'}
      eventSourceId={'allocatedResource'}
      addOnFilter={{...filterParams, is_wrong_input: {$in: [false, null]}}}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                values={[{value: employee?.official_email_id}]}
              />
            );
          },
          width: 260,
        },
        {
          header: 'Exp.',
          render: ({row, styles: {rowText = {}} = {}}) => {
            return (
              <Text style={{...rowText}}>
                {`${row?.employee?.experience} yrs`}
              </Text>
            );
          },
          width: 80,
        },
        {
          header: 'Skill',
          field: 'resource_tool.name',
          type: 'colorTag',
          colorField: 'resource_tool.color',
        },
        // {header: 'Project', field: 'project_id.project'},
        {
          header: 'Project',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.project_id?.project}
                items={[{value: row?.order?.order_number}]}
              />
            );
          },
        },
        {
          header: 'Billed Hrs.',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 100,
        },
        {
          header: 'Assigned Till',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          width: 180,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          // width: 150,
        },
      ]}
      moreActions={() => [
        assignShadow({navigation, params}),
        stopAllocation({navigation, params}),
        updateAllocation({navigation, params}),
        changeAllocation({navigation, params}),
        assignAsReplacement({navigation, params}),
        assignTraining({navigation, params}),
        updateLevelOwner({navigation, params}),
        AllocationActiviyHistory({navigation, params}),
        {
          title: 'Mark Anchor Resource',
          onPress: ({row}) => {
            navigation.navigate(`replacement-form`, {
              ...row,
              assigned_to: {_id: row?.employee?._id},
              defaultValues: {
                status: 'due',
                type: 'Anchor',
              },
            });
          },
        },
        {
          title: 'Mark BandWidth Release',
          onPress: ({row}) => {
            navigation.navigate(`replacement-form`, {
              ...row,
              assigned_to: {_id: row?.employee?._id},
              defaultValues: {
                status: 'due',
                type: 'Bandwidth Release',
              },
            });
          },
        },
      ]}
      params={params}
    />
  );
};

export const AllAllocationResourceList = props => {
  let {
    filterParams,
    period,
    route: {params},
    navigation,
  } = props;
  const {employeeassignmentFilter = {}, fromBillingStatus = false} =
    params || {};
  params = {...params, period};

  return (
    <Table
      api={'/allocatedList'}
      eventSourceId={'allocatedResource'}
      addOnFilter={{
        ...filterParams,
        ...employeeassignmentFilter,
        is_wrong_input: {$in: [false, null]},
      }}
      renderHeader={() =>
        fromBillingStatus && <TableHeader title="Allocations" {...props} />
      }
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                official_email_id={employee?.official_email_id}
              />
            );
          },
          width: 260,
        },
        {header: 'Project', field: 'project_id.project'},
        {header: 'Order No.', field: 'order.order_number'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: 'Allocated Hours',
          align: 'center',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          width: 150,
        },
      ]}
      params={params}
    />
  );
};

export const ResourceAllocationsReadOnly = props => {
  const {
    route: {params},
  } = props;

  const filterProps = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filterValues = {}} = filterProps;

  return (
    <Table
      api={'/allocatedList'}
      eventSourceId={'allocatedResource'}
      addOnFilter={{
        is_wrong_input: {$in: [false, null]},
        ...filterValues.filter,
      }}
      params={{...params, ...filterValues.params}}
      renderHeader={() => (
        <TableHeader
          title="Allocations"
          actions={[
            <PeriodRangeFilter {...filterProps} />,
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  type: 'autoComplete',
                  label: 'Employee',
                  api: '/employeeSuggestions',
                  placeholder: 'Select',
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  field: 'employee',
                },
                {
                  type: 'autoComplete',
                  label: 'Project',
                  api: '/projects',
                  placeholder: 'Select',
                  suggestionField: 'project',
                  valueField: 'project',
                  field: 'project_id',
                },
                {
                  label: 'Type',
                  type: 'autoComplete',
                  options: ['Billable', 'Non-Billable'],
                  field: 'assigned_type',
                },
              ]}
            />,
          ]}
        />
      )}
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                official_email_id={employee?.official_email_id}
              />
            );
          },
          width: 260,
        },
        {header: 'Project', field: 'project_id.project'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row, styles}) => {
            return (
              <Durations
                styles={styles.text}
                toDate={row?.to_date}
                fromDate={row?.from_date}
              />
            );
          },
        },
        {
          header: 'Allocated Hours',
          align: 'center',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          width: 150,
        },
      ]}
    />
  );
};

export const AllocationResourceForBillingStatusReportList = props => {
  let {
    filterParams,
    period,
    route: {params},
    navigation,
  } = props;
  const {employeeassignmentFilter = {}, fromBillingStatus = false} =
    params || {};
  params = {...params, period};

  return (
    <Table
      api={'/allocatedList'}
      eventSourceId={'allocatedResource'}
      addOnFilter={{
        ...filterParams,
        ...employeeassignmentFilter,
        is_wrong_input: {$in: [false, null]},
      }}
      renderHeader={() =>
        fromBillingStatus && <TableHeader title="Allocations" {...props} />
      }
      columns={[
        {
          header: 'Employee',
          field: 'employee',
          type: 'userAvatarChip',
          render: ({row}) => {
            const {employee} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={employee?.name}
                color={employee?.color}
                official_email_id={employee?.official_email_id}
              />
            );
          },
          width: 260,
        },
        {header: 'Project', field: 'project_id.project'},
        {header: 'Order No.', field: 'order.order_number'},
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: 'Allocated Hours',
          align: 'center',
          field: 'hours',
          type: 'number',
          formatOptions: {
            maximumFractionDigits: 2,
          },
          width: 150,
        },
        {
          header: 'Type',
          align: 'center',
          field: 'assigned_type',
          width: 150,
        },
      ]}
      params={params}
      moreActions={() => [
        updateAllocation({navigation, params}),
        AllocationActiviyHistory({navigation, params}),
      ]}
    />
  );
};

export default DetailResourceAllocatedList;
