import React from 'react';
import {useFilter} from '@unthinkable/react-filters';

import {TabView} from '../../../components/tab';
import {UserStoryScreen} from './UserstoryTable';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const UserStoryTab = props => {
  let {
    route: {params},
  } = props;

  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter(
    {},
  );
  const {project, module, feature, projectlibrary_id} = params;

  const groupBy = useGroupBy({
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            const {module} = row || {};
            return <GroupContentItem value={module || 'No Module'} />;
          },
        },
      },
    ],
  });

  let filter = {
    ...filterValues.filter,
    project_id: project?._id,
    library_id: projectlibrary_id || null,
  };

  if (feature) {
    filter.objective_ids = feature?._id;
  } else if (module) {
    filter.module_id = module?._id;
  }

  const countProps = {
    search: searchValue,
    searchFields: ['userstory', 'code'],
    eventSourceId: ['UserStory', 'RemoveMappedFeature', 'ProjectAI'],
    filter: filter,
  };

  return (
    <TabView
      tabs={{
        backlog: {
          label: 'Backlog',
          view: (
            <UserStoryScreen
              api={'/userstories/backlog'}
              selectedTab={'backlog'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              subStatus={null}
              status={'backlog'}
              groupBy={groupBy}
              {...props}
            />
          ),
          api: '/userstories/backlog',
          ...countProps,
          filter: {...filter, sub_status: null},
        },
        architecture: {
          label: 'Architecture',
          view: (
            <UserStoryScreen
              api={'/userstories/backlog'}
              selectedTab={'architecture'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              subStatus="Architecture"
              status={'backlog'}
              groupBy={groupBy}
              {...props}
            />
          ),
          api: '/userstories/backlog',
          ...countProps,
          filter: {...filter, sub_status: 'Architecture'},
        },
        development: {
          label: 'Development',
          view: (
            <UserStoryScreen
              api={'/userstories/development'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              groupBy={groupBy}
              status={'active'}
              {...props}
            />
          ),
          api: '/userstories/development',
          ...countProps,
        },
        qa: {
          label: 'QA',
          view: (
            <UserStoryScreen
              api={'/userstories/qa'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              groupBy={groupBy}
              status={'qa'}
              {...props}
            />
          ),
          api: '/userstories/qa',
          ...countProps,
        },
        released: {
          label: 'Released',
          view: (
            <UserStoryScreen
              selectedTab={'released'}
              api={'/userstories/released'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              groupBy={groupBy}
              status={'released'}
              {...props}
            />
          ),
          api: '/userstories/released',
          ...countProps,
        },
        all: {
          label: 'All',
          view: (
            <UserStoryScreen
              api={'/userstories/all'}
              selectedTab={'all'}
              onChangeFilter={onChangeFilter}
              searchValue={searchValue}
              filterValues={filterValues}
              applyFilter={applyFilter}
              groupBy={groupBy}
              {...props}
            />
          ),
          api: '/projectUserstories',
          ...countProps,
        },
      }}
    />
  );
};
