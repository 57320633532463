import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useAppStateContext} from '../../../../providers/AppState';
import {HEADER_CONTENT_TYPES} from '../ApiConfigurationForm';

export const SwaggerConfigForm = props => {
  const {
    route: {params},
  } = props;
  const {selectedIds, project_id, module_id, fromModule, fromProject} = params;

  const defaultValues = {
    module_id,
    project_id,
    selectedIds,
    file_type: 'JSON',
    action_type: 'Download',
  };

  const {download, fetch} = useAppStateContext();
  return (
    <Form
      header={{title: 'Swagger Configurations'}}
      onSubmit={data => {
        let uri = `/downloadSwagger`;

        if (fromModule) {
          uri = `/modules/${module_id}/downloadSwagger`;
        } else if (fromProject) {
          uri = `/projects/${project_id}/downloadSwagger`;
        }

        if (data.action_type === 'Commit') {
          fetch({
            uri,
            props: {
              params: data,
            },
          });
        } else {
          download({
            uri,
            props: data,
          });
        }
      }}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Domain',
          type: 'text',
          field: 'domain',
        },
        {
          label: 'Default Security type',
          type: 'autoComplete',
          field: 'security_type',
          options: ['BasicAuth', 'BearerAuth', 'ApiKeyAuth'],
        },
        {
          label: 'Default Response type',
          type: 'autoComplete',
          field: 'resp_format',
          options: Object.keys(HEADER_CONTENT_TYPES),
        },
        {
          label: 'File Type',
          field: 'file_type',
          type: 'radioGroup',
          options: ['JSON', 'YML'],
        },
        {
          label: 'Actions',
          field: 'action_type',
          type: 'radioGroup',
          options: ['Download', 'Commit'],
        },
        {
          visible: ({values}) => values.action_type === 'Commit',
          fields: [
            {
              label: 'Select Repository',
              field: 'repository',
              type: 'autoComplete',
              api: `/projectRepositories`,
              filter: {project_id, type: 'Backend'},
              suggestionField: 'repository',
              secondarySuggestionField: 'repository',
              valueField: 'repository',
              required: true,
            },
            {
              label: 'Path',
              field: 'path',
              type: 'text',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
