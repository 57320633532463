import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from '../../../components/button/Button';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const ProjectTypeForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/projecttypes',
    eventSourceId: 'ProjectsRole',
    ...props,
  });
  const {user = {}} = useAppStateContext();
  const defaultValues = {};
  if (user?.org) {
    defaultValues.org = user.org;
  }
  const {route = {}} = props || {};
  const {params = {}} = route || {};
  let {project_type} = params || {};

  return (
    <Form
      api={`/projecttypes/${project_type}`}
      fields={{
        project_billing_type: 1,
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          fields: [
            {
              label: 'Type',
              field: 'project_billing_type',
              type: 'text',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectTypeForm = props => {
  return (
    <ProjectTypeForm
      header={{
        title: 'Add Project Type',
      }}
      successMessage="Project type added."
      {...props}
    />
  );
};

export const EditProjectTypeForm = props => {
  return (
    <ProjectTypeForm
      mode="edit"
      header={{
        title: 'Edit Project Type',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
        },
      }}
      {...props}
    />
  );
};

export const ProjectSubTypeForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectsubtypes',
    eventSourceId: 'ProjectsRole',
    ...props,
  });
  const {user = {}} = useAppStateContext();
  const defaultValues = {};
  if (user?.org) {
    defaultValues.org = user.org;
  }
  const {route = {}} = props || {};
  const {params = {}} = route || {};
  const {project_type} = params || {};

  return (
    <Form
      api={`/projectsubtypes/${project_type}`}
      fields={{
        project_sub_type: 1,
        project_type: {_id: 1, project_billing_type: 1},
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          fields: [
            {
              label: 'Label',
              field: 'project_sub_type',
              type: 'text',
              required: true,
            },
            {
              label: 'Parent',
              field: 'parent',
              type: 'autoComplete',
              api: `/projectsubtypes`,
              suggestionField: 'project_sub_type',
              valueField: 'project_sub_type',
              // required: true,
              filter: {org: user.org},
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const ProjectApprovalRequiredForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectapprovalrequireds',
    eventSourceId: 'ProjectsRole',
    ...props,
  });
  const {user = {}} = useAppStateContext();
  const defaultValues = {};
  if (user?.org) {
    defaultValues.org = user.org;
  }
  const {route = {}} = props || {};
  const {params = {}} = route || {};
  const {project_type} = params || {};
  return (
    <Form
      api={`/projectapprovalrequireds/${project_type}`}
      fields={{
        approval_required: 1,
        org: {_id: 1, name: 1},
      }}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          fields: [
            {
              field: 'approval_required',
              type: 'autoComplete',
              options: ['Yes', 'No'],
              size: 6,
              label: 'Approval Required',
            },
            {
              label: 'Organization',
              field: 'org',
              type: 'autoComplete',
              api: `/userorganizations`,
              suggestionField: 'name',
              valueField: 'name',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectApprovalRequiredForm = props => {
  return (
    <ProjectApprovalRequiredForm
      header={{
        title: 'Add Project Approval',
      }}
      successMessage="Added."
      {...props}
    />
  );
};

export const EditProjectApprovalRequirdForm = props => {
  return (
    <ProjectApprovalRequiredForm
      mode="edit"
      header={{
        title: 'Edit Project Project Approval',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
        },
      }}
      {...props}
    />
  );
};

export const AddProjectSubTypeForm = props => {
  return (
    <ProjectSubTypeForm
      header={{
        title: 'Add Project Label',
      }}
      successMessage="Project label added."
      {...props}
    />
  );
};

export const EditProjectSubTypeForm = props => {
  return (
    <ProjectSubTypeForm
      mode="edit"
      header={{
        title: 'Edit Project Label',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
        },
      }}
      {...props}
    />
  );
};
