import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Image, Text, View} from '@unthinkable/react-core-components';
import NestedTable from '../../../components/form/NestedTable';
import {useAppStateContext} from '../../../providers/AppState';
import {useFetchFormData} from '../../../controllers/useFetchData';
import {useTheme} from '@unthinkable/react-theme';
import {Table} from '../../../components/table/Table';
import {NestedTableModalRender} from '../../../app-components/renders/NestedTableModalRender';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';

const AdvanceFieldNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Advance Details'}}
      field={'advance'}
      selection={{
        deleteAction: true,
      }}
      fields={[
        {
          field: 'payment_id',
          type: 'autoComplete',
          api: '/payments',
          suggestionField: 'payment_no',
          valueField: 'payment_no',
          label: 'Payment',
        },

        {
          field: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          type: 'number',
          label: 'Amount Converted',
          field: 'amount_converted',
        },
      ]}
      columns={[
        {
          field: 'payment_id.payment_no',
          type: 'text',
          header: 'Payment',
          // width: 150,
        },

        {
          field: 'amount',
          type: 'currency',
          header: 'Amount',
          width: 135,
        },
        {
          field: 'amount_converted',
          type: 'currency',
          header: 'Amount Converted',
          width: 135,
        },
      ]}
    />
  );
};

const DeductionNestedTable = ({...props}) => {
  return (
    <NestedTableModalRender
      {...props}
      header={{title: 'Adjustments'}}
      field={'deduction'}
      fields={[
        {
          field: 'account_id',
          type: 'autoComplete',
          api: '/accounts',
          suggestionField: 'name',
          valueField: 'name',
          label: 'Account',
        },
        {
          type: 'text',
          field: 'name',
          label: 'Name',
        },
        {
          field: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          type: 'text',
          field: 'reason',
          label: 'Reason',
        },
      ]}
      columns={[
        {
          field: 'account_id.name',
          type: 'text',
          header: 'Account',
          width: 150,
        },
        {
          type: 'text',
          header: 'Name',
          field: 'name',
        },
        {
          field: 'amount',
          type: 'currency',
          header: 'Amount',
          width: 135,
        },
        {
          type: 'text',
          header: 'Reason',
          field: 'reason',
        },
      ]}
    />
  );
};

const InvoiceAmountSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');

  const data = [
    {
      key: 'invoice_amount',
      name: 'Invoice amount',
      amount: values?.invoice_amount || 0,
    },
    {
      key: 'advances',
      name: 'Advances',
      amount: values?.advance_amount || 0,
    },
    {
      key: 'adjustments',
      name: 'Adjustments',
      amount: values?.total_deduction || 0,
    },
    {
      key: 'total',
      name: 'Payment amount',
      amount: values?.total_payment_amount || 0,
    },
  ];
  return (
    <Table
      data={data}
      columns={[
        {
          render: ({row, ...rest}) => {
            const {styles} = rest;
            return <Text style={styles?.groupRowText}>{row?.name}</Text>;
          },
        },
        {
          render: ({row}) => {
            let renderNestedTable = null;
            if (row?.key === 'advances') {
              renderNestedTable = <AdvanceFieldNestedTable {...props} />;
            } else if (row?.key === 'adjustments') {
              renderNestedTable = <DeductionNestedTable {...props} />;
            }
            return renderNestedTable;
          },
        },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = (
              <Text style={styles?.rowText}>{`${row?.amount} INR`}</Text>
            );
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>{`${row?.amount} INR`}</Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

const InvoiceAmountTaxSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');

  const data = [
    {
      key: 'total',
      name: 'Payment amount',
      amount: values?.total_payment_amount || 0,
    },
  ];
  return (
    <Table
      data={data}
      columns={[
        {
          render: ({row, ...rest}) => {
            const {styles} = rest;
            return <Text style={styles?.groupRowText}>{row?.name}</Text>;
          },
        },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = (
              <Text style={styles?.rowText}>{`${row?.amount} INR`}</Text>
            );
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>{`${row?.amount} INR`}</Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

const PaymentComputations = fetch => ({
  base_currency_id: {
    compute: async value => {
      if (value?.organization?._id) {
        const {data: organizationData} = await fetch({
          uri: '/organizations',
          props: {
            filter: {
              _id: value?.organization?._id,
            },
            fields: {_id: 1, currency: {_id: 1, currency: 1}},
            only: true,
          },
        });
        return organizationData?.currency;
      }
    },
    dependencies: ['organization'],
  },
  conversion_rate: {
    compute: async value => {
      if (value?.currency?._id && value.payment_date) {
        const {data: exchangeData} = await fetch({
          uri: '/daywisecurrencies',
          props: {
            filter: {
              currency: value?.currency?._id,
              from_date: {$lte: value.payment_date},
              $or: [{to_date: null}, {to_date: {$gte: value.payment_date}}],
            },
            fields: {_id: 1, exchange_rate: 1},
            only: true,
          },
        });
        return exchangeData?.exchange_rate || 1;
      }
    },
    dependencies: ['currency', 'payment_date'],
  },
  'set vendor details': {
    compute: async value => {
      const {payee_id, payment_date} = value;
      if (payee_id?._id && payment_date) {
        const {data: vendorData} = await fetch({
          uri: `/vendor/taxdetails/${payee_id?._id}`,
          props: {
            date: value.payment_date,
          },
        });
        let {
          product,
          payable_account,
          pre_payment_account,
          location_id,
          organization,
          currency_id,
          vendor_tds_rate,
          vendor_vat_rate,
          vendor_gst_rate,
          pay_from,
        } = vendorData || {};
        const response = {};
        if (currency_id) {
          response.currency = currency_id;
        }
        // if (vendor_vat_rate?.length > 0) {
        //   vendor_vat_rate = vendor_vat_rate[0];
        // }
        // if (vendor_gst_rate?.length > 0) {
        //   vendor_gst_rate = vendor_gst_rate[0];
        // }
        if (vendor_tds_rate?.length > 0) {
          vendor_tds_rate = vendor_tds_rate[0];
        }
        if (payable_account) {
          response.vendor_account = payable_account;
        }
        if (pre_payment_account) {
          response.vendor_advance_account = pre_payment_account;
        }
        if (location_id) {
          response.location = location_id;
        }
        if (product) {
          response.product = product;
        }
        if (organization) {
          response.organization = organization;
        }
        if (pay_from) {
          response.fund_center = pay_from;
        }
        // if (vendor_gst_rate?.igst) {
        //   response.igst_account_id = vendor_gst_rate?.igst;
        // }
        // if (vendor_gst_rate?.cgst) {
        //   response.cgst_account_id = vendor_gst_rate?.cgst;
        // }
        // if (vendor_gst_rate?.sgst) {
        //   response.sgst_account_id = vendor_gst_rate?.sgst;
        // }
        // if (vendor_vat_rate?.account) {
        //   response.vat_account = vendor_vat_rate?.account;
        // }
        if (vendor_tds_rate?.account) {
          response.tds_account = vendor_tds_rate?.account;
        }
        const finalResponse = {
          ...response,
          // vendor_gst_rate,
          vendor_tds_rate,
          // vendor_vat_rate,
        };
        return finalResponse;
      }
    },
    multi: true,
    dependencies: ['payee_id', 'payment_date'],
  },
  total_payment_amount: {
    compute: async value => {
      if (value?.payment_type && value?.payment_type != 'Prepayment') {
        const totalPayemnt =
          (value?.invoice_amount || 0) -
          (value?.total_deduction || 0) -
          (value?.tds_amount || 0) -
          (value?.advance_amount || 0) +
          (value?.interest_amount || 0) +
          (value?.loan_amount || 0) +
          (value?.direct_amount || 0);
        return Number(totalPayemnt.toFixed(2));
      } else {
        const totalPayemnt =
          (value?.invoice_amount || 0) -
          (value?.total_deduction || 0) -
          (value?.other_amount || 0) -
          (value?.tds_amount || 0) +
          (value?.advance_amount || 0) +
          (value?.interest_amount || 0);
        return Number(totalPayemnt.toFixed(2));
      }
    },
    dependencies: [
      'payment_type',
      'invoice_amount',
      'total_deduction',
      'other_amount',
      'tds_amount',
      'advance_amount',
      'interest_amount',
      'direct_amount',
      'loan_amount',
    ],
  },
  base_total_payment_amount: {
    compute: value => {
      if (value.payment_type && value.payment_type != 'Prepayment') {
        const totalPaymentAmount =
          (value.base_invoice_amount || 0) -
          (value.base_total_deduction || 0) -
          (value.tds_amount || 0) -
          (value.base_advance_amount || 0) +
          (value.interest_amount || 0) +
          (value.loan_amount || 0) +
          (value.direct_amount || 0);
        return Number(totalPaymentAmount.toFixed(2));
      } else {
        const totalPaymentAmount =
          (value.base_invoice_amount || 0) -
          (value.base_total_deduction || 0) -
          (value.other_amount || 0) -
          (value.tds_amount || 0) +
          (value.base_advance_amount || 0) +
          (value.interest_amount || 0);
        return Number(totalPaymentAmount.toFixed(2));
      }
    },
    dependencies: [
      'payment_type',
      'base_invoice_amount',
      'base_total_deduction',
      'other_amount',
      'tds_amount',
      'base_advance_amount',
      'advance_amount',
      'interest_amount',
      'direct_amount',
      'loan_amount',
    ],
  },
  'set aggregate amounts': {
    multi: true,
    compute: async value => {
      let paymentAmountBase = 0;
      let paymentAmount = 0;
      let tdsAmount = 0;
      let totalFluctuationAmount = 0;
      let taxAmount = 0;
      const {payment_detail = [], income_tax_lineitems = []} = value;

      if (Array.isArray(payment_detail)) {
        for (const payment of payment_detail) {
          paymentAmountBase += payment.base_amount || 0;
          paymentAmount += payment.amount || 0;
          tdsAmount += payment.tds_amount || 0;
          totalFluctuationAmount += payment.fluctuation_amount || 0;
        }
      }

      if (Array.isArray(income_tax_lineitems)) {
        for (const incometax of income_tax_lineitems) {
          taxAmount += incometax.amount || 0;
        }
      }

      let totalBase = paymentAmountBase + taxAmount;
      let total = paymentAmount + taxAmount;
      return {
        base_invoice_amount: Number(totalBase.toFixed(2)),
        invoice_amount: Number(total.toFixed(2)),
        tds_amount: Number(tdsAmount.toFixed(2)),
        total_fluctuation_amount: Number(totalFluctuationAmount.toFixed(2)),
        is_fluctuation_amount: totalFluctuationAmount != 0 ? true : false,
      };
    },
    dependencies: ['payment_detail', 'income_tax_lineitems'],
  },

  base_advance_amount: {
    compute: value => {
      const advanceAmount =
        (value.advance_amount || 0) * (value.conversion_rate || 0);
      return Number(advanceAmount.toFixed(2));
    },
    dependencies: ['advance_amount', 'conversion_rate'],
  },
  advance_amount: {
    compute: value => {
      const advanceAmount = value.advance.reduce(
        (accum, doc) => accum + doc.amount,
        0,
      );
      return Number(advanceAmount.toFixed(2));
    },
    dependencies: ['advance'],
  },
  total_deduction: {
    multi: true,
    compute: value => {
      if (value?.deduction) {
        const deductionAmount = value.deduction.reduce(
          (accum, doc) => accum + doc.amount,
          0,
        );
        return {
          total_deduction: Number(deductionAmount.toFixed(2)),
          base_total_deduction: Number(
            (
              Number(deductionAmount.toFixed(2)) * (value.conversion_rate || 0)
            ).toFixed(2),
          ),
        };
      }
    },
    dependencies: ['deduction', 'conversion_rate'],
  },
  'set bank account against location': {
    multi: true,
    compute: async value => {
      const {data: locationFundCenter} = await fetch({
        uri: '/offices',
        props: {
          fields: {
            _id: 1,
            fund_center: {_id: 1, name: 1},
          },
          filter: {
            _id: value?.location,
          },
          only: true,
        },
      });
      // if (locationFundCenter?._id) {
      //   return {
      //     fund_center: locationFundCenter?.fund_center,
      //   };
      // }
    },
    dependencies: ['location'],
  },
  pendingInvoices: {
    multi: true,
    dependencies: [
      'payee_id',
      'payment_type',
      'location',
      'organization',
      'invoicetype',
      'from_date',
      'to_date',
      'transfer_mode',
      'is_multiple_vendor',
      'salary_type',
    ],
    compute: async value => {
      const {
        payee_id,
        is_multiple_vendor,
        payment_type,
        conversion_rate = 1,
      } = value;
      let paymentDetails = [];
      if (!is_multiple_vendor) {
        //we dont have this field in the form right now, the entire is multiple vendor part incase of bill type is missing
        if (!payee_id) {
          return null;
        }
        const {data: vendorInvoiceResult} = await fetch({
          uri: '/vendorinvoices/all',
          props: {
            fields: {
              _id: 1,
              invoice_no: 1,
              due_date: 1,
              payable_amount: 1,
              conversion_rate: 1,
              pending_amount: 1,
              product: {_id: 1, name: 1},
              payee_id: {_id: 1, name: 1},
              tds_amount: 1,
            },
            filter: {
              pending_amount: {$gt: 0},
              expense_type: {$ne: 'Provisions'},
              hold_salary: {$in: [null, false]},
              payee_id: value.payee_id._id,
            },
          },
        });
        if (!payment_type || payment_type != 'Bill' || !payee_id) {
          paymentDetails = [];
        } else {
          paymentDetails = vendorInvoiceResult;
        }
      } else if (is_multiple_vendor) {
        const filter = {};
        if (value?.salary_type === 'Attendance Base') {
          filter['monthly_attendance_id'] = {$exists: true};
        }
        if (value?.salary_type === 'Performance Base') {
          filter['performance_id'] = {$exists: true};
        }
        const {data: vendorInvoiceResult} = await fetch({
          uri: '/vendorinvoices/all',
          props: {
            fields: {
              _id: 1,
              invoice_no: 1,
              conversion_rate: 1,
              location: {_id: 1, name: 1},
              pending_amount: 1,
              payee_id: {_id: 1, name: 1},
            },
            filter: {
              pending_amount: {$gt: 0},
              expense_type: {$ne: 'Provisions'},
              hold_salary: {$in: [null, false]},
              invoice_type: value?.invoicetype,
              transfer_mode: value?.transfer_mode,
              location: value?.location,
              invoice_date: {
                $gte: value.from_date,
                $lte: value.to_date,
              },
              organization: value?.organization,
              ...filter,
            },
          },
        });
        if (
          !payment_type ||
          payment_type != 'Bill' ||
          !value?.invoicetype ||
          !value?.to_date ||
          !value?.from_date
        ) {
          paymentDetails = [];
        } else {
          paymentDetails = vendorInvoiceResult;
        }
      }

      let finalArray = [];
      if (paymentDetails && paymentDetails.length > 0) {
        finalArray = paymentDetails.map((vendorInvoice, index) => {
          const lineitem = {
            invoice_id: {
              _id: vendorInvoice._id,
              invoice_no: vendorInvoice.invoice_no,
            },
            amount:
              (vendorInvoice.pending_amount &&
                Number(vendorInvoice.pending_amount.toFixed(2))) ||
              0,
            base_amount:
              (vendorInvoice.pending_amount &&
                Number(
                  vendorInvoice.pending_amount.toFixed(2) * conversion_rate,
                )) ||
              0,
            base_invoice_amount:
              (vendorInvoice.pending_amount &&
                Number(
                  vendorInvoice.pending_amount.toFixed(2) *
                    vendorInvoice.conversion_rate,
                )) ||
              0,
            product: vendorInvoice.product,
            payee_id: vendorInvoice.payee_id,
            invoice_conversion_rate: vendorInvoice.conversion_rate,
          };
          if (lineitem.base_amount && lineitem.base_invoice_amount) {
            lineitem.fluctuation_amount =
              lineitem.base_amount - lineitem.base_invoice_amount;
          }
          return lineitem;
        });
      }
      return {payment_detail: finalArray};
    },
  },
  pendingTaxDetails: {
    multi: true,
    dependencies: [
      "from_date",
      "to_date",
      "account_tax",
      "type_tax"
    ],
    compute: async value => {
      const {
        to_date,
        from_date,
        account_tax,
        type_tax,
      } = value;
      if (to_date && from_date && account_tax && type_tax) {
        const { data: voucherLineItemResult } = await fetch({
          uri: '/voucherlineitems',
          props: {
            fields: {
              _id: 1,
              date: 1,
              cr_amount: 1,
              dr_amount: 1,
              account: { _id: 1, name: 1 },
              entity: { _id: 1, name: 1 },
              product: { _id: 1, name: 1 },
              organization: { _id: 1, name: 1 },
            },
            filter: {
              is_paid: { $exists: false },
              date: { $gte: from_date, $lte: to_date },
              account: account_tax?._id,
              cr_amount: type_tax === "c" ? { $gt: 0 } : { $in: [null, 0] },
              dr_amount: type_tax === "d" ? { $gt: 0 } : { $in: [null, 0] }
            },
          },
        });
        let finalArray = [];
        if (voucherLineItemResult && voucherLineItemResult.length > 0) {
          finalArray = voucherLineItemResult.map((voucherItem, index) => {
            const { account, entity, date, product, cr_amount, dr_amount } = voucherItem
            console.log("account>>>>>>>>.",account)
            const lineitem = {
              account_id: account,
              vld: voucherItem?._id,
              amount: cr_amount ? cr_amount : dr_amount ? dr_amount : 0,
              type: type_tax,
              date: date,
              product: product,
              entity: entity,
            };
            return lineitem;
          });
        }
        return { income_tax_lineitems: finalArray };
      }
    },
  },
  populate_advance: {
    multi: true,
    compute: async value => {
      if (value && value.payment_type && value.payment_type === 'Bill') {
        if (value && value.payee_id) {
          const {data: advancePaymentResult} = await fetch({
            uri: '/payments',
            props: {
              fields: {
                _id: 1,
                payment_no: 1,
                conversion_rate: 1,
                base_advance_amount_pending: 1,
                advance_amount_pending: 1,
                advance: 1,
              },
              filter: {
                payment_type: 'Prepayment', //_id check?
                advance_amount_pending: {$gt: 5},
                payee_id: value?.payee_id,
              },
            },
          });
          console.log(
            'advancePaymentResult>>>>>>>>>>>>>',
            advancePaymentResult,
          );
          if (advancePaymentResult && advancePaymentResult.length > 0) {
            let sum = 0;
            const details = advancePaymentResult.map(payment => {
              const advanceAmtLineItem = {
                payment_id: {_id: payment._id, payment_no: payment.payment_no},
                amount: payment.advance_amount_pending,
                amount_converted:
                  payment.advance_amount_pending * value.conversion_rate,
              };
              sum = payment.advance_amount_pending + sum;
              return advanceAmtLineItem;
            });
            console.log('details>>>>>>>>>>>>>', details);
            if (details && details.length) {
              return {
                advance: details,
                advance_amount: Number(sum.toFixed(2)),
              };
            } else return {advance: [], advance_amount: 0};
          }
        }
      } else {
        return {advance: [], advance_amount: 0};
      }
    },
    dependencies: ['payee_id', 'payment_type'],
  },
  nestedComputations: {
    advance: {
      amount_converted: {
        multi: true,
        compute: (values, {_parentValues}) => {
          if (_parentValues && _parentValues.conversion_rate && values.amount) {
            return {
              amount_converted: _parentValues.conversion_rate * values.amount,
            };
          }
        },
        dependencies: ['amount', '_parentValues.conversion_rate'],
      },
    },
    payment_detail: {
      base_amount: {
        multi: true,
        compute: (values, {_parentValues}) => {
          let baseAmount = 0;
          let baseInvoiceAmount = 0;
          if (values.invoice_conversion_rate && values.amount) {
            baseInvoiceAmount = values.amount * values.invoice_conversion_rate;
          }
          if (_parentValues.conversion_rate && values.amount) {
            baseAmount = values.amount * _parentValues.conversion_rate;
          }
          const fluctuationAmount = baseAmount - baseInvoiceAmount;

          return {
            base_amount: baseAmount,
            base_invoice_amount: baseInvoiceAmount,
            fluctuation_amount: fluctuationAmount,
          };
        },
        dependencies: ['amount', '_parentValues.conversion_rate'],
      },
    },
  },
});

const VendorPaymentForm = props => {
  const {
    route: {params = {}},
  } = props;

  const {row} = params;

  const {GreenBackGroundAdd} = useTheme('icons');

  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'vendorPayment',
    uri: '/payments',
  });
  let currentDate = getZeroTimeDate(new Date());
  const defaultData = {
    conversion_rate: 1,
    payment_type: 'Bill',
    approval_status: 'new',
    payment_date: currentDate,
    product: employeeData?.product,
    organization: employeeData?.organization,
    location: employeeData?.accounting_location, //no accounting location in employeedata and no accounting organization
    base_currency_id: employeeData?.organization?.currency,
    currency_id: employeeData?.organization?.currency,
  };
  return (
    <Form
      onSubmit={onSubmit}
      api={`/payments/details/${row?._id}`}
      submitAction={'save'}
      defaultValues={defaultData}
      computations={{...PaymentComputations(fetch)}}
      layoutFields={[
        {
          label: 'Type',
          fields: [
            {
              type: 'radioGroup',
              field: 'payment_type',
              options: [
                {label: 'Pre-payment', value: 'Prepayment'},
                {label: 'Post-payment', value: 'Bill'},
                {label: 'Loan', value: 'Loan'},
                {label: 'Direct', value: 'Direct'},
                {label: 'Other', value: 'Other'},
                {label : 'Tax Payment' , value : 'Tax Payment'}
              ],
              required: true,
            },
            {
              api: '/vendors',
              field: 'payee_id',
              label: 'Vendor',
              valueField: 'name',
              suggestionField: 'name',
              type: 'autoComplete',
              size: 6,
              filter: ({ values }) => {
                if(values?.organization?._id){
                  return { organization: values.organization._id }
                }
              },
              visible: ({values}) => values?.is_multiple_vendor || values?.payment_type === "Tax Payment",
            },
            {
              api: '/vendors',
              field: 'payee_id',
              label: 'Vendor',
              valueField: 'name',
              suggestionField: 'name',
              type: 'autoComplete',
              size: 6,
              required: true,
              filter: ({values}) => {
                if(values?.organization?._id){
                  return { organization: values.organization._id }
                }
              },
              visible: ({values}) => !values?.is_multiple_vendor && values?.payment_type != "Tax Payment",
            },
            {
              field: 'payment_date',
              type: 'date',
              label: 'Payment Date',
              size: 6,
              required: true,
            },
            {
              type: 'checkbox',
              label: 'Is Multiple Vendor',
              field: 'is_multiple_vendor',
              visible: ({values}) => values?.payment_type == 'Bill',
              size: 6,
            },
          ],
        },
        {
          fields: [
            {
              field: 'document',
              type: 'file',
              options: {bucketName: 'manaze'},
            },
          ],
        },
        {
          // visible: ({values}) => values?.is_multiple_vendor,
          fields: [
            {
              field: 'from_date',
              type: 'date',
              label: 'From',
              size: 6,
              visible: ({values}) => values?.is_multiple_vendor || values?.payment_type === "Tax Payment",
            },
            {
              field: 'to_date',
              type: 'date',
              label: 'To',
              size: 6,
              visible: ({values}) => values?.is_multiple_vendor || values?.payment_type === "Tax Payment",
            },
            {
              api: '/accounts',
              field: 'account_tax',
              label: 'Account Tax',
              valueField: 'name',
              suggestionField: 'name',
              type: 'autoComplete',
              size: 6,
              visible: ({ values }) => values?.payment_type === "Tax Payment",
            },
            {
              type: 'autoComplete',
              field: 'type_tax',
              label: 'Tax Type',
              options: [
                {label: 'Credit', value: 'c'},
                {label: 'Debit', value: 'd'},
              ],
              size: 6,
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              visible: ({ values }) => values?.payment_type === "Tax Payment",
            },
            {
              field: 'invoicetype',
              label: 'Invoice Type',
              type: 'autoComplete',
              options: ['Expense', 'Salary'],
              size: 4,
              visible: ({values}) => values?.is_multiple_vendor,
            },
            {
              field: 'transfer_mode',
              label: 'Transfer Mode',
              type: 'autoComplete',
              options: ['Account Transfer', 'By Cheque'],
              visible: ({values}) => values?.is_multiple_vendor,
              size: 4,
            },
            {
              field: 'salary_type',
              label: 'Salary Type',
              type: 'autoComplete',
              options: ['Attendance Base', 'Performance Base'],
              visible: ({values}) =>
                values?.is_multiple_vendor && values?.invoicetype === 'Salary',
              size: 4,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Bill',
          label: 'Payment Details',
          fields: [
            {
              field: 'payment_detail',
              nested: true,
              render: props => {
                return (
                  <NestedTable
                    {...props}
                    selection={{
                      deleteAction: true,
                    }}
                    // inlineAddForm
                    fields={[
                      {
                        api: '/vendorinvoices/all',
                        field: 'invoice_id',
                        label: 'Invoice',
                        valueField: 'invoice_no',
                        suggestionField: 'invoice_no',
                        required: true,
                        type: 'autoComplete',
                        size: 6,
                      },
                      {
                        type: 'number',
                        field: 'amount',
                        label: 'Amount',
                      },
                      {
                        type: 'number',
                        field: 'tds_amount',
                        label: 'TDS Amount',
                      },
                    ]}
                    columns={[
                      {
                        header: 'Invoice',
                        field: 'invoice_id.invoice_no',
                        type: 'text',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        label: 'Amount',
                      },
                      {
                        header: 'TDS Amount',
                        field: 'tds_amount',
                        type: 'number',
                        label: ' TDS Amount',
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Tax Payment',
          label: 'Tax Details',
          fields: [
            {
              field: 'income_tax_lineitems',
              nested: true,
              render: props => {
                return (
                  <NestedTable
                    {...props}
                    selection={{
                      deleteAction: true,
                    }}
                    // inlineAddForm
                    fields={[
                      {
                        api: '/accounts',
                        field: 'account_id',
                        label: 'Account',
                        valueField: 'name',
                        suggestionField: 'name',
                        required: true,
                        type: 'autoComplete',
                        size: 6,
                      },
                      {
                        type: 'autoComplete',
                        field: 'type',
                        label: 'Type',
                        options: [
                          {label: 'Credit', value: 'c'},
                          {label: 'Debit', value: 'd'},
                        ],
                        size: 6,
                        suggestionField: 'label',
                        keyField: 'value',
                        valueField: 'label',
                      },
                      {
                        type: 'number',
                        field: 'amount',
                        label: 'Amount',
                      },
                      {
                        type: 'date',
                        field: 'date',
                        label: 'Date',
                      },
                    ]}
                    columns={[
                      {
                        header: 'Account',
                        field: 'account_id.name',
                        type: 'text',
                      },
                      {
                        type: 'autoComplete',
                        field: 'type',
                        label: 'Type',
                        options: [
                          {label: 'Credit', value: 'c'},
                          {label: 'Debit', value: 'd'},
                        ],
                        size: 6,
                        suggestionField: 'label',
                        keyField: 'value',
                        valueField: 'label',
                      },
                      {
                        header: 'Amount',
                        field: 'amount',
                        type: 'number',
                        label: 'Amount',
                      },
                      {
                        type: 'date',
                        field: 'date',
                        label: 'Date',
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Tax Payment',
          render: (_, props) => {
            return <InvoiceAmountTaxSummary {...props} />;
          },
        },
        {
          visible: ({values}) => values?.payment_type == 'Bill',
          render: (_, props) => {
            return <InvoiceAmountSummary {...props} />;
          },
        },
        {
          visible: ({values}) => values?.payment_type == 'Prepayment',
          label: 'Payment Details',
          fields: [
            {
              type: 'number',
              field: 'advance_amount',
              label: 'Amount',
            },
            {
              type: 'number',
              field: 'total_payment_amount',
              label: 'Amount',
              editable: false,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Loan',
          label: 'Payment Details',
          fields: [
            {
              field: 'loan_account',
              label: 'Loan Account',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              type: 'autoComplete',
              size: 6,
            },
            {
              type: 'number',
              field: 'loan_amount',
              label: 'Loan Amount',
              size: 6,
            },
            {
              field: 'interest_account_id',
              label: 'Interest Account',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              type: 'autoComplete',
              size: 6,
            },
            {
              type: 'number',
              field: 'interest_amount',
              label: 'Interest Amount',
              size: 6,
            },
            {
              type: 'number',
              field: 'total_payment_amount',
              label: 'Amount',
              editable: false,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Direct',
          label: 'Payment Details',
          fields: [
            {
              field: 'direct_account',
              label: 'Direct Account',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              type: 'autoComplete',
              size: 6,
            },
            {
              type: 'number',
              field: 'direct_amount',
              label: 'Direct Amount',
              size: 6,
            },
            {
              type: 'number',
              field: 'total_payment_amount',
              label: 'Amount',
              editable: false,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Other',
          label: 'Payment Details',
          fields: [
            {
              api: '/accounts',
              field: 'loan_account',
              label: 'Other Account',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              type: 'autoComplete',
              size: 6,
            },
            {
              type: 'number',
              field: 'loan_amount',
              label: 'Other Amount',
              size: 6,
            },
            {
              type: 'number',
              field: 'total_payment_amount',
              label: 'Amount',
              editable: false,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Prepayment',
          groups: [
            {
              label: 'TDS',
              fields: [
                {
                  api: '/accounts',
                  field: 'tds_account',
                  label: 'TDS Account',
                  valueField: 'name',
                  suggestionField: 'name',
                  required: true,
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'tds_amount',
                  label: 'TDS Amount',
                  size: 6,
                },
              ],
            },
            {
              label: 'Other Deduction',
              fields: [
                {
                  api: '/accounts',
                  field: 'other_account',
                  label: 'Other Account',
                  valueField: 'name',
                  suggestionField: 'name',
                  required: true,
                  type: 'autoComplete',
                  size: 6,
                },
                {
                  type: 'number',
                  field: 'other_amount',
                  label: 'Amount',
                  size: 6,
                },
              ],
            },
          ],
        },
        {
          label: 'Expense Details',
          fields: [
            {
              api: '/currencies',
              field: 'currency',
              label: 'Currency',
              valueField: 'currency',
              suggestionField: 'currency',
              required: true,
              type: 'autoComplete',
              size: 4,
            },
            {
              type: 'number',
              label: 'Rate',
              field: 'conversion_rate',
              required: true,
              size: 4,
            },
          ],
        },

        {
          label: 'Payment Method',
          fields: [
            {
              type: 'radioGroup',
              field: 'is_payment_mode_online',
              options: [
                {label: 'Online', value: 'online'},
                {label: 'Offline', value: 'offline'},
              ],
              required: true,
            },
          ],
        },
        {
          field: 'payment_mode',
          label: 'Payment mode',
          type: 'autoComplete',
          options: ['CHEQUE', 'CASH', 'RTGS', 'NEFT', 'ONLINE', 'INTRABANK'],
          required: true,
          size: 3,
        },
        {
          api: '/banks',
          field: 'fund_center',
          label: 'Bank Account',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
          titleField: 'name',
          type: 'autoComplete',
          size: 3,
        },
        {
          type: 'text',
          field: 'transaction_id',
          label: 'Transaction Id',
          size: 3,
          // required: true,
        },
        {
          label: 'Category',
          type: 'autoComplete',
          field: 'category',
          api: '/categories',
          valueField: 'name',
          suggestionField: 'name',
          required: true,
          size: 3,
        },
        {
          groups: [
            {
              label: 'Description',
              fields: [
                {
                  type: 'textArea',
                  field: 'purpose',
                  label: 'Description',
                },
              ],
            },
            {
              label: 'Accounting',
              fields: [
                {
                  field: 'organization',
                  api: '/organizations',
                  type: 'autoComplete',
                  label: 'Organization',
                  searchField: 'name',
                  suggestionField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  field: 'product',
                  api: '/products',
                  type: 'autoComplete',
                  label: 'Product',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  field: 'location',
                  api: '/offices',
                  type: 'autoComplete',
                  label: 'Tax Location',
                  valueField: 'name',
                  suggestionField: 'name',
                  required: true,
                  size: 4,
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const AddVendorPaymentFrom = props => {
  return <VendorPaymentForm header={'Create Payment'} {...props} />;
};

export const EditVendorPaymentForm = props => {
  return <VendorPaymentForm header={'Edit Payment'} mode="edit" {...props} />;
};
