import React from 'react';
import {TableHeader} from '../../../../components/table/Headers';
import {GroupFilter, SearchFilter} from '../../../../components/filter/Filters';
import {useFilter} from '../../../../controllers/useFilter';
import {SplitScreen} from '../../../../components/SplitScreen';
import {AssetColumns, assetFilters} from './AssetUtility';
import {ApiEndpointDetailForm} from './ApiEndPointForm';
import {ExportExcelAction, useAssetActions} from './AssetUtility';
import {
  PROJECT_CONTROLLER_IMPORT_SAMPLE_ID,
  PROJECT_CONTROLLER_TYPE_ID,
} from '../../../common/constants/SourceConstants';
import {TabView} from '../../../../components/tab';
import {AllFeatureApiEndpointScreen} from './FeatureApiEndpointsScreen';
import {useTheme} from '@unthinkable/react-theme';
import {AITable} from '../../../../components/table/AITable';
import {AISuggestionRender} from '../../cellrenders/AISuggestionRenders';
import {BorderLessButton} from '../../../../components/button/AddButton';
import {useGroupBy} from '../../../../controllers/useGroupBy';
import {GroupBy} from '../../../../components/table/GroupBy';
import {GroupContentItem} from '../../../../components/card/GroupContentItem';
import {Table} from '../../../../components/table/Table';

export const ApiEndpointsControllerHeader = ({
  params,
  searchValue,
  onChangeFilter,
  filterValues,
  applyFilter,
  selectedIds,
  excelParams,
  navigation,
  groupBy,
}) => {
  const {project, module_id, fromDataEntity} = params;
  const filters = assetFilters({project});
  const {icons} = useTheme();

  return (
    <TableHeader
      actions={
        fromDataEntity
          ? null
          : [
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  !module_id && filters.module,
                  filters.dataEntity,
                  filters.method,
                  filters.owner,
                ]}
              />,
              !module_id && <GroupBy {...groupBy} />,
              <BorderLessButton
                onPress={() => {
                  navigation.navigate('project-import-data', {
                    ...params,
                    source: PROJECT_CONTROLLER_TYPE_ID,
                    sampleId: PROJECT_CONTROLLER_IMPORT_SAMPLE_ID,
                  });
                }}
                text={'Import'}
                icon={icons.DownloadBlue}
              />,
              <ExportExcelAction
                selectedIds={selectedIds}
                params={excelParams}
              />,
            ]
      }
    />
  );
};

export const apiEndpointExportParams = ({
  addOnFilter,
  search,
  searchFields,
  fromFeature,
  params: parameters,
  ...params
}) => {
  const remarksKey = fromFeature ? 'remarks' : 'released_version_id.remarks';
  // update this work
  return {
    addOnFilter,
    search,
    searchFields,
    params: parameters,
    excelParams: {
      ...params,
      filename: 'Apis',
      columns: [
        {header: 'Name', key: 'controller', width: 15},
        {header: 'Endpoint', key: 'uri', width: 15},
        {header: 'Method', key: 'method', width: 15},
        {header: 'Model', key: 'model_id.model', width: 15},

        // REQUEST
        // {header: 'req_header', key: 'req_header', width: 15}, // not in form
        // {header: 'Request Body Type', key: 'requests', width: 15},
        // {header: 'parameters', key: 'parameters', width: 15},

        // RESPONSE
        // {header: 'resp_header', key: 'resp_header', width: 15}, // not in form
        // {header: 'Response Type', key: 'responses', width: 15},
        // {header: 'resp_format', key: 'resp_format', width: 15},

        {header: 'Authentication', key: 'security_type', width: 15},
        {header: 'Owner', key: 'owner_id.name', width: 15},
        {header: 'Description', key: 'desc', width: 20},
        {header: 'Remarks', key: remarksKey, width: 50, isHtml: true},
      ],
    },
  };
};

export const ApiEndPointsTable = props => {
  const {
    navigation,
    route: {params},
    fromAllView,
    status,
    selectedId,
  } = props;
  const {colors} = useTheme();
  const {fromDataEntity, feature, module} = params;
  const {groupBy, ...restProps} = props;

  const {
    assetDetail,
    mergeReferences,
    changeModule,
    changeDataEntity,
    deleteAsset,
    deleteMultipleAssets,
    versions,
    updateModuleMultipleAssets,
    updateDataEntityMultipleAssets,
  } = useAssetActions({
    navigation,
    params,
    source: PROJECT_CONTROLLER_TYPE_ID,
    fromReleased: status === 'released',
  });

  const assetColumns = AssetColumns({
    navigation,
    params,
    fromAllView,
    api: '/projectControllers',
    source: PROJECT_CONTROLLER_TYPE_ID,
  });

  const {allowDynamicGrouping} = groupBy || {};

  let TableComponent = Table;
  // if (module) {
  // TableComponent = AITable;
  // }

  return (
    <TableComponent
      {...restProps}
      allowDynamicGrouping={allowDynamicGrouping}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      // secondaryApiProps={
      //   feature?._id && {
      //     api: `/projectControllers/ai`,
      //     params: {parent_entity: 'Module', parent_entity_id: module?._id},
      //     eventSourceId: 'ApiEndpointAi',
      //   }
      // }
      selection={
        fromDataEntity
          ? null
          : {
              actions: [
                updateModuleMultipleAssets,
                updateDataEntityMultipleAssets,
                {
                  title: 'Generate Swagger docs',
                  onPress: ({selectedIds, resetSelection}) => {
                    navigation.navigate('swagger-config', {
                      ...params,
                      selectedIds,
                    });
                    resetSelection?.();
                  },
                },
                deleteMultipleAssets,
              ],
            }
      }
      eventSourceId={['Controller', 'ApiEndpointAi', 'MapUserstory']}
      columns={[
        !selectedId && !allowDynamicGrouping && assetColumns.module,
        {
          colspan: ({row}) => (row.aiGenerated ? 6 : void 0),
          header: 'Name',
          render: _props => {
            const {row} = _props;
            let primaryTitleColor = void 0;

            if (!row?.aiGenerated && feature?._id && !row.in_development) {
              primaryTitleColor = colors.NEUTRAL_MEDIUM;
            }
            return (
              <AISuggestionRender
                {..._props}
                params={params}
                primaryField="controller"
                secondaryField="desc"
                primaryTitleColor={primaryTitleColor}
                entityName="ProjectController"
                eventSourceId="ApiEndpointAi"
              />
            );
          },
        },
        assetColumns.apiUri,
        assetColumns.apiMethod,
        !selectedId && assetColumns.dataEntity,
        !selectedId && assetColumns.userstory_count,
        !selectedId && assetColumns.api_services_count,
        !selectedId && assetColumns.owner,
        !selectedId && assetColumns.code_editor,
      ]}
      onRowPress={assetDetail}
      moreActions={() =>
        fromDataEntity
          ? null
          : [
              versions,
              changeModule,
              changeDataEntity,
              mergeReferences({
                targetApi: `/projectcontrollers`,
              }),
              {
                title: 'Generate Code',
                visible: ({row}) => row?.folder_path_id && row?.file_name,
                onPress: ({row}) => {
                  navigation.navigate('api-endpoint-code-editor', {
                    ...params,
                    row,
                    readOnly: !feature || feature.status === 'released',
                  });
                },
              },
              deleteAsset,
            ]
      }
    />
  );
};

export const ApiEndpointScreen = props => {
  const {
    route: {params},
    groupBy,
  } = props;

  const {project, module_id, fromDataEntity, dataEntity} = params;

  const filterProps = useFilter();
  const {searchValue, filterValues} = filterProps;

  let addOnFilter = fromDataEntity
    ? {model_id: dataEntity?._id}
    : {project_id: project?._id, module_id};

  const tableProps = {
    api: `/projectcontrollers/released`,
    addOnFilter: {
      ...addOnFilter,
      ...filterValues.filter,
    },
    search: searchValue,
    searchFields: 'controller',
  };

  const renderHeader = props => {
    return (
      <ApiEndpointsControllerHeader
        {...props}
        params={params}
        groupBy={groupBy}
        {...filterProps}
        excelParams={apiEndpointExportParams(tableProps)}
      />
    );
  };

  return (
    <SplitScreen
      sideScreenWidth={'50%'}
      splitScreen={<ApiEndpointDetailForm {...props} />}>
      <ApiEndPointsTable
        {...props}
        groupBy={groupBy}
        renderHeader={renderHeader}
        status={'released'}
        {...tableProps}
      />
    </SplitScreen>
  );
};

export const ApiEndpointsTab = props => {
  const {route: {params} = {}} = props;
  const {project, module} = params;

  let groupBy = useGroupBy({
    defaultSelected: module?._id ? void 0 : 'module_id',
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          exclusiveExpand: true,
          defaultExpandedIndex: 0,
          leftContent: ({row}) => {
            const moduleName = row?.module || 'No module';
            return <GroupContentItem value={moduleName + ` (${row.count})`} />;
          },
        },
      },
    ],
  });

  return (
    <TabView
      {...props}
      tabs={{
        released: {
          label: 'Released',
          view: <ApiEndpointScreen groupBy={groupBy} {...props} />,
          api: '/projectcontrollers',
          eventSourceId: 'Controller',
          filter: {
            status: 'released',
            project_id: project?._id,
            module_id: module?._id,
          },
        },
        all: {
          label: 'In Development',
          view: (
            <AllFeatureApiEndpointScreen
              api={`/projects/${project?._id}/asset/${PROJECT_CONTROLLER_TYPE_ID}/all-data`}
              groupBy={groupBy}
              {...props}
            />
          ),
          params: {module_id: module?._id},
          api: `/projects/${project?._id}/asset/${PROJECT_CONTROLLER_TYPE_ID}/in-development/count`,
          eventSourceId: 'Controller',
        },
      }}
    />
  );
};
