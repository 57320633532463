import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Image, Text, View} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {Form} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {Table} from '../../../components/table/Table';
import {useFetchData} from '../../../controllers/useFetchData';
import {useInvoke} from '../../../controllers/useInvoke';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const columnTextStyle = {
  fontFamily: 'Roboto-Bold',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#4276EC',
};

const columnFooterStyle = {
  fontFamily: 'Quicksand-Bold',
  fontSize: '12px',
  lineHeight: '15px',
  color: '#8E8E8E',
};

const InvoiceAmountSummary = props => {
  const {values} = props;
  const {INFORMATION_HIGH} = useTheme('colors');

  const {
    exp_amount = 0,
    total_tax_amount = 0,
    payable_amount = 0,
    base_payable_amount = 0,
  } = values;

  const data = [
    {
      // key: 'invoice_amount',
      name: 'Net Amount',
      amount: `${parseFloat(exp_amount || 0).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      // key: 'total_tax_amount',
      name: 'Tax Amount',
      amount: `${parseFloat(total_tax_amount || 0).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      // key: 'advance_details',
      name: 'Gross Amount',
      amount: `${parseFloat(payable_amount).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      // key: 'deduction',
      name: 'Gross Amount(BC)',
      amount: `${parseFloat(base_payable_amount).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
  ];
  return (
    <Table
      data={data}
      columns={[
        {
          render: ({row, ...rest}) => {
            const {styles} = rest;
            return <Text style={styles?.groupRowText}>{row?.name}</Text>;
          },
        },
        // {
        //   render: ({row}) => {
        //     let renderNestedTable = null;
        //     if (row?.key === 'advance_details') {
        //       renderNestedTable = <AdvanceFieldNestedTable {...props} />;
        //     } else if (row?.key === 'deduction') {
        //       renderNestedTable = <DeductionNestedTable {...props} />;
        //     } else if (row?.key === 'CreditNote') {
        //       renderNestedTable = <CreditNoteNestedTable {...props} />;
        //     }
        //     return renderNestedTable;
        //   },
        // },
        {
          align: 'right',
          field: 'amount',
          render: ({row, ...rest}) => {
            const {styles} = rest;
            let amount = <Text style={styles?.rowText}>{row?.amount}</Text>;
            if (row?.key === 'total') {
              amount = (
                <Text
                  style={{
                    ...styles?.rowText,
                    color: INFORMATION_HIGH,
                  }}>
                  {row?.amount}
                </Text>
              );
            }
            return amount;
          },
        },
      ]}
    />
  );
};

const amountFooterColumns = ({values = {}}) => {
  const {exp_details = [], exp_amount = 0} = values;
  let totalTaxAmount = 0;
  let netAmount = 0;
  let grossAmount = 0;
  if (exp_details.length) {
    exp_details.map(item => {
      let {purchase_amount = 0, expense_tax = []} = item;
      grossAmount += purchase_amount;
      if (expense_tax.length) {
        expense_tax.map(innerItem => {
          let {amount = 0} = innerItem;
          totalTaxAmount += amount;
        });
      }
    });
  }

  netAmount += totalTaxAmount + grossAmount;

  return [
    {
      label: 'Net Amount',
      value: `${parseFloat(netAmount || 0).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      label: 'Tax Amount',
      value: `${parseFloat(totalTaxAmount || 0).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      label: 'Gross Amount',
      value: `${parseFloat(exp_amount).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
    {
      label: 'Gross Amount(BC)',
      value: `${parseFloat(exp_amount).toFixed(2)} ${
        (values?.currency_id && values?.currency_id.currency) || 'INR'
      }`,
    },
  ];
};

const InvoiceAmountDetailFooter = props => {
  let {rows, ...restProps} = props;
  if (rows && typeof rows === 'function') {
    rows = rows(restProps);
  }
  if (!rows || !rows.length) {
    return null;
  }
  return (
    <View style={{flexDirection: 'row'}}>
      <View style={{flex: 1}}>
        {rows.map(item => {
          const {
            visible = true,
            label,
            value,
            addAction,
            separator = '-',
            labelTextStyle = {},
            valueTextStyle = {},
          } = item;
          if (!visible) {
            return null;
          }
          return (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                overflow: 'hidden',
                paddingBottom: 8,
              }}>
              <Text
                style={{
                  ...columnFooterStyle,
                  paddingLeft: 3,
                  paddingRight: 3,
                  ...labelTextStyle,
                }}>
                {label}
              </Text>
              {addAction}
              <Text
                style={{
                  ...columnFooterStyle,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}>
                {separator}
              </Text>
              <Text
                style={{
                  width: 100,
                  ...columnFooterStyle,
                  color: '#4276EC',
                  textAlign: 'right',
                  numeberOfLines: 1,
                  ...valueTextStyle,
                }}>
                {value}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const InvoiceComputations = fetch => ({
  base_currency_id: {
    compute: async value => {
      if (value?.organization?._id) {
        const {data: organizationData} = await fetch({
          uri: '/organizations',
          props: {
            filter: {
              _id: value?.organization?._id,
            },
            fields: {_id: 1, currency: {_id: 1, currency: 1}},
            only: true,
          },
        });
        return organizationData?.currency;
      }
    },
    dependencies: ['organization'],
  },
  invoice_type: {
    dependencies: ['payment_type'],
    multi: true,
    compute: value => {
      if (value?.payment_type == 'Asset') {
        return {
          invoice_type: 'Asset',
          expense_type: 'Asset Inventory',
        };
      } else {
        return {
          invoice_type: 'Expense',
          expense_type: 'General Expense',
        };
      }
    },
  },
  expense_type:{
    dependencies : ['book_inventory'],
    multi : true,
    compute: value => {
      if (value?.book_inventory) {
        return {
          expense_type: 'Asset Inventory',
        };
      } 
    },
  },
  conversion_rate: {
    compute: async value => {
      if (value?.currency?._id && value.invoice_date) {
        const {data: exchangeData} = await fetch({
          uri: '/daywisecurrencies',
          props: {
            filter: {
              currency: value?.currency?._id,
              from_date: {$lte: value.invoice_date},
              $or: [{to_date: null}, {to_date: {$gte: value.invoice_date}}],
            },
            fields: {_id: 1, exchange_rate: 1},
            only: true,
          },
        });
        return exchangeData?.exchange_rate || 1;
      }
    },
    dependencies: ['currency', 'invoice_date'],
  },
  approval_limit: {
    multi: true,
    compute: async value => {
      if (value?.expense_approved_by?._id) {
        const {data: approverLimit} = await fetch({
          uri: '/expenseapproverlimits',
          props: {
            filter: {
              employee: value.expense_approved_by._id,
            },
            fields: {_id: 1, amount: 1},
            only: true,
          },
        });
        const approvalLimit = approverLimit?.amount || 0;
        return {approval_limit: approvalLimit};
      }
    },
    dependencies: ['expense_approved_by'],
  },
  'set vendor details': {
    compute: async value => {
      const {payee_id, invoice_date} = value;
      if (payee_id?._id && invoice_date) {
        const {data: vendorData} = await fetch({
          uri: `/vendor/taxdetails/${payee_id?._id}`,
          props: {
            date: value.invoice_date,
          },
        });
        let {
          product,
          payable_account,
          pre_payment_account,
          location_id,
          organization,
          currency_id,
          vendor_tds_rate,
          vendor_vat_rate,
          vendor_gst_rate,
        } = vendorData || {};
        const response = {};
        if (currency_id) {
          response.currency = currency_id;
        }
        if (vendor_vat_rate?.length > 0) {
          vendor_vat_rate = vendor_vat_rate[0];
        }
        if (vendor_gst_rate?.length > 0) {
          vendor_gst_rate = vendor_gst_rate[0];
        }
        if (vendor_tds_rate?.length > 0) {
          vendor_tds_rate = vendor_tds_rate[0];
        }
        if (payable_account) {
          response.vendor_account = payable_account;
        }
        if (pre_payment_account) {
          response.vendor_advance_account = pre_payment_account;
        }
        if (location_id) {
          response.location = location_id;
        }
        if (product) {
          response.product = product;
        }
        if (organization) {
          response.organization = organization;
        }
        if (vendor_gst_rate?.igst) {
          response.igst_account_id = vendor_gst_rate?.igst;
        }
        if (vendor_gst_rate?.cgst) {
          response.cgst_account_id = vendor_gst_rate?.cgst;
        }
        if (vendor_gst_rate?.sgst) {
          response.sgst_account_id = vendor_gst_rate?.sgst;
        }
        if (vendor_vat_rate?.account) {
          response.vat_account = vendor_vat_rate?.account;
        }
        if (vendor_tds_rate?.account) {
          response.tds_account = vendor_tds_rate?.account;
        }
        const finalResponse = {
          ...response,
          vendor_gst_rate,
          vendor_tds_rate,
          vendor_vat_rate,
        };
        return finalResponse;
      }
    },
    multi: true,
    dependencies: ['payee_id', 'invoice_date'],
  },
  'amount total in invoice': {
    compute: values => {
      let service_tax_amount = 0;
      let education_ses_amount = 0;
      let higher_education_ses_amount = 0;
      let sgst_amount = 0;
      let cgst_amount = 0;
      let igst_amount = 0;
      let vat_amount = 0;
      let tds_amount = 0;
      let exp_amount = 0;
      let base_exp_amount = 0;
      let base_tds_amount = 0;
      let base_vat_amount = 0;
      let base_igst_amount = 0;
      let base_cgst_amount = 0;
      let base_sgst_amount = 0;
      let base_higher_education_ses_amount = 0;
      let base_education_ses_amount = 0;
      let base_service_tax_amount = 0;

      if (values.exp_details && values.exp_details.length > 0) {
        values.exp_details.forEach(size => {
          if (size.service_tax_amount) {
            service_tax_amount += size.service_tax_amount;
          }
          if (size.education_ses) {
            education_ses_amount += size.education_ses;
          }
          if (size.higher_education_ses) {
            higher_education_ses_amount += size.higher_education_ses;
          }
          if (size.igst_amount) {
            igst_amount += size.igst_amount;
          }
          if (size.vat_amount) {
            vat_amount += size.vat_amount;
          }
          if (size.cgst_amount) {
            cgst_amount += size.cgst_amount;
          }
          if (size.sgst_amount) {
            sgst_amount += size.sgst_amount;
          }
          if (size.tds_amount) {
            tds_amount += size.tds_amount;
          }
          if (size.purchase_amount) {
            exp_amount += size.purchase_amount;
          }
          if (size.base_purchase_amount) {
            base_exp_amount += size.base_purchase_amount;
          }
          if (values && tds_amount) {
            base_tds_amount = tds_amount * (values.conversion_rate || 1);
          }
          if (values && vat_amount) {
            base_vat_amount = vat_amount * (values.conversion_rate || 1);
          }
          if (values && igst_amount) {
            base_igst_amount = igst_amount * (values.conversion_rate || 1);
          }
          if (values && cgst_amount) {
            base_cgst_amount = cgst_amount * (values.conversion_rate || 1);
          }
          if (values && sgst_amount) {
            base_sgst_amount = sgst_amount * (values.conversion_rate || 1);
          }
          if (values && higher_education_ses_amount) {
            base_higher_education_ses_amount =
              higher_education_ses_amount * (values.conversion_rate || 1);
          }
          if (values && education_ses_amount) {
            base_education_ses_amount =
              education_ses_amount * (values.conversion_rate || 1);
          }
          if (values && service_tax_amount) {
            base_service_tax_amount =
              service_tax_amount * (values.conversion_rate || 1);
          }
        });
      }
      const total_tax_amount =
        sgst_amount + cgst_amount + igst_amount + vat_amount + tds_amount;
      const response = {
        service_tax_amount,
        education_ses_amount,
        higher_education_ses_amount,
        sgst_amount,
        cgst_amount,
        igst_amount,
        vat_amount,
        tds_amount,
        exp_amount,
        base_exp_amount,
        base_service_tax_amount,
        base_education_ses_amount,
        base_higher_education_ses_amount,
        base_sgst_amount,
        base_cgst_amount,
        base_igst_amount,
        base_vat_amount,
        base_tds_amount,
        total_tax_amount,
        base_total_tax_amount: total_tax_amount,
      };
      return response;
    },
    multi: true,
    dependencies: ['exp_details', 'conversion_rate'],
  },
  'set payable and base payable amount': {
    compute: (values, {fetch}) => {
      let payableAmount = 0;
      let basePayableAmount = 0;
      if (values.service_tax_amount && values.base_service_tax_amount) {
        payableAmount += values.service_tax_amount;
        basePayableAmount += values.base_service_tax_amount;
      }
      if (values.education_ses_amount) {
        payableAmount += values.education_ses_amount;
        basePayableAmount += values.base_education_ses_amount;
      }
      if (values.higher_education_ses_amount) {
        payableAmount += values.higher_education_ses_amount;
        basePayableAmount += values.base_higher_education_ses_amount;
      }
      if (values.sgst_amount) {
        payableAmount += values.sgst_amount;
        basePayableAmount += values.base_sgst_amount;
      }
      if (values.cgst_amount) {
        payableAmount += values.cgst_amount;
        basePayableAmount += values.base_cgst_amount;
      }
      if (values.igst_amount) {
        payableAmount += values.igst_amount;
        basePayableAmount += values.base_igst_amount;
      }
      if (values.vat_amount) {
        payableAmount += values.vat_amount;
        basePayableAmount += values.base_vat_amount;
      }
      if (values.tds_amount) {
        payableAmount -= values.tds_amount;
        basePayableAmount -= values.base_tds_amount;
      }
      if (values.exp_amount) {
        payableAmount += values.exp_amount;
      }
      if (values.base_exp_amount) {
        basePayableAmount += values.base_exp_amount;
      }
      const response = {
        payable_amount: Number(payableAmount.toFixed(2)),
        base_payable_amount: Number(basePayableAmount.toFixed(2)),
      };
      return response;
    },
    multi: true,
    dependencies: [
      'service_tax_amount',
      'education_ses_amount',
      'higher_education_ses_amount',
      'sgst_amount',
      'cgst_amount',
      'igst_amount',
      'vat_amount',
      'tds_amount',
      'exp_amount',
      'base_exp_amount',
      'base_tds_amount',
      'base_vat_amount',
      'base_igst_amount',
      'base_cgst_amount',
      'base_sgst_amount',
      'base_higher_education_ses_amount',
      'base_education_ses_amount',
      'base_service_tax_amount',
    ],
  },

  nestedComputations: {
    exp_details: {
      base_purchase_amount: {
        multi: true,
        compute: (values, {_parentValues}) => {
          if (
            _parentValues &&
            _parentValues.conversion_rate &&
            values.purchase_amount
          ) {
            return {
              base_purchase_amount:
                _parentValues.conversion_rate * values.purchase_amount,
            };
          }
        },
        dependencies: [
          'purchase_category',
          'purchase_amount',
          '_parentValues.conversion_rate',
        ],
      },
      'product in exp_details': {
        multi: true,
        compute: (values, {_parentValues}) => {
          if (_parentValues.product) {
            return {
              product: _parentValues.product?._id,
            };
          }
        },
        dependencies: ['_parentValues.product', '_id'],
      },
      purchase_amount: {
        multi: true,
        compute: (values, {_parentValues}) => {
          if (values.quantity && values.rate) {
            return {purchase_amount: values.rate * values.quantity};
          }
        },
        dependencies: ['_id', 'quantity', 'rate'],
      },
      update_tax_amount: {
        multi: true,
        compute: (values, {_parentValues}) => {
          const result = updateTaxAmount(values.expense_tax);
          const finalJSON = {};
          finalJSON['service_tax_amount'] = result['Service Tax'] || 0;
          finalJSON['vat_amount'] = result['Vat'] || 0;
          finalJSON['education_ses'] = result['Cess'] || 0;
          finalJSON['higher_education_ses'] = result['Hcess'] || 0;
          finalJSON['kk_amount'] = result['KK'] || 0;
          finalJSON['igst_amount'] = result['IGST'] || 0;
          finalJSON['cgst_amount'] = result['CGST'] || 0;
          finalJSON['sgst_amount'] = result['SGST'] || 0;
          finalJSON['tds_amount'] = result['TDS'] || 0;
          return {...finalJSON};
        },
        dependencies: ['expense_tax'],
      },
      nestedComputations: {
        expense_tax: {
          amount: {
            multi: true,
            compute: (values, {_parentValues}) => {
              const type = values.tax_type;
              const amount = _parentValues.base_purchase_amount || 0;
              return calculateTaxAmount(type, amount, _parentValues);
            },
            dependencies: ['tax_type', '_parentValues.tds_account'],
          },
          rate: {
            multi: true,
            compute: (values, {_parentValues}) => {
              const amount = _parentValues.base_purchase_amount || 0;
              return {
                amount: (Number(values.rate) / 100) * amount,
              };
            },
            dependencies: ['rate'],
          },
        },
      },
    },
  },
});

const updateTaxAmount = tax => {
  const taxDetails =
    tax &&
    tax.reduce((prev, currentInfo) => {
      const type = currentInfo.tax_type;
      let currentamount = currentInfo.amount;
      if (type && type.name && currentamount) {
        if (prev[type.name]) {
          currentamount += Number(prev[type.name]);
        }
        prev[type.name] = Number(currentamount);
      }
      return prev;
    }, {});
  return taxDetails;
};

const calculateTaxAmount = (type, amount, info) => {
  const {vendor_tds_rate, vendor_vat_rate, vendor_gst_rate} =
    info?._parentValues || {};
  let response = void 0;
  if (type.name === 'TDS' && amount && amount > 0) {
    if (
      vendor_tds_rate &&
      vendor_tds_rate.account &&
      vendor_tds_rate.rate_percent &&
      vendor_tds_rate.rate_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_tds_rate.rate_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_tds_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'Vat' && amount && amount > 0) {
    if (
      vendor_vat_rate &&
      vendor_vat_rate.account &&
      vendor_vat_rate.rate_percent &&
      vendor_vat_rate.rate_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_vat_rate.rate_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_vat_rate.rate_percent),
      };
    }
  } else if (info && type && type.name === 'IGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.igst &&
      vendor_gst_rate.igst_percent &&
      vendor_gst_rate.igst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.igst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.igst_percent),
      };
    }
  } else if (info && type && type.name === 'CGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.cgst &&
      vendor_gst_rate.cgst_percent &&
      vendor_gst_rate.cgst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.cgst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.cgst_percent),
      };
    }
  } else if (info && type && type.name === 'SGST' && amount && amount > 0) {
    if (
      vendor_gst_rate &&
      vendor_gst_rate.sgst &&
      vendor_gst_rate.sgst_percent &&
      vendor_gst_rate.sgst_percent > 0
    ) {
      response = {
        amount: Number(
          ((Number(vendor_gst_rate.sgst_percent) / 100) * amount).toFixed(2),
        ),
        rate: Number(vendor_gst_rate.sgst_percent),
      };
    }
  }
  return response;
};

const VendorInvoiceForm = props => {
  const {
    route: {params = {}},
  } = props;
  const {GreenBackGroundAdd} = useTheme('icons');
  const colors = useTheme('colors');

  const {department, product, row = {}} = params || {};
  const {user: {employee: employeeData = {}} = {}, fetch} =
    useAppStateContext();

  const {onSubmit} = useFormSubmit({
    eventSourceId: 'VendorInvoiceView',
    uri: '/vendorinvoices',
  });

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: [
      'ReimbursementAccountApproved',
      'ReimbursementAccountProcessed',
      'ReimbursementAccountReopen',
    ],
  });

  const currentDate = getZeroTimeDate(new Date());
  const defaultData = {
    invoice_type: 'Expense',
    expense_type: 'General Expense',
    payment_type: 'One Time',
    accrued_type: 'One Time',
    invoice_date: getZeroTimeDate(new Date()),
    due_date: currentDate.setDate(currentDate.getDate() + 7),
    conversion_rate: 1,
    product: employeeData?.product,
    organization: employeeData?.organization,
    base_currency_id: employeeData?.organization?.currency,
    currency: employeeData?.organization?.currency,
    ...row,
  };
  return (
    <Form
      onSubmit={onSubmit}
      afterSubmit={() => {
        if (row?.reimbursement) {
          invoke({
            uri: `/reimbursements/${row?.reimbursement}`,
            props: {
              status: 'Processed',
              processed_on: getZeroTimeDate(new Date()),
            },
          });
        }
        if (row.status === 'Approved') {
          invoke({
            uri: `/purchaseorders/${row?.purchase_order_id}`,
            props: {
              is_invoice_generated: true,
            },
          });
        }
      }}
      api={`/vendor-invoice/details/${row?._id}`}
      submitAction={'save'}
      defaultValues={defaultData}
      eventSourceId="vendorinvoice"
      computations={{...InvoiceComputations(fetch)}}
      layoutFields={[
        {
          label: 'Payment Type',
          // style: {flex: 1, justifyContent: 'left'},
          fields: [
            {
              type: 'radioGroup',
              field: 'payment_type',
              options: [
                {label: 'One Time', value: 'One Time'},
                {label: 'Recurring', value: 'Recurring'},
                {label: 'Asset', value: 'Asset'},
                {label: 'Provision', value: 'Provision'},
              ],
              required: true,
              size: 6,
            },
            {
              visible: ({values}) => values?.draft_approved_by?.name,
              render: (_, {values}) => {
                return (
                  <Text
                    style={{
                      fontSize: 14,
                      color: colors.INFORMATION_HIGH,
                    }}>
                    {`This Draft has been approved by ${
                      values?.draft_approved_by?.name || ''
                    } `}
                  </Text>
                );
              },
              size: 6,
            },
            {
              visible: ({values}) =>
                (row && row.reimbursement_approved_by) ||
                (values && values.reimbursement_approved_by),
              size: 6,
              render: (_, {values}) => {
                const approvedBy =
                  row?.reimbursement_approved_by?.name ||
                  values?.reimbursement_approved_by?.name;
                return (
                  <Text
                    style={{
                      fontSize: 14,
                      color: colors.INFORMATION_HIGH,
                    }}>
                    {`This Reimbursement has been approved by ${
                      approvedBy || ''
                    } `}
                  </Text>
                );
              },
            },
          ],
        }, // style={{columnTextStyle}}
        {
          label: 'Expense Details',
          fields: [
            {
              api: '/vendors',
              field: 'payee_id',
              label: 'Vendor',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              type: 'autoComplete',
              filter: ({values}) => {
                if (values?.organization?._id) {
                  return {organization: values.organization._id};
                }
              },
              size: 4,
            },
            {
              type: 'date',
              field: 'invoice_date',
              label: 'Invoice Date',
              required: true,
              size: 2,
            },
            {
              type: 'date',
              field: 'due_date',
              label: 'Due Date',
              size: 2,
            },
            {
              type: 'date',
              field: 'capitalization_date',
              label: 'Capitalization Date',
              visible: ({values}) => values?.payment_type == 'Asset',
              size: 4,
            },
            {
              label: 'Servie/product description',
              field: 'purpose',
              required: true,
              type: 'textArea',
              size: 12,
            },
            {
              label: 'PO Number',
              field: 'po_number',
              type: 'autoComplete',
              api: '/purchaseorders',
              valueField: 'po_no',
              suggestionField: 'po_no',
              visible: ({values}) => values?.payment_type == 'Asset',
              size: 3,
              // required: true,
            },
            {
              label: 'Amount',
              field: 'payable_amount',
              required: true,
              type: 'number',
              size: 4,
              editable: false,
            },
            {
              api: '/currencies',
              field: 'currency',
              label: 'Currency',
              valueField: 'currency',
              suggestionField: 'currency',
              required: true,
              type: 'autoComplete',
              size: 4,
            },
            {
              label: 'Exchange Rate',
              field: 'conversion_rate',
              required: true,
              type: 'number',
              size: 4,
            },
            {
              type: 'text',
              field: 'bill_number',
              label: 'Vendor Invoice No.',
              size: 3,
              required: true,
            },
            {
              type: 'date',
              field: 'bill_date',
              label: 'Bill Date',
              size: 3,
              required: true,
            },
          ],
        },
        {
          label: 'Provision Details',
          visible: ({values}) => values?.payment_type == 'Provision',
          fields: [
            {
              label: 'Provision Account',
              field: 'vendor_account',
              api: '/accounts',
              type: 'autoComplete',
              valueField: 'name',
              suggestionField: 'name',
              size: 4,
            },
          ],
        },
        {
          label: 'TDS Account',
          visible: ({values}) => values?.tds_amount && values?.tds_amount > 0,
          fields: [
            {
              label: 'Select TDS Account',
              field: 'tds_account',
              type: 'autoComplete',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              size: 4,
            },
          ],
        },
        {
          label : 'Book Inventory',
          fields : [
            {
              label : "Book Inventory",
              field : 'book_inventory',
              type : "checkbox"
            }
          ]
        },
        {
          label: 'Asset Details',
          visible: ({values}) => values?.payment_type == 'Asset',
          fields: [
            {
              label: 'Asset type',
              field: 'asset_type',
              type: 'autoComplete',
              options: ['WIP', 'Ready To Use', 'Asset Inventory'],
              required: true,
              size: 4,
            },
            {
              label: 'Category',
              field: 'master_purchase_category',
              type: 'autoComplete',
              api: '/accounts',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              size: 4,
            },
            {
              label: 'Location',
              field: 'location',
              type: 'autoComplete',
              api: '/offices',
              valueField: 'name',
              suggestionField: 'name',
              required: true,
              size: 4,
            },
            {
              placeholder: 'Assets photos/invoice.',
              field: 'asset_documents',
              type: 'file',
              required: ({values}) => values?.payment_type == 'Asset',
              options: {bucketName: 'manaze'},
              size: 6,
            },
            {
              label: 'Original bill received',
              field: 'original_bill_received',
              type: 'checkbox',
              api: '/offices',
              size: 6,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Asset',
          fields: [
            {
              label: 'Warranty',
              placeholder: 'Warranty',
              type: 'checkbox',
              field: 'warranty_valid',
            },
            {
              label: 'Warranty due Date',
              placeholder: 'Warranty due Date',
              type: 'date',
              field: 'warranty_due_date',
              visible: ({values}) => values?.warranty_valid,
            },
          ],
        },
        {
          visible: ({values}) => values?.payment_type == 'Asset',
          fields: [
            {
              label: 'Insured',
              placeholder: 'Insured',
              type: 'checkbox',
              field: 'is_asset_insured',
              required: true,
            },
            {
              label: 'Insured on',
              placeholder: 'Insured on',
              type: 'date',
              field: 'asset_insured_on',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Policy no.',
              placeholder: 'Policy no.',
              type: 'text',
              field: 'policy_number',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance company',
              placeholder: 'Insurance company',
              type: 'text',
              field: 'insurance_company',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
            {
              label: 'Insurance expiry/renewal date',
              placeholder: 'Insurance expiry/renewal date',
              type: 'date',
              field: 'insurance_expiry_date',
              visible: ({values}) => values?.is_asset_insured,
              required: true,
              size: 3,
            },
          ],
        },
        {
          visible: ({values}) =>
            values?.payment_type == 'One Time' ||
            values?.payment_type == 'Provision',
          label: 'Expense Across',
          fields: [
            {
              type: 'radioGroup',
              field: 'accrued_type',
              options: [
                {label: 'Single Month', value: 'One Time'},
                {label: 'Period', value: 'Period'},
              ],
              required: true,
            },
            {
              label: 'Start Date',
              field: 'schedule_from_date',
              type: 'date',
              size: 4,
              visible: ({values}) => values?.accrued_type == 'Period',
            },
            {
              label: 'End Date',
              field: 'schedule_to_date',
              type: 'date',
              size: 4,
              visible: ({values}) => values?.accrued_type == 'Period',
            },
            {
              label: 'Schedule Account',
              field: 'schedule_account',
              api: '/accounts',
              type: 'autoComplete',
              valueField: 'name',
              suggestionField: 'name',
              size: 4,
              visible: ({values}) => values?.accrued_type == 'Period',
            },
          ],
        },
        {
          label: 'Invoice Details',
          fields: [
            {
              label: 'Purchase Details',
              field: 'exp_details',
              nested: true,
              render: props => {
                const {
                  form: {values = {}},
                } = props;
                const {conversion_rate ,  expense_type} = values;
                console.log("expense_type>>>>>>>>>>",expense_type)
                return (
                  <NestedTable
                    {...props}
                    key = {values?.expense_type}
                    inlineAddForm
                    selection={{
                      deleteAction: true,
                    }}
                    fields={[
                      {
                        field: 'purchase_category',
                        api: '/accounts',
                        type: 'autoComplete',
                        valueField: 'name',
                        suggestionField: 'name',
                        label: 'Purchase Category',
                        minWidth: 180,
                      },
                      {
                        field: 'sub_purchase_category', // need to ask
                        api: '/assetmastercategories',
                        type: 'autoComplete',
                        valueField: 'name',
                        suggestionField: 'name',
                        label: 'Sub Category',
                        width: 200,
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'number',
                        field: 'quantity',
                        label: 'Qty.',
                        required: true,
                        width: 150,
                        onChangeValue: (value, e, {setFieldValue, values}) => {
                          if (values?.rate && value) {
                            setFieldValue(
                              'purchase_amount',
                              values.rate * value,
                            );
                            setFieldValue(
                              'base_purchase_amount',
                              values.rate * value * conversion_rate,
                            );
                          }
                        },
                      },
                      {
                        type: 'number',
                        field: 'rate',
                        label: 'Rate',
                        width: 150,
                        required: true,
                        onChangeValue: (value, e, {setFieldValue, values}) => {
                          if (value && values?.quantity) {
                            setFieldValue(
                              'purchase_amount',
                              values.quantity * value,
                            );
                            setFieldValue(
                              'base_purchase_amount',
                              values.quantity * value * conversion_rate,
                            );
                          }
                        },
                      },
                      {
                        type: 'number',
                        field: 'purchase_amount',
                        label: 'Amount',
                        width: 150,
                      },
                      {
                        type: 'text',
                        field: 'brand',
                        label: 'Brand',
                        width: 100,
                        visible: () => values?.payment_type == 'Asset'|| values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'text',
                        field: 'model',
                        label: 'Model',
                        width: 100,
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'text',
                        field: 'serial_no',
                        label: 'Serial No.',
                        width: 100,
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'text',
                        field: 'imei_number',
                        label: 'IMEI No.',
                        width: 100,
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                    ]}
                    columns={[
                      {
                        field: 'purchase_category.name',
                        type: 'text',
                        header: 'Purchase Category',
                        visible : () => {
                          console.log("values>>>>>>>>>>>>>>>",values)
                          return true
                        }
                      },
                      {
                        field: 'sub_purchase_category.name',
                        type: 'text',
                        header: 'Sub Category',
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        field: 'purchase_amount',
                        type: 'text',
                        header: 'Amount',
                      },
                      {
                        field: 'brand',
                        type: 'text',
                        header: 'Brand',
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        field: 'model',
                        type: 'text',
                        header: 'Model',
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'number',
                        field: 'quantity',
                        header: 'Quantity',
                        visible: () => values?.payment_type !== 'Asset' || values?.expense_type == 'Asset Inventory',
                      },
                      {
                        type: 'number',
                        field: 'rate',
                        header: 'Rate',
                        visible: () => values?.payment_type !== 'Asset' || values?.expense_type == 'Asset Inventory',
                        width: 100,
                      },
                      {
                        type: 'text',
                        field: 'serial_no',
                        header: 'Serial No.',
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                        width: 100,
                      },
                      {
                        type: 'text',
                        field: 'imei_number',
                        header: 'IMEI No.',
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                        width: 100,
                      },
                      {
                        width: 100,
                        visible: () => values?.payment_type == 'Asset' || values?.expense_type == 'Asset Inventory',
                        innerNested: true,
                        header: 'Tag',
                        label: () => {
                          return <Image source={GreenBackGroundAdd} />;
                        },
                        fields: [
                          {
                            field: 'tag_asset',
                            nested: true,
                            render: (props = {}) => {
                              const {
                                form: {data = {}},
                              } = props;
                              return (
                                <NestedTable
                                  {...props}
                                  fields={[
                                    {
                                      field: 'type',
                                      type: 'autoComplete',
                                      label: 'Tag',
                                      api: '/assetmastercategories',
                                      valueField: 'name',
                                      suggestionField: 'name',
                                    },
                                  ]}
                                  columns={[
                                    {
                                      field: 'type.name',
                                      label: 'Tag',
                                      type: 'text',
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                      {
                        width: 100,
                        innerNested: true,
                        header: 'Tax',
                        label: () => {
                          return <Image source={GreenBackGroundAdd} />;
                        },
                        fields: [
                          {
                            type: 'text',
                            field: 'expense_tax',
                            nested: true,
                            render: (props = {}) => {
                              const {
                                form: {data = {}},
                              } = props;

                              const {base_purchase_amount} = data;
                              return (
                                <NestedTable
                                  {...props}
                                  fields={[
                                    {
                                      field: 'tax_type',
                                      label: 'Tax Type',
                                      suggestionField: 'name',
                                      valueField: 'name',
                                      api: '/taxtypes',
                                      type: 'autoComplete',
                                      size: 4,
                                    },
                                    {
                                      type: 'number',
                                      field: 'rate',
                                      label: 'Rate',
                                      onChangeValue: (
                                        value,
                                        _,
                                        {setFieldValue},
                                      ) => {
                                        setFieldValue(
                                          'amount',
                                          (value / 100) * base_purchase_amount,
                                        );
                                      },
                                      size: 4,
                                    },
                                    {
                                      type: 'number',
                                      field: 'amount',
                                      label: 'Amount',
                                      size: 4,
                                    },
                                  ]}
                                  columns={[
                                    {
                                      field: 'tax_type.name',
                                      type: 'text',
                                      header: 'Tax Type',
                                    },
                                    {
                                      type: 'number',
                                      field: 'rate',
                                      header: 'Rate',
                                    },
                                    {
                                      type: 'number',
                                      field: 'amount',
                                      header: 'Amount',
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                );
              },
            },
          ],
        },
        {
          render: (_, props) => {
            return <InvoiceAmountSummary {...props} />;
          },
        },
        {
          groups: [
            {
              label: 'HSN Details',
              fields: [
                {
                  label: 'HSN Code',
                  required: true,
                  field: 'hsn_number',
                  type: 'text',
                },
                {
                  collapsedFields: [
                    {
                      type: 'textArea',
                      field: 'hsn_description',
                      required: true,
                      label: 'HSN Description',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Supporting Docs',
              fields: [
                {
                  field: 'approved_mail',
                  label: 'Approved document',
                  type: 'file',
                  options: {bucketName: 'manaze'},
                  size: 6,
                },
                {
                  field: 'document',
                  label: 'Invoice',
                  type: 'file',
                  multiple: true,
                  size: 6,
                  options: {bucketName: 'manaze'},
                },
              ],
            },
            {
              label: 'Team',
              fields: [
                {
                  field: 'department',
                  api: '/departments',
                  type: 'autoComplete',
                  valueField: 'name',
                  suggestionField: 'name',
                  label: 'Department',
                  size: 4,
                },
                {
                  field: 'team',
                  api: '/employeeteams',
                  type: 'autoComplete',
                  valueField: 'name',
                  suggestionField: 'name',
                  label: 'Team',
                  size: 4,
                  required: true,
                  // readOnly: params.expense_view ? true : false,
                },
                {
                  field: 'expense_approved_by',
                  type: 'autoComplete',
                  label: 'Expense Approve By',
                  api: '/employeeSuggestions',
                  valueField: 'name',
                  suggestionField: 'name',
                  keyField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  size: 4,
                  required: true,
                  // readOnly: params.expense_view ? true : false,
                },
                {
                  field: 'approval_limit',
                  type: 'number',
                  label: 'Approval Limit',
                  size: 4,
                  required: true,
                  readOnly: params.expense_view ? true : false,
                },
                {
                  field: 'approved_amount',
                  type: 'number',
                  label: 'Approved Amount',
                  size: 4,
                },
                {
                  field: 'requested_by',
                  type: 'autoComplete',
                  label: 'Expense Requested By',
                  api: '/employeeSuggestions',
                  valueField: 'name',
                  suggestionField: 'name',
                  keyField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  size: 4,
                  // readOnly: params.expense_view ? true : false,
                },
              ],
            },
            {
              label: 'Accounting',
              fields: [
                {
                  field: 'organization',
                  api: '/organizations',
                  type: 'autoComplete',
                  label: 'Organization',
                  searchField: 'name',
                  suggestionField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  field: 'product',
                  api: '/products',
                  type: 'autoComplete',
                  label: 'Product',
                  suggestionField: 'name',
                  valueField: 'name',
                  required: true,
                  size: 4,
                },
                {
                  field: 'location',
                  api: '/offices',
                  type: 'autoComplete',
                  label: 'Tax Location',
                  valueField: 'name',
                  suggestionField: 'name',
                  required: true,
                  size: 4,
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateNewVendor = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/vendors`,
    eventSourceId: 'Vendor',
    ...props,
  });

  const {user, fetch} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        verified_on: new Date(),
        verifier: employeeId,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'status',
          options: [
            {value: 'Active', label: 'Verified'},
            {value: 'In Active', label: 'Inactive'},
          ],
        },
      ]}
      {...props}
    />
  );
};

export const MarkVendorBlacklist = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/vendors`,
    eventSourceId: 'Vendor',
    ...props,
  });

  const {user, fetch} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        blacklist_date: new Date(),
        blacklist_by: employeeId,
        status: 'Blacklist',
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'textArea',
          field: 'blacklist_reason',
          label: 'Reason for blacklist',
          required: true,
        },
      ]}
      {...props}
    />
  );
};

export const UpdateVerifiedCustomer = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/vendors`,
    eventSourceId: 'Vendor',
    ...props,
  });

  const {user, fetch} = useAppStateContext();
  const {employee} = user;
  const {_id: employeeId, organization, product} = employee;

  return (
    <Form
      defaultValues={{
        _id: row?._id,
        verified_on: new Date(),
        verifier: employeeId,
      }}
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'status',
          options: [
            {value: 'New', label: 'New'},
            {value: 'In Active', label: 'In Active'},
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateNewVendorStatus = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateNewVendor
      header={{
        title: 'Update Status',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const markVendorBlacklist = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkVendorBlacklist
      header={{
        title: 'Update Status',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const UpdateVerifiedVendorStatus = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <UpdateVerifiedCustomer
      header={{
        title: 'Update Status',
        secondaryTitle: () => {
          const colors = useTheme('colors');
          const {CAPTION_LARGE} = useTheme('fonts');
          return (
            <Text
              style={{
                ...CAPTION_LARGE,
                color: colors.NEUTRAL_MEDIUM,
                paddingLeft: 5,
              }}>
              {row?.name}
            </Text>
          );
        },
      }}
      {...props}
    />
  );
};

export const AddVendorInvoiceForm = props => {
  return <VendorInvoiceForm header={'Create Expense Invoice'} {...props} />;
};

export const AddVendorInvoiceReimbursmentForm = ({route, ...props}) => {
  const {params} = route;
  let {row} = params;
  const {data = {}, loading} = useFetchData({
    api: '/vendors',
    filter: {
      employee_id: row?.employee?._id,
    },
    fields: {
      _id: 1,
      location_id: {_id: 1, name: 1},
      product: {_id: 1, name: 1},
      name: 1,
    },
    only: true,
    // sort: {_id: -1},
  });

  const updatedRow = {
    ...row,
    payee_id: {_id: data?._id, name: data?.name}, //
    location: {
      _id: data?.location_id?._id,
      name: data?.location?.name,
    }, //
    product: {_id: data?.product?._id, name: data?.product?.name}, //
  };

  let updatedRoute = {...route, params: {...params, row: updatedRow}};

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <VendorInvoiceForm
      header={'Create Expense Invoice'}
      {...props}
      route={updatedRoute}
    />
  );
};

export const EditVendorInvoiceForm = props => {
  return (
    <VendorInvoiceForm header={'Edit Expense Invoice'} mode="edit" {...props} />
  );
};

const SettleVendorInvoiceForm = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const {user} = useAppStateContext();
  const {onSubmit} = useFormSubmit({
    uri: `/vendorinvoices`,
    eventSourceId: ['VendorInvoiceView'],
  });
  return (
    <Form
      api={`/vendorInvoice/${row._id}`}
      mode="edit"
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        const changes = {
          adjusted_by: user?.employee?._id,
        };
        if (data.adjust_type === 'Settle') {
          changes['pending_amount'] = 0;
          changes['base_pending_amount'] = 0;
        }
        return {
          data: {
            ...data,
            ...changes,
          },
        };
      }}
      eventSourceId="VendorInvoiceView"
      submitAction="Save"
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'adjust_type',
          options: ['Settle', 'Disputed', 'Legal'],
          placeholder: 'Select Type',
          label: 'Adjust Type',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
export const SettleVendorInvoice = props => {
  return <SettleVendorInvoiceForm header="Settle Invoice" {...props} />;
};

export const MarkNotUpdateOnVoucher = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/vendorinvoices',
    eventSourceId: ['vendorinvoices'],
  });

  return (
    <Form
      {...props}
      api={`/vendor-invoice/details/${row?._id}`}
      fields={{not_update_voucher: 1}}
      mode="edit"
      onSubmit={onSubmit}
      submitAction={'Save'}
      layoutFields={[
        {
          type: 'radioGroup',
          field: 'not_update_voucher',
          required: true,
          options: [
            {value: true, label: 'Yes'},
            {value: false, label: 'No'},
          ],
        },
      ]}
    />
  );
};

export const MarkNotUpdateOnVoucherForm = props => {
  let {
    route: {params},
  } = props;
  const {row} = params;
  return (
    <MarkNotUpdateOnVoucher
      header={{
        title: 'Mark Not Update On Voucher',
      }}
      {...props}
    />
  );
};