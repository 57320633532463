import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {useState} from 'react';
import {Tag} from '../../../components/chip/Chip';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithDateChip, UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';

const RenderStatus = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendance',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.status) {
    return;
  }
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/requests/${row._id}`,
                props: {
                  status: 'approve',
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/requests/${row._id}`,
                props: {
                  status: 'reject',
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};

export const TeamLeaveRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    filterParams,
    searchValue,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};

  let addOnFilter = {
    ...filter,
    status: {$exists: false},
  };
  return (
    <Table
      api={'/leaverequest/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{requested_on: -1}}
      onRowPress={({row}) => {
        if (row && row.status !== 'approve') {
          navigation.navigate(`update-team-leave-request`, {
            row,
          });
        }
      }}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.requested_on).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Duration',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'First Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.first_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Second Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.second_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          render: ({row, styles}) => <RenderStatus row={row} styles={styles} />,
        },
      ]}
    />
  );
};
export const ApprovedLeaveRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {...filter, status: {$in: ['approve', 'reject']}};
  return (
    <Table
      api={'/leaverequest/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      sort={{updateAt: -1}}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 200,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.requested_on).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 300,
          render: ({row}) => (
            <UserAvatarWithNameAndEmail
              name={row?.employee?.name}
              color={row?.employee?.color}
              official_email_id={row?.employee?.official_email_id}
            />
          ),
        },
        {
          header: 'Duration',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'First Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.first_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Second Half',
          render: ({row}) => {
            return (
              <Tag
                value={row?.second_attendance_type_id?.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Status',
          render: ({row, styles}) => {
            let {rowText} = styles;
            if (row && row?.status && row?.status === 'approve') {
              return (
                <Text style={{...rowText, color: '#34C759'}}>Approved </Text>
              );
            }
            if (row && row?.status && row?.status === 'reject') {
              return (
                <Text style={{...rowText, color: '#FF5247'}}>Rejected </Text>
              );
            }
          },
        },
      ]}
    />
  );
};
export const RejectedLeaveRequestTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;
  let {user} = useAppStateContext();

  const {period, ...rest} = params;

  params = {
    ...rest,
  };
  params = {...params, ...filterParams};
  let addOnFilter = {...filter, status: 'reject'};
  return (
    <Table
      api={'/leaverequest/team'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      search={searchValue}
      // onRowPress={({row}) => {
      //   if (row && row.status !== 'approve') {
      //     navigation.navigate(`update-team-leave-request`, {
      //       row,
      //     });
      //   }
      // }}
      columns={[
        {
          header: 'Employee',
          width: 300,
          render: props => (
            <UserAvatarWithDateChip
              field={'employee'}
              dateField={'createdAt'}
              requested
              {...props}
            />
          ),
        },
        {
          header: 'Duration',
          type: 'date',
          width: 300,
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'First Half',
          render: ({row}) => {
            return (
              <Tag
                value={row.first_attendance_type_id.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Second Half',
          render: ({row}) => {
            return (
              <Tag
                value={row.second_attendance_type_id.name}
                color={'#F5E0FF'}
              />
            );
          },
        },
        {
          header: 'Rejected On',
          render: ({row, styles}) => {
            if (row && row.updatedAt) {
              return (
                <Text style={{...styles.rowText}}>
                  {moment(row.updatedAt).format('DD MMM YY')}
                </Text>
              );
            }
          },
        },
      ]}
    />
  );
};
