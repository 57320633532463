import React from 'react';
import {Table} from '../../../components/table/Table';
import {useTheme} from '@unthinkable/react-theme';
import {View} from '@unthinkable/react-core-components';
import {Chip} from '../../../components/chip/Chip';
import {appraisalController} from './AppraisalController';
import {useAppStateContext} from '../../../providers/AppState';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';

export const EmployeeAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  const {
    showEmployeePackageForPH,
    repopulateAppraisal,
    recalculateAppraisal,
    showPreviousAppraisal,
    showProjectFeedback,
    offerAcceptedByEmployeeForm,
    offerAcceptedByEmployee,
  } = appraisalController(props);
  const colors = useTheme('colors');
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeeteamappraisal'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('manager-appraisal-detail', {
          row,
          tab,
        })
      }
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {header: 'Team', field: 'employee.employee_team.name', width: 200},
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 200,
        },
        {
          header: 'Due Date',
          type: 'date',
          field: 'effective_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Agreement End Date',
          type: 'date',
          field: 'employee.agreement_end_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Self Assesment Recieved',
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager Feedback',
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Unit Head Feedback Recieved',
          render: ({row}) => {
            if (row?.unit_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
      moreActions={[
        {
          title: 'Show Components',
          onPress: showEmployeePackageForPH,
          visible: () => tab && tab === 'TeamPending',
        },
        {
          title: 'Project Feedback',
          onPress: showProjectFeedback,
          visible: () => tab && tab === 'TeamPending',
        },
        {
          title: 'Show Previous Appraisal',
          onPress: showPreviousAppraisal,
          visible: () => tab && tab === 'TeamPending',
        },
        {
          title: 'Offer Accepted By Employee',
          onPress: offerAcceptedByEmployeeForm,
          visible: ({row}) => {
            if (
              tab &&
              tab === 'In Discussion' &&
              row &&
              row.increment_recommendation
            ) {
              return true;
            }
          },
        },
        offerAcceptedByEmployee({tab}),
        repopulateAppraisal({tab}),
        recalculateAppraisal({tab}),
      ]}
    />
  );
};

export const PHAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab, api} = props;
  const colors = useTheme('colors');
  const {
    showEmployeePackageForPH,
    repopulateAppraisal,
    recalculateAppraisal,
    showPreviousAppraisal,
    showProjectFeedback,
  } = appraisalController(props);
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={api || '/employeephappraisal'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('ph-appraisal-detail', {
          row,
          tab,
        })
      }
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {header: 'Team', field: 'employee.employee_team.name', width: 200},
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 200,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'employee.old_designation.name',
          width: 150,
        },
        {
          header: 'Due Date',
          type: 'date',
          field: 'effective_date',
          width: 200,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          width: 200,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Primary Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'Self Assesment',
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager Feedback',
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Primary Manager Feedback Recieved',
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
      moreActions={[
        {
          title: 'Show Components',
          onPress: showEmployeePackageForPH,
          visible: () => tab && tab === 'Pending Approval',
        },
        {
          title: 'Project Feedback',
          onPress: showProjectFeedback,
          visible: () => tab && tab === 'Pending Approval',
        },
        {
          title: 'Show Previous Appraisal',
          onPress: showPreviousAppraisal,
          visible: () => tab && tab === 'Pending Approval',
        },
        repopulateAppraisal({tab}),
        recalculateAppraisal({tab}),
      ]}
    />
  );
};

export const UHAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab, api} = props;
  const colors = useTheme('colors');
  const {
    showEmployeePackageForPH,
    repopulateAppraisal,
    recalculateAppraisal,
    showPreviousAppraisal,
    showProjectFeedback,
  } = appraisalController(props);
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeeuhappraisal'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('ph-appraisal-detail', {
          row,
          tab,
        })
      }
      columns={[
        {header: 'Code', field: 'employee.employee_code', width: 150},
        {header: 'Name', field: 'employee', type: 'userAvatarChip', width: 200},
        {
          header: 'Joining Date',
          type: 'date',
          field: 'employee.joining_date',
          width: 150,
        },
        {header: 'Experience', type: 'text', field: 'employee.experience'},
        {header: 'Due Date', type: 'date', field: 'effective_date', width: 200},
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          width: 200,
        },
        {
          header: 'Primary Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'New Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 150,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'employee.old_designation.name',
          width: 150,
        },
        {
          header: 'Self Assesment',
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager Feedback',
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  // {...rest}
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Primary Manager Feedback Recieved',
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    // {...rest}
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  // {...rest}
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
      moreActions={[
        {
          title: 'Show Components',
          onPress: showEmployeePackageForPH,
          visible: () => tab && tab === 'Pending Approval',
        },
        {
          title: 'Project Feedback',
          onPress: showProjectFeedback,
          visible: () => tab && tab === 'Pending Approval',
        },
        {
          title: 'Show Previous Appraisal',
          onPress: showPreviousAppraisal,
          visible: () => tab && tab === 'Pending Approval',
        },
        repopulateAppraisal({tab}),
        recalculateAppraisal({tab}),
      ]}
      // params={params}
    />
  );
};

export const PHFinalAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  // const { tab } = params;
  const colors = useTheme('colors');
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeephappraisal'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('ph-final-appraisal-detail', {
          row,
          tab,
        })
      }
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {header: 'Team', field: 'employee.employee_team.name', width: 200},
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 200,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'employee.old_designation.name',
          width: 150,
        },
        {
          header: 'Due Date',
          type: 'date',
          field: 'effective_date',
          width: 200,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          width: 200,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Primary Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'Self Assesment',
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager Feedback',
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Primary Manager Feedback Recieved',
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
    />
  );
};

export const UHFinalAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  // const { tab } = params;
  const colors = useTheme('colors');
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeephappraisal'}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('ph-final-appraisal-detail', {
          row,
          tab,
        })
      }
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {header: 'Team', field: 'employee.employee_team.name', width: 200},
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 150,
        },
        {
          header: 'Old Designation',
          type: 'text',
          field: 'employee.old_designation.name',
          width: 150,
        },
        {header: 'Due Date', type: 'date', field: 'effective_date', width: 200},
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          width: 200,
        },
        {
          header: 'Primary Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'Self Assesment',
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager Feedback',
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Primary Manager Feedback Recieved',
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
    />
  );
};

const checkIfInAppraisalAccepted = row => {
  const {
    employee = {},
    status,
    hrPayroll_appraisal_status,
    processed_manually_by_hr,
    offer_accepted_by_employee,
    ifIncrement_asPer_aggrement,
    manually_processed_onhold_extend,
    people_manager_appraisal_status,
  } = row || {};
  let result = false;

  if (
    (employee &&
      employee.department &&
      employee.department._id.toString() === '584900077d89cd3f17c73374') ||
    hrPayroll_appraisal_status ||
    status !== 'Active'
  ) {
    return result;
  }

  if (processed_manually_by_hr && offer_accepted_by_employee) {
    result = true;
  }

  if (
    !result &&
    ifIncrement_asPer_aggrement === 'Extend' &&
    manually_processed_onhold_extend
  ) {
    result = true;
  }

  if (
    !result &&
    ifIncrement_asPer_aggrement === 'OnHold' &&
    manually_processed_onhold_extend &&
    people_manager_appraisal_status &&
    offer_accepted_by_employee
  ) {
    result = true;
  }

  if (
    !result &&
    ifIncrement_asPer_aggrement === 'AsPerAggrement' &&
    people_manager_appraisal_status &&
    offer_accepted_by_employee
  ) {
    result = true;
  }

  if (
    !result &&
    !ifIncrement_asPer_aggrement &&
    people_manager_appraisal_status &&
    offer_accepted_by_employee
  ) {
    result = true;
  }

  return result ? 'Yes' : 'No';
};

export const HRAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter, addOnFilter, tab} = props;
  const colors = useTheme('colors');
  const {download} = useAppStateContext();
  const fetchProps = {
    addOnFilter,
    api: `/employeehrappraisal`,
  };
  const {
    inactiveAppraisal,
    repopulateAppraisal,
    recalculateAppraisal,
    changePeopleManager,
    changePracticeHead,
    extendAppraisal,
    closeManuallyAppraisal,
    processManuallyAmountAccepted,
    nextSixMonthPackage,
    firstSixMonthPackage,
    preparePackage,
    downloadBiannualPackage,
    downloadAnnualPackage,
    addAppraisalAttachment,
    changeCloseDate,
    editIncrementDetail,
    addRemark,
    resendAppraisalLetter,
    sendAppraisalLetter,
    processExtendAppraisal,
    closeAppraisalRequest,
    processOnHoldAppraisal,
    activeAppraisal,
    uploadAppraisalLetter,
    showPackage,
  } = appraisalController(props);
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeehrappraisal'}
      limit={100}
      addOnFilter={{...filter, ...addOnFilter}}
      onRowPress={({row}) =>
        navigation.navigate('hr-appraisal-detail', {
          row,
        })
      }
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      Experience: 'employee.experience',
                      'Effective Date': 'effective_date',
                      'Current Designation': 'employee.designation.name',
                      "New Designation": "unit_promotion_designation.name",
                      'People Manager': 'employee_people_manager.name',
                      "Increment From Date": "increment_letter_latest_from_date",
                      "Increment To Date": "increment_letter_latest_to_date",
                      "Increment Letter": "increment_letter_latest.filename",
                      'Close Date': 'closed_date',
                      'Employee Status': 'self_appraisal_status',
                      'Reporting Manager Status': 'manager_appraisal_status',
                      'People Manager Status':
                        'people_manager_appraisal_status',
                      'Unit Manager Status': 'unit_appraisal_status',
                      Approver: 'approved_by_unit_ph.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {header: 'Team', field: 'employee.employee_team.name', width: 100},
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 200,
        },
        {
          header: 'Appraisal Due',
          type: 'date',
          field: 'effective_date',
          width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Close Date',
          type: 'date',
          field: 'closed_date',
          width: 120,
          visible: () =>
            tab &&
            (tab === 'Appraisal Amount Accepted' || tab === 'Closed Appraisal'),
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Primary Manager',
          type: 'userAvatarChip',
          field: 'employee.functional_manager',
          width: 180,
        },
        {
          header: 'Practice Head',
          type: 'userAvatarChip',
          field: 'practice_head',
          width: 180,
        },
        {
          header: 'Self Status',
          width: 100,
          render: ({row}) => {
            if (row?.self_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Project Manager',
          width: 130,
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Primary Manager',
          width: 130,
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Unit Manager',
          width: 130,
          render: ({row}) => {
            if (row?.unit_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Accepted',
          width: 100,
          render: ({row}) => {
            let appraisalAccepted = checkIfInAppraisalAccepted(row);
            if (appraisalAccepted == 'Yes') {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'No'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Approver',
          type: 'userAvatar',
          field: 'approved_by_unit_ph',
          width: 100,
        },
      ]}
      moreActions={[
        inactiveAppraisal({tab}),
        repopulateAppraisal({tab}),
        recalculateAppraisal({tab}),
        processExtendAppraisal({}),
        processOnHoldAppraisal({}),
        activeAppraisal({}),
        extendAppraisal({navigation}),
        {
          title: 'Change People Manager',
          onPress: changePeopleManager,
          visible: () => tab && tab === 'Pending',
        },
        {
          title: 'Update Practice Head',
          onPress: changePracticeHead,
          visible: () => tab && tab === 'Pending',
        },
        {
          title: 'Add Attachment',
          onPress: addAppraisalAttachment,
          visible: () =>
            tab && (tab === 'Letter Shared' || tab === 'Closed Appraisal'),
        },
        {
          title: 'Change Close Date',
          onPress: changeCloseDate,
          visible: () => tab && tab === 'Closed Appraisal',
        },
        {
          title: 'Add Remark',
          onPress: addRemark,
          visible: () => tab && tab === 'Closed Appraisal',
        },
        {
          title: 'Send Appraisal Letter',
          onPress: sendAppraisalLetter,
          visible: () => tab && tab === 'Appraisal Amount Accepted',
        },
        {
          title: 'Upload Letter',
          onPress: uploadAppraisalLetter,
          visible: () => tab && tab === 'Inactive Appraisal',
        },
        {
          title: 'Close Appraisal Request',
          onPress: closeAppraisalRequest,
          visible: () => tab && tab === 'Letter Shared',
        },
        {
          title: 'Resend Letter',
          onPress: resendAppraisalLetter,
          visible: () => tab && tab === 'Letter Shared',
        },
        {
          title: 'Edit Increment Details',
          onPress: editIncrementDetail,
          visible: () => tab && tab === 'Closed Appraisal',
        },
        {
          title: 'Process Manually(Closed Appraisals)',
          onPress: closeManuallyAppraisal,
          visible: ({row}) => {
            return (
              tab &&
              (tab === 'Pending' ||
                tab === 'Appraisal Amount Accepted' ||
                tab === 'Promotion Recommendations') &&
              row &&
              (!row.processed_manually_by_hr || row.status !== 'Closed')
            );
          },
        },
        {
          title: 'Process Manually(Appraisal Amount Accepted)',
          onPress: processManuallyAmountAccepted,
          visible: ({row}) => {
            return (
              tab &&
              (tab === 'Pending' ||
                tab === 'Appraisal Amount Accepted' ||
                tab === 'Promotion Recommendations') &&
              row &&
              !row.processed_manually_by_hr
            );
          },
        },
        {
          title: 'First Six Month Package',
          onPress: firstSixMonthPackage,
          visible: ({row}) =>
            tab &&
            (tab === 'Appraisal Amount Accepted' || tab === 'Letter Shared') &&
            row &&
            row.employee &&
            !row.unitManager_incrementType &&
            row.employee.increment_type === 'Biannual',
        },
        {
          title: 'Next Six Month Package',
          onPress: nextSixMonthPackage,
          visible: ({row}) =>
            tab &&
            (tab === 'Appraisal Amount Accepted' || tab === 'Letter Shared') &&
            row &&
            row.employee &&
            !row.unitManager_incrementType &&
            row.employee.increment_type === 'Biannual',
        },
        {
          title: 'Prepare Package',
          onPress: preparePackage,
          visible: ({row}) =>
            ((tab && tab === 'Appraisal Amount Accepted') ||
              tab === 'Letter Shared') &&
            ((row &&
              row.employee &&
              row.unitManager_incrementType === 'Annual') ||
              (row.employee.increment_type &&
                row.employee.increment_type === 'Annual') ||
              !row.employee.increment_type),
        },
        {
          title: 'Download Package',
          onPress: downloadAnnualPackage,
          visible: ({row}) =>
            tab &&
            (tab === 'Appraisal Amount Accepted' ||
              tab === 'Letter Shared' ||
              tab === 'Inactive Appraisal') &&
            ((row &&
              row.employee &&
              row.unitManager_incrementType === 'Annual') ||
              (row.employee.increment_type &&
                row.employee.increment_type === 'Annual') ||
              !row.employee.increment_type),
        },
        {
          title: 'Download Package',
          onPress: downloadBiannualPackage,
          visible: ({row}) =>
            tab &&
            (tab === 'Appraisal Amount Accepted' ||
              tab === 'Letter Shared' ||
              tab === 'Inactive Appraisal') &&
            row &&
            row.employee &&
            !row.unitManager_incrementType &&
            row.employee.increment_type === 'Biannual',
        },
        {
          title: 'Show Package',
          onPress: showPackage,
          visible: ({row}) =>
            tab &&
            (tab === 'Appraisal Amount Accepted' ||
              tab === 'Letter Shared' ||
              tab ===
                'Inactive Appraisal') /* && row && row.employee && !row.unitManager_incrementType && row.employee.increment_type === 'Biannual' */,
        },
        {
          title: 'Activity History',
          // visible : formHr ? false : true,
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'practice_head', select: {name: 1}},
                {
                  path: 'approved_by_unit_ph',
                  select: {name: 1},
                },
                {
                  path: 'promotion_designation',
                  select: {name: 1},
                },
                {
                  path: 'unit_promotion_designation',
                  select: {name: 1},
                },
                {
                  path: 'employee_people_manager',
                  select: {name: 1},
                },
                {
                  path: 'new_employee_people_manager',
                  select: {name: 1},
                },
                {
                  path: 'technology_by_unit_head',
                  select: {name: 1},
                },
              ],
              api: `/appraisals/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
      // params={params}
    />
  );
};

export const EmployeeSelfAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter} = props;
  // const { employee } = params;
  const colors = useTheme('colors');
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeeselfappraisal'}
      addOnFilter={{
        ...filter,
        status: 'Closed',
        $or: [
          {
            hrPayroll_appraisal_status: true,
          },
          {
            processed_manually_by_hr: true,
          },
        ],
        hrPayroll_closed_status: true,
      }}
      // onRowPress={({ row }) =>
      //     navigation.navigate('project-feedback-detail', {
      //         row,
      //         tab
      //     })
      // }
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
                joining_date={row?.employee?.joining_date}
                primarySubTitle={row?.employee?.experience}
              />
            );
          },
          minWidth: 260,
        },
        {
          header: 'Designation',
          type: 'text',
          field: 'employee.designation.name',
          width: 200,
        },
        {
          header: 'Due Date',
          type: 'date',
          field: 'effective_date',
          width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          width: 120,
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'People Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'PM Feedback',
          width: 120,
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'RM Feedback',
          width: 120,
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'UH Feedback Recieved',
          width: 200,
          render: ({row}) => {
            if (row?.unit_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
    />
  );
};

export const EmployeePreviousAppraisalTable = props => {
  let {navigation, route: {params} = {}, filter} = props;
  const {employee} = params;
  const addOnFilter = {};
  if (employee) {
    addOnFilter['employee'] = employee;
  }
  const colors = useTheme('colors');
  return (
    <Table
      eventSourceId={['Appraisal']}
      api={'/employeepreviousappraisaldata'}
      addOnFilter={{
        ...filter,
        ...addOnFilter,
        status: 'Closed',
        $or: [
          {
            hrPayroll_appraisal_status: true,
          },
          {
            processed_manually_by_hr: true,
          },
        ],
        hrPayroll_closed_status: true,
      }}
      // onRowPress={({ row }) =>
      //     navigation.navigate('project-feedback-detail', {
      //         row,
      //         tab
      //     })
      // }
      columns={[
        {header: 'Code', field: 'employee.employee_code'},
        {header: 'Name', field: 'employee', type: 'userAvatarChip'},
        {
          header: 'Joining Date',
          type: 'date',
          field: 'employee.joining_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Due Date',
          type: 'date',
          field: 'effective_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Closure Date',
          type: 'date',
          field: 'closed_date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'Reporting',
          field: 'employee.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
        },
        {
          header: 'People Manager',
          type: 'userAvatar',
          field: 'employee.functional_manager',
        },
        {
          header: 'People Manager Feedback',
          width: 200,
          render: ({row}) => {
            if (row?.people_manager_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'Reporting Manager Feedback',
          width: 200,
          render: ({row}) => {
            if (row?.manager_appraisal_status == true) {
              return (
                <Chip
                  color={colors.SUCCESS_LOW}
                  textColor={colors.SUCCESS_HIGH}
                  value={'Yes'}
                  borderRadius={6}
                />
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
        {
          header: 'UnitHead Feedback Recieved',
          width: 200,
          render: ({row}) => {
            if (row?.unit_appraisal_status == true) {
              return (
                <View>
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'Yes'}
                    borderRadius={6}
                  />
                </View>
              );
            } else {
              return (
                <Chip
                  color={colors.WARNING_LOW}
                  textColor={colors.WARNING_HIGH}
                  value={'Pending'}
                  borderRadius={6}
                />
              );
            }
          },
        },
      ]}
    />
  );
};
