import React from 'react';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {TableHeader} from '../../../components/table/Headers';
import {TasksTable} from './TasksTable';
import {taskFilters, useTaskActions} from './TaskActions';
import {AdhocTaskInlineForm} from './TaskForm';
import { useAppStateContext } from '../../../providers/AppState';

export const BacklogOrganizationTaskTable = props => {
  const {
    route: {params},
    navigation,
    searchValue: propsSearchValue,
    user,
    favorite,
    delegatedFilter = {},
    delegatedTask,
    userTeam
  } = props;
  let {employee_team, fromMyView, userId, thread = {}, threadGoalId, threadMeetingNotes} = params;
  
  userId = userId || user?._id;
  const {
    activityLogs,
    updateAssignedTo,
    archive,
    updatePlan,
    markFavorite,
    markDelegated,
    markComplete,
  } = useTaskActions({
    navigation,
    params,
    fromMyView,
  });

  const threadFilter = thread?._id ? {thread: thread._id, thread_goal: threadGoalId, thread_meeting_notes: threadMeetingNotes} : {};
  const favoriteFilter = favorite ? { favorite_by: {$in: [userId]} } : {};
  const {user: currentUser} = useAppStateContext();
  const {_id: currentUserId} = currentUser || {};

  const taskDetail = ({row}) => {
    navigation.navigate('task-edit', {
      ...params,
      row,
      readOnly: fromMyView || favorite,
    });
  };

  const {searchValue, onChangeFilter, filterValues, applyFilter} = useFilter({
    params: {},
  });
  const {params: filterParams, filter: extraFilter} = filterValues;

  const addOnFilter = {
    type: {
      $in: ['Task', 'Continuous'],
    },
    project_id: null,
    ...extraFilter,
    ...threadFilter,
    ...favoriteFilter,
    ...delegatedFilter,
  };
  if (filterParams.is_open) {
    addOnFilter.assigned_to =
      filterParams.is_open == 'Yes' ? null : {$exists: true};
  }
  if (fromMyView && userId) {
    addOnFilter.assigned_to = userId;
  }

  if(favorite){
    addOnFilter.assigned_to = userId;
  }

  return (
    <>
      <TableHeader
        title="Plan Tasks"
        actions={[
          <SearchFilter value={searchValue} onChangeFilter={onChangeFilter} />,
          <GroupFilter
            filterValues={filterValues}
            applyFilter={applyFilter}
            filters={[
              !fromMyView && taskFilters.assigned_to,
              taskFilters.reviewer,
              taskFilters.favorite_by,
              taskFilters.custom,
              taskFilters.strategic,
              taskFilters.due_date,
              !fromMyView && taskFilters.is_open,
              taskFilters.source,
            ]}
          />,
        ]}
      />
      {fromMyView || favorite ? (
        void 0
      ) : (
        <AdhocTaskInlineForm
          defaultValues={{
            status: 'backlog',
            employee_team: employee_team,
            thread: thread?._id,
            thread_goal: threadGoalId,
            delegators: delegatedTask ? [currentUserId] : [],
            thread_meeting_notes: threadMeetingNotes,
          }}
          params={{...params, delegatedTask, userTeam}}
        />
      )}
      <TasksTable
        {...props}
        selection={{
          actions: [
            {
              title: 'Mark Archive',
              onPress: ({selectedIds}) => {
                navigation.navigate('selected-archive-task', {selectedIds});
              },
            },
          ],
        }}
        api={`/organizationtasks`}
        params={{teamId: employee_team?._id, userId}}
        isBacklog
        onRowPress={taskDetail}
        searchValue={propsSearchValue || searchValue}
        fromMyView={fromMyView}
        sort={{due_date: 1, createdAt: -1}}
        filter={{
          status: 'backlog',
          ...addOnFilter,
        }}
        moreActions={[
          fromMyView && updateAssignedTo,
          archive,
          updatePlan,
          markFavorite,
          activityLogs,
          markDelegated,
          thread?._id && markComplete,
        ]}
      />
    </>
  );
};
