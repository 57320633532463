import {
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Confirm} from '../../../components/confirm/Confirm';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useAssetActions} from '../actions/AssetActions';
import {Tag} from '../../../components/chip/Chip';
import {useNavigation} from '@react-navigation/native';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
const JsBarcode = require('jsbarcode');
const {createCanvas} = require('canvas');

const PURCHASE = {
  header: 'Purchased on',
  field: 'date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
  // width: 120
};

const LOCATION = {
  header: 'Location',
  field: 'location.name',
  type: 'colorTag',
  colorField: randomColor,
  width: 140,
};

const RenderFaultType = props => {
  const {row} = props;
  const faultType = row?.fault_type?.join(', ');
  if (!faultType) return null;
  return <Tag value={faultType} />;
};

const ASSETANDCODE = {
  header: 'Asset code',
  align: 'center',
  width: 150,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.code}
        // items={[{value: row?.asset_type}]}
      />
    );
  },
};
const BRANDANDMODEL = {
  header: 'Brand & model',
  // align: 'center',
  width: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.brand}
        items={[{value: row?.model}]}
      />
    );
  },
};

const ASSETCATEGORYTYPE = {
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.type?.name}
        items={[{value: row?.internal_code}]}
      />
    );
  },
  header: 'Asset category',
  // field: 'type.name',
  // width: 150,
};

const BRAND = {
  header: 'Brand',
  field: 'brand',
  width: 100,
};

const MODEL = {
  header: 'Model',
  field: 'model',
  width: 180,
};

const SPECIFICATION = {
  header: 'Specification',
  field: 'specification',
  width: 200,
};

const INACTIVESINCE = {
  header: 'Inactive Since',
  minWidth: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={
          (row?.depreciation_date || row?.scrap_date) &&
          moment(row?.depreciation_date || row?.scrap_date).format('DD MMM YY')
        }
        items={[
          {
            value: `Purchased: ${
              row?.date && moment(row?.date).format('DD MMM YY')
            }`,
          },
        ]}
      />
    );
  },
};

const renderStatus = ({backgroundColor, textcolor, value, rowText}) => {
  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        padding: (2, 4, 2, 4),
        alignItems: 'center',
        borderRadius: 4,
      }}>
      <Text
        style={{
          ...rowText,
          color: textcolor,
        }}>
        {value}
      </Text>
    </View>
  );
};

const STATUS = {
  header: 'Status',
  field: 'status',
  render: ({row, styles: {rowText = {}}}) => {
    const color = useTheme('colors');
    if (row?.status === 'Unassigned') {
      return renderStatus({
        backgroundColor: color.INFORMATION_LOW,
        textcolor: color.INFORMATION_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Assigned') {
      return renderStatus({
        backgroundColor: color.SUCCESS_LOW,
        textcolor: color.SUCCESS_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Unusable') {
      return renderStatus({
        backgroundColor: color.ERROR_LOW,
        textcolor: color.ERROR_HIGH,
        value: row?.status,
        rowText,
      });
    }
    if (row?.status === 'Returned') {
      return renderStatus({
        backgroundColor: color.ACCENT4_LOW,
        textcolor: color.ACCENT4_HIGH,
        value: row?.status,
        rowText,
      });
    }
  },
};

const REASON = {
  header: 'Reason',
  field: 'reason',
  width: 200,
  render: ({row, styles: {rowText = {}}}) => {
    const color = useTheme('colors');
    if (row?.reason === 'To be repaired') {
      return renderStatus({
        backgroundColor: color.INFORMATION_LOW,
        textcolor: color.INFORMATION_HIGH,
        value: row?.reason,
        rowText,
      });
    }
    if (row?.reason === 'Broken & unrepairable') {
      return renderStatus({
        backgroundColor: color.SUCCESS_LOW,
        textcolor: color.SUCCESS_HIGH,
        value: row?.reason,
        rowText,
      });
    }
    if (row?.reason === 'To be donated') {
      return renderStatus({
        backgroundColor: color.ERROR_LOW,
        textcolor: color.ERROR_HIGH,
        value: row?.reason,
        rowText,
      });
    }
  },
};

const REPAIRASSETCODE = {
  header: 'Asset code',
  align: 'center',
  width: 180,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.asset?.code}
        // items={[{value: row?.asset_type}]}
      />
    );
  },
};

const ActiveAllITAsset = props => {
  const {route: {params = {}} = {}, searchValue, addOnFilter = {}} = props;
  const {download} = useAppStateContext();
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {date: 1},
  };
  return (
    <Table
      eventSourceId={['ActiveITAsset']}
      {...fetchProps}
      // addOnFilter={{...addOnFilter}}
      // search={searchValue}
      // searchFields="code"
      // api={`/active-it-assets`}
      // limit={1000}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        PURCHASE,

        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 180},
        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 180,
        },
        LOCATION,
        {
          header: 'Status',
          align: 'center',
          width: 180,
          render: ({row}) => {
            return <PrimaryColumnCard primaryTitle={row?.status} />;
          },
        },
      ]}
    />
  );
};

const RepairHistoryList = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;

  const {download, resourceUrl} = useAppStateContext();
  const fetchProps = {
    api: `/asset-repair-history`,
  };
  return (
    <Table
      eventSourceId={['ActiveITAsset']}
      addOnFilter={{...addOnFilter}}
      sort={{repair_date: -1}}
      search={searchValue}
      api={`/assets/repair-history`}
      onRowPress={row => {
        navigation.navigate('asset-repair-history-detail-from', row);
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Repair date': 'repair_date',
                      'Ticket Id': 'ticket_id',
                      Vendor: 'vendor.name',
                      'Fault type': 'fault_type',
                      'Repair status': 'repair_status',
                      'Repair cost': 'total_repair_cost',
                      'Insurance claim': 'insurance_coverage',
                      'Warranty stauts': 'warranty_status',
                      Description: 'description',
                      Invoice: 'invoice.filename',
                    },
                    filename: 'Repair History',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Repair date',
          field: 'repair_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Ticket ID',
          type: 'text',
          field: 'ticket_id',
        },
        {
          header: 'Vendor',
          type: 'userAvatarChip',
          field: 'vendor',
        },
        {
          header: 'Fault type',
          // type: 'colorTag',
          render: RenderFaultType,
          // field: 'fault_type',
        },
        {
          header: 'Repair status',
          type: 'text',
          field: 'repair_status',
        },
        {
          header: 'Repair cost',
          type: 'currency',
          field: 'total_repair_cost',
        },
        {
          header: 'Insurance claim',
          type: 'text',
          field: 'insurance_coverage',
        },
        {
          header: 'Warranty status',
          type: 'text',
          field: 'warranty_status',
        },
        {
          header: 'Invoice',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {invoice} = row || {};
            if (!invoice) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(invoice);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },
      ]}
    />
  );
};

const ActiveUnassignedITAsset = props => {
  const {GreenBackGroundAdd} = useTheme('icons');
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ActiveITAsset', 'AssetUnusable', 'InactiveITAsset'],
  });
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  const {download, user} = useAppStateContext();
  const {employee} = user;
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {assigned_to_date: 1},
  };

  return (
    <Table
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Warranty due date': 'warranty_due_date',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Available': 'asset_aggrement_available',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.filename',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId={['ActiveITAsset', 'AssetAssign', 'AssetRegister']}
      columns={[
        {
          field: 'assigned_to_date',
          type: 'date',
          header: 'Unassigend since',
          width: 180,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 180},
        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
        },
        LOCATION,
        {
          header: 'Last Assignee',
          width: 380,
          render: ({row}) => {
            const {
              last_assigment,
              assigned_from_date,
              last_assigned_from_date,
            } = row;
            return (
              <UserAvatarWithNameAndEmail
                name={last_assigment?.name}
                color={last_assigment?.color}
                values={[
                  {
                    value: last_assigment?.official_email_id,
                  },
                  {
                    value: last_assigned_from_date
                      ? `Signed on: ${
                          last_assigned_from_date &&
                          moment(last_assigned_from_date).format('DD MMM YY')
                        }`
                      : void 0,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Received by',
          width: 280,
          render: ({row}) => {
            const {recieved_by} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={recieved_by?.name}
                color={recieved_by?.color}
                values={[
                  {
                    value: recieved_by?.official_email_id,
                  },
                ]}
              />
            );
          },
        },

        {
          header: 'Last Aggrement',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {last_asset_aggrement_document} = row || {};
            if (!last_asset_aggrement_document) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(last_asset_aggrement_document);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },

        {
          header: 'Assign',
          width: 100,
          render: ({row}) => {
            return (
              <View
                onPress={() => {
                  navigation.navigate('asset-assignee-form', {row});
                }}>
                <Image
                  style={{width: 16, height: 16}}
                  source={GreenBackGroundAdd}
                />
              </View>
            );
          },
        },
        // PURCHASE,

        // {
        //   header: 'Assign',
        //   width: 250,
        //   render: ({row}) => {
        //     const {last_assigment} = row;
        //     return (
        //       <UserAvatarWithNameAndEmail
        //         name={last_assigment?.name}
        //         color={last_assigment?.color}
        //         values={[
        //           {
        //             value: last_assigment?.official_email_id,
        //           },
        //         ]}
        //       />
        //     );
        //   },
        // },
        // {
        //   header: 'Condition',
        //   field: 'condition',
        //   type: 'text',
        // },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Add Sub Asset',
          onPress: ({row}) => {
            navigation.navigate('add-sub-asset-form', {row});
          },
        },
        {
          title: 'Tagged With',
          onPress: ({row}) => {
            navigation.navigate('tagged-with-asset', {row});
          },
        },
        {
          title: 'Untag asset',
          confirm: {
            title: 'Untag asset',
            message: 'Are you sure you want to untag this asset?',
          },
          message: 'Asset untagged successfully',
          visible: ({row}) => {
            if (row?.taged_with) {
              return true;
            } else {
              return false;
            }
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                taged_with: null,
                parent_asset: null,
              },
            });
          },
        },
        // {
        //   title: 'Update Status',
        //   onPress: ({row}) => {
        //     navigation.navigate('update-asset-status-form', {row});
        //   },
        // },
        {
          title: 'Assignment History',
          onPress: ({row}) => {
            navigation.navigate('asset-assignment-history', {row});
          },
        },
        {
          title: 'Mark Lost',
          confirm: {
            title: 'Mark lost',
            message: 'Are you sure you want to mark lost this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Lost',
                asset_status: 'Inactive',
                depreciation_date: new Date(),
              },
            });
          },
          visible: ({}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1' ||
              employee?._id == '53a437e36dc89c02007cbbf0'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Mark Unusable',
          onPress: ({row}) => {
            navigation.navigate('mark-unusable-form', {row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const ActiveAssignedITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const {user} = useAppStateContext();
  const {employee = {}} = user;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ActiveITAsset', 'AssetUnusable', 'InactiveITAsset'],
  });
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });

  const {download, resourceUrl} = useAppStateContext();
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {assigned_from_date: -1},
  };

  return (
    <Table
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Available': 'asset_aggrement_available',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.filename',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      eventSourceId={['ActiveITAsset', 'AssetRegister']}
      {...fetchProps}
      columns={[
        {
          type: 'date',
          header: 'Assiged on',
          field: 'assigned_from_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        // PURCHASE,
        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 150},
        // BRAND,
        // MODEL,
        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {...SPECIFICATION, width: 150},
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        LOCATION,
        {
          header: 'Assignee',
          width: 280,
          render: ({row}) => {
            const {assigned_to, assigned_from_date} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                values={[
                  {
                    value: assigned_to?.official_email_id,
                  },
                  // {
                  //   value: `Assigned: ${
                  //     assigned_from_date &&
                  //     moment(assigned_from_date).format('DD MMM YY')
                  //   }`,
                  // },
                ]}
              />
            );
          },
        },

        {
          header: 'Aggrement',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {asset_aggrement_document} = row || {};
            if (!asset_aggrement_document) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(asset_aggrement_document);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },
        {
          header: 'Action',
          width: 120,
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <>
                <Confirm
                  title="Mark recevied"
                  message="Are you sure you want to mark recevied this asset?"
                  onConfirm={async () => {
                    await invoke({
                      uri: `/assetinventories/received/${row._id}`,
                      props: {
                        status: 'Unassigned',
                        asset_status: 'Active',
                        assigned_from_date: row?.assigned_from_date,
                        assigned_to_date: row?.assigned_to_date,
                        assigned_to: null,
                        asset_aggrement_document: row?.asset_aggrement_document,
                        recieved_by: employee,
                        last_assigment: row?.assigned_to?._id,
                      },
                    });
                  }}>
                  <TouchableOpacity>
                    <Text style={{...rowText, color: '#007AFF'}}>
                      Mark recevied
                    </Text>
                  </TouchableOpacity>
                </Confirm>
              </>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        // {
        //   title: 'Update Status',
        //   onPress: ({row}) => {
        //     navigation.navigate('update-asset-status-form', {row});
        //   },
        // },
        {
          title: 'Assignment History',
          onPress: ({row}) => {
            navigation.navigate('asset-assignment-history', {row});
          },
        },

        {
          title: 'Add Sub Asset',
          onPress: ({row}) => {
            navigation.navigate('add-sub-asset-form', {row});
          },
        },
        {
          title: 'Tagged With',
          onPress: ({row}) => {
            navigation.navigate('tagged-with-asset', {row});
          },
        },
        // {
        //   title: 'Mark Unusable',
        //   onPress: ({row}) => {
        //     navigation.navigate('mark-unusable-form', {row});
        //   },
        // },
        {
          title: 'Untag asset',
          confirm: {
            title: 'Untag asset',
            message: 'Are you sure you want to untag this asset?',
          },
          message: 'Asset untagged successfully',
          visible: ({row}) => {
            if (row?.taged_with) {
              return true;
            } else {
              return false;
            }
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                taged_with: null,
                parent_asset: null,
              },
            });
          },
        },
        {
          title: 'Mark Lost',
          confirm: {
            title: 'Mark lost',
            message: 'Are you sure you want to mark lost this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Lost',
                asset_status: 'Inactive',
                depreciation_date: new Date(),
              },
            });
          },
          visible: ({}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1' ||
              employee?._id == '53a437e36dc89c02007cbbf0'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Update Aggerement',
          onPress: ({row}) => {
            navigation.navigate('update-asset-aggrement', {row});
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const ActiveUnusableITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['AssetUnusable', 'InactiveITAsset'],
  });

  const {download, user} = useAppStateContext();
  const {employee} = user;
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {unusable_date: 1},
  };
  const {activityLogDetailForAsset} = useAssetActions({
    navigation,
    params,
  });
  return (
    <Table
      eventSourceId={['ActiveITAsset', 'AssetUnusable']}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      sort={{date: -1}}
      columns={[
        {
          type: 'date',
          field: 'unusable_date',
          header: 'Unused since',
          width: 180,
        },

        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 180},
        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,

        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
        },
        PURCHASE,
        LOCATION,
        REASON,
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to mark active this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                asset_status: 'Active',
                status: 'Unassigned',
              },
            });
          },
        },
        {
          title: 'Mark Donated',
          confirm: {
            title: 'Mark Donted',
            message: 'Are you sure you want to mark donate this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Donated',
                asset_status: 'Inactive',
                donated_date: new Date(),
              },
            });
          },
          visible: ({}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Mark scrap',
          confirm: {
            title: 'Mark Scrap',
            message: 'Are you sure you want to mark scrap this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Scrap',
                asset_status: 'Inactive',
                is_scrap: true,
                scrap_date: new Date(),
              },
            });
          },
          visible: ({}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Mark Lost',
          confirm: {
            title: 'Mark Lost',
            message: 'Are you sure you want to mark lost this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Lost',
                asset_status: 'Inactive',
                depreciation_date: new Date(),
              },
            });
          },
          visible: ({}) => {
            if (
              employee?._id == '5db673b0fdfc8569be32aa76' ||
              employee?._id == '53a438926dc89c02007cd37d' ||
              employee?._id == '5a24d508c5eded3207e2873c' ||
              employee?._id == '540458a0dd329b0c9672efc1'
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        {
          title: 'Mark Return',
          confirm: {
            title: 'Mark Return',
            message: 'Are you sure you want to mark return this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Returned',
                asset_status: 'Active',
                asset_returned_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },

        {
          title: 'Raise Repair Request',
          confirm: {
            title: 'Raise Repair Request',
            message: 'Are you sure you want to raise a repair request?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                status: 'Under Repair',
                under_repair_date: new Date(),
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: activityLogDetailForAsset,
        },
      ]}
    />
  );
};

const ActiveUnderRepairITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['AssetUnusable', 'InactiveITAsset'],
  });

  const {download, user} = useAppStateContext();
  const {employee} = user;
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {unusable_date: 1},
  };

  return (
    <Table
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      eventSourceId={['ActiveITAsset', 'AssetUnusable']}
      // addOnFilter={{...addOnFilter}}
      // search={searchValue}
      // searchFields="code"
      // api={`/active-it-assets`}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      sort={{date: -1}}
      columns={[
        {
          type: 'date',
          header: 'Repair since',
          field: 'under_repair_date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 180,
        },
        // PURCHASE,
        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 150},
        // BRAND,
        // MODEL,
        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        {...SPECIFICATION, width: 150},
        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 100,
        },
        PURCHASE,
        LOCATION,
        {
          header: 'Assignee',
          width: 280,
          render: ({row}) => {
            const {assigned_to, assigned_from_date} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                values={[
                  {
                    value: assigned_to?.official_email_id,
                  },
                  // {
                  //   value: `Assigned: ${
                  //     assigned_from_date &&
                  //     moment(assigned_from_date).format('DD MMM YY')
                  //   }`,
                  // },
                ]}
              />
            );
          },
        },

        {
          header: 'Aggrement',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {asset_aggrement_document} = row || {};
            if (!asset_aggrement_document) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(asset_aggrement_document);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Repair History',
          onPress: ({row}) => {
            navigation.navigate('asset-repair-history', {row});
          },
        },
        {
          title: 'Mark Active',
          confirm: {
            title: 'Mark Active',
            message: 'Are you sure you want to mark active this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/${row._id}`,
              props: {
                asset_status: 'Active',
                status: 'Unassigned',
              },
            });
          },
          // visible: ({}) => {
          //   if (
          //     employee?._id == '5db673b0fdfc8569be32aa76' ||
          //     employee?._id == '53a438926dc89c02007cd37d' ||
          //     employee?._id == '5a24d508c5eded3207e2873c' ||
          //     employee?._id == '540458a0dd329b0c9672efc1' ||
          //     employee?._id == '53a437e36dc89c02007cbbf0'
          //   ) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // },
        },
      ]}
    />
  );
};

const ActiveReturnedITAsset = props => {
  const {
    route: {params = {}} = {},
    searchValue,
    addOnFilter = {},
    navigation,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['ActiveITAsset'],
  });
  const {user, download} = useAppStateContext();
  const {employee = {}} = user;
  const fetchProps = {
    addOnFilter: {...addOnFilter},
    search: searchValue,
    searchFields: ['code', 'internal_code', 'serial_no'],
    api: `/active-it-assets`,
    sort: {date: -1},
  };
  return (
    <Table
      // onRowPress={({row}) => {
      //   navigation.navigate('details-asset-register-form', {row});
      // }}
      eventSourceId={['InactiveITAsset', 'ActiveITAsset']}
      {...fetchProps}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    fetchProps,
                    column: {
                      'Internal code': 'internal_code',
                      Type: 'type.name',
                      Specification: 'specification',
                      code: 'code',
                      'Invoice No.': 'invoice_no',
                      Date: 'date',
                      'Serial number': 'serial_no',
                      'Supplier Name': 'payee_id.name',
                      'Invoice Number': 'vendor_invoice.invoice_no',
                      'Bill Number': 'vendor_invoice.bill_number',
                      Account: 'account.name',
                      'Current Amount': 'current_amount',
                      Location: 'location.name',
                      Department: 'department.name',
                      Brand: 'brand',
                      Model: 'model',
                      Organization: 'organization.name',
                      'Assigned To': 'assigned_to.name',
                      'Assigned By': 'assigned_by.name',
                      'Assigned To Email': 'assigned_to.official_email_id',
                      'Assigned On': 'assigned_from_date',
                      Status: 'status',
                      'Tagged With': 'taged_with.code',
                      'Asset Type': 'asset_type',
                      Amount: 'amount',
                      Product: 'product.name',
                      Verified: 'is_verified',
                      'Insurance date': 'asset_insured_on',
                      'Aggrement Documnet Uploaded':
                        'asset_aggrement_document.name',
                      // 'Asset Confirmation Status': 'asset_confirmation',
                      // comment: 'comment_from_employee',
                      // 'Asset Confirmation Date': 'asset_confirmation_date',
                    },
                  },
                },
              }),
          },
        ],
      }}
      sort={{date: -1}}
      columns={[
        {
          header: 'Returned on',
          field: 'asset_returned_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
          width: 180,
        },
        ASSETANDCODE,
        {...ASSETCATEGORYTYPE, width: 180},

        BRANDANDMODEL,
        {
          type: 'custome',
          header: 'Serial no.',
          render: ({row}) => {
            var canvas = createCanvas({
              type: 'svg',
            });

            JsBarcode(canvas, row.code, {
              background: '#f5f5f5',
              width: 1,
              displayValue: true,
            });
            const url = canvas.toDataURL('image/jpeg');

            return (
              <View>
                <a href={url} download>
                  <Image
                    source={url}
                    style={{height: 80, width: 150, objectFit: 'cover'}}
                  />
                </a>
              </View>
            );
          },
          width: 200,
          height: 50,
        },
        SPECIFICATION,

        {
          type: 'currency',
          field: 'current_amount',
          header: 'Value',
          width: 120,
        },
        {
          header: 'Assignee',
          width: 200,
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                values={[
                  {
                    value: assigned_to?.employee_code,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Aggrement',
          align: 'center',
          width: 200,
          render: ({row}) => {
            const {Reimbursement} = useTheme('icons');
            const {asset_aggrement_document} = row || {};
            if (!asset_aggrement_document) return null;

            return (
              <TouchableOpacity
                onPress={() => {
                  const url = resourceUrl(asset_aggrement_document);
                  Linking.openURL(url, '_blank');
                }}>
                <Image source={Reimbursement} width={18} height={18} />
              </TouchableOpacity>
            );
          },
        },
        // STATUS,
        {
          header: 'Action',
          width: 120,
          align: 'center',
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <>
                <Confirm
                  title="Mark recevied"
                  message="Are you sure you want to mark recevied this asset?"
                  onConfirm={async () => {
                    await invoke({
                      uri: `/assetinventories/received/${row._id}`,
                      props: {
                        status: 'Unassigned',
                        asset_status: 'Active',
                        assigned_from_date: row?.assigned_from_date,
                        assigned_to_date: row?.assigned_to_date,
                        assigned_to: null,
                        asset_aggrement_document: row?.asset_aggrement_document,
                        recieved_by: employee?.id,
                        last_assigment: row?.assigned_to?._id,
                      },
                    });
                  }}>
                  <TouchableOpacity>
                    <Text style={{...rowText, color: '#007AFF'}}>
                      Mark recevied
                    </Text>
                  </TouchableOpacity>
                </Confirm>
              </>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Details',
          onPress: ({row}) => {
            navigation.navigate('details-asset-register-form', {row: row});
          },
        },
        {
          title: 'Mark Received',
          confirm: {
            title: 'Mark Received',
            message: 'Are you sure you want to mark received this asset?',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/assetinventories/received/${row._id}`,
              props: {
                status: 'Unassigned',
                asset_status: 'Active',
                assigned_from_date: row?.assigned_from_date,
                assigned_to_date: row?.assigned_to_date,
                assigned_to: null,
                asset_aggrement_document: row?.asset_aggrement_document,
                recieved_by: employee?.id,
                last_assigment: row?.assigned_to?._id,
              },
            });
          },
        },
        {
          title: 'Mark Unusable',
          onPress: ({row}) => {
            navigation.navigate('mark-unusable-form', {row});
          },
        },
      ]}
    />
  );
};

export const ActiveITAssetTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const icons = useTheme('icons');
  const {filter = {}} = filterValues;
  const tabs = {
    unassigned: {
      label: 'Unassigned',
      view: (
        <ActiveUnassignedITAsset
          tab={'unassigned'}
          addOnFilter={{
            ...filter,
            status: {$in: ['Unassigned', null]},
            asset_type: {$in: ['Ready To Use', 'Asset Inventory']},
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetAssign'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: {$in: ['Unassigned', null]},
        asset_type: {$in: ['Ready To Use', 'Asset Inventory']},
      },
      limit: 1000,
    },

    assigned: {
      label: 'Assigned',
      view: (
        <ActiveAssignedITAsset
          tab={'assigned'}
          addOnFilter={{
            ...filter,
            status: 'Assigned',
            asset_type: {$in: ['Asset Inventory', 'Ready To Use']},
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetAssign'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Assigned',
        asset_type: {$in: ['Asset Inventory', 'Ready To Use']},
      },
      limit: 1000,
    },

    unusable: {
      label: 'Unusable',
      view: (
        <ActiveUnusableITAsset
          tab={'unusable'}
          addOnFilter={{...filter, status: 'Unusable'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetUnusable'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Unusable',
      },
      limit: 1000,
    },
    under_repair: {
      label: 'Under repair',
      view: (
        <ActiveUnderRepairITAsset
          tab={'under_repair'}
          addOnFilter={{...filter, status: 'Under Repair'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetUnusable'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Under Repair',
      },
      limit: 1000,
    },
    returned: {
      label: 'Returned',
      view: (
        <ActiveReturnedITAsset
          tab={'returned'}
          addOnFilter={{...filter, status: 'Returned'}}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['InactiveITAsset', 'ActiveITAsset'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        status: 'Returned',
      },
      limit: 1000,
    },
    all: {
      label: 'All',
      view: (
        <ActiveAllITAsset
          tab={'all'}
          addOnFilter={{
            ...filter,
            // asset_status: 'Active',
            // status: {$in: ['Unassigned', null]},
            status: {
              $in: [
                'Unassigned',
                null,
                'Assigned',
                'Unusable',
                'Under Repair',
                'Returned',
              ],
            },
            asset_type: {$in: ['Ready To Use', 'Asset Inventory']},
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetAssign'],
      api: '/active-it-assets',
      search: searchValue,
      searchFields: ['code', 'internal_code', 'serial_no'],
      addOnFilter: {
        ...filter,
        // status: {$in: ['Unassigned', null]},
        status: {
          $in: [
            'Unassigned',
            null,
            'Assigned',
            'Unusable',
            'Under Repair',
            'Returned',
          ],
        },
        asset_type: {$in: ['Ready To Use', 'Asset Inventory']},
      },
      limit: 1000,
    },
    // repair_history: {
    //   label: 'Repair history',
    //   view: (
    //     <RepairHistoryList
    //       tab={'repair_history'}
    //       addOnFilter={{
    //         ...filter,
    //       }}
    //       searchValue={searchValue}
    //       {...props}
    //     />
    //   ),
    //   eventSourceId: ['ActiveITAsset', 'AssetAssign'],
    //   api: '/assets/repair-history',
    //   search: searchValue,
    //   addOnFilter: {
    //     ...filter,
    //   },
    //   actions: [
    //     <GroupFilter
    //       filterValues={filterValues}
    //       applyFilter={applyFilter}
    //       filters={[
    //         {
    //           type: 'date',
    //           field: 'repair_date',
    //           label: 'Repair Date',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Asset',
    //           api: '/assetinventories',
    //           placeholder: 'Select',
    //           suggestionField: 'code',
    //           valueField: 'code',
    //           field: 'asset',
    //         },
    //         {
    //           type: 'autoComplete',
    //           label: 'Vendor',
    //           api: '/vendors',
    //           placeholder: 'Select',
    //           suggestionField: 'name',
    //           valueField: 'name',
    //           field: 'vendor',
    //         },
    //       ]}
    //     />,
    //   ],
    //   limit: 1000,
    // },
  };

  const {download} = useAppStateContext();
  return (
    <TabView
      {...props}
      tabs={tabs}
      params={params}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Asset Type',
              options: ['Ready To Use', 'Asset Inventory', 'WIP'],
              field: 'asset_type',
            },
            {
              type: 'autoComplete',
              label: 'Assigned To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'assigned_to',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Last Assignee',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'last_assigment',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'multiAutoComplete',
              label: 'Location',
              api: '/offices',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'location',
            },
            {
              type: 'autoComplete',
              label: 'Organization',
              api: '/organizations',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'organization',
            },
            {
              type: 'autoComplete',
              label: 'Recieved by',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'recieved_by',
              searchFields: ['name', 'official_email_id', 'employee_code'],
            },
            {
              type: 'autoComplete',
              label: 'Asset Aggrement',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'asset_aggrement_signed',
            },
            {
              type: 'multiAutoComplete',
              label: 'Asset Category',
              api: '/assetMasterCategories',
              searchField: 'name',
              suggestionField: 'name',
              field: 'type',
            },
            {
              type: 'autoComplete',
              label: 'Unusable Reason',
              options: [
                'To be repaired',
                'Broken & unrepairable',
                'To be donated',
              ],
              field: 'reason',
            },
            {
              type: 'autoComplete',
              label: 'OS Type',
              suggestionField: 'label',
              valueField: 'label',
              keyField: 'value',
              options: [
                {label: 'Windows', value: 'Windows'},
                {label: 'Ubuntu', value: 'Ubuntu'},
                {label: 'Others', value: 'Others'},
              ],
              field: 'asset_os_type',
            },
            {
              type: 'autoComplete',
              label: 'Out of warranty',
              options: [
                {label: 'Yes', value: {$in: [null, false]}},
                {label: 'No', value: true},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'warranty_valid',
            },
            {
              type: 'date',
              label: 'Warranty date',
              field: 'warranty_due_date',
            },
            {
              type: 'autoComplete',
              label: 'Insurance',
              options: [
                {label: 'Covered', value: true},
                {label: 'Not covered', value: {$in: [null, false]}},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'is_asset_insured',
            },
            {
              type: 'date',
              label: 'Insurance expiry date',
              field: 'asset_insured_on',
            },
          ]}
        />,
      ]}
    />
  );
};

export const RepairHistoryTab = props => {
  const {route: {params = {}} = {}, navigation} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const icons = useTheme('icons');
  const {filter = {}, plainFilter = {}} = filterValues;

  const tabs = {
    repair_history: {
      label: 'Repair history',
      view: (
        <RepairHistoryList
          tab={'repair_history'}
          addOnFilter={{
            ...filter,
            ...plainFilter,
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: ['ActiveITAsset', 'AssetAssign'],
      api: '/assets/repair-history',
      search: searchValue,
      addOnFilter: {
        ...filter,
        ...plainFilter,
      },
      actions: [
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'date',
              field: 'repair_date',
              label: 'Repair Date',
            },
            {
              type: 'autoComplete',
              label: 'Asset',
              api: '/assetinventories',
              placeholder: 'Select',
              suggestionField: 'code',
              valueField: 'code',
              field: 'asset',
            },
            {
              type: 'autoComplete',
              label: 'Vendor',
              api: '/vendors',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'vendor',
            },
            {
              type: 'multiAutoComplete',
              label: 'Fault Type',
              options: [
                'Screen',
                'Battery',
                'Motherboard',
                'Keyboard',
                'Top Panel',
                'Bottom Panel',
                'Ports',
                'SSD',
                'RAM',
                'Camera',
                'TopCase with Battery',
                'A/B/C/D Cover',
                'Fan',
              ],
              placeholder: 'Select',
              field: 'fault_type',
            },
            {
              type: 'autoComplete',
              label: 'Repair status',
              options: [
                'Pending',
                'Out For Repair',
                'Repaired Asset Recieved',
                'Repair Completed',
              ],
              placeholder: 'Select',
              field: 'repair_status',
            },
          ]}
        />,
      ],
      limit: 1000,
    },
  };

  return <TabView {...props} tabs={tabs} params={params} />;
};

export const AssetCategoryTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="AssetCategory"
      api={`/assetCategory`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-asset-master-category`, {
          asset_category: row?._id,
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Asset Category"
            actions={[
              <AddButton
                title="Asset Category"
                view="add-asset-master-category"
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
        {
          type: 'text',
          field: 'account.name',
          header: 'Account',
        },
      ]}
    />
  );
};
