import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {IssueScreen} from './IssuesTable';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const IssueTabs = props => {
  const {route: {params} = {}, type} = props;
  const {onChangeFilter, searchValue, filterValues, applyFilter} = useFilter(
    {},
  );

  const {module, project, projectlibrary_id} = params;

  const groupBy = useGroupBy({
    options: [
      !module?._id && {
        label: 'Module',
        field: 'module_id',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            const {module} = row || {};
            return <GroupContentItem value={module || 'No Module'} />;
          },
        },
      },
    ],
  });

  const filter = {
    project_id: project?._id,
    module_id: module?._id,
    library_id: projectlibrary_id || null,
    ...filterValues?.filter,
  };

  const filterParams = {
    type,
  };

  const countProps = {
    filter,
    eventSourceId: ['Issue', 'RemoveMappedFeature'],
    search: searchValue,
    searchFields: ['issue', 'code'],
    params: filterParams,
  };

  const backlogFilter = {
    ...filter,
  };

  const architectureTab = {};

  if (type === 'Enhancement') {
    backlogFilter.sub_status = null;
    let architectureFilter = {...filter, sub_status: 'Architecture'};
    architectureTab.architecture = {
      label: 'Architecture',
      view: (
        <IssueScreen
          groupBy={groupBy}
          api={'/issues/backlog'}
          selectedTab={'architecture'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={architectureFilter}
          filterParams={filterParams}
          status={'backlog'}
          subStatus="Architecture"
        />
      ),
      api: '/issues/backlog',
      ...countProps,
      filter: architectureFilter,
    };
  }

  const tabs = {
    backlog: {
      label: 'Backlog',
      view: (
        <IssueScreen
          groupBy={groupBy}
          type={type}
          selectedTab={'backlog'}
          api={'/issues/backlog'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={backlogFilter}
          filterParams={filterParams}
          status={'backlog'}
          subStatus={null}
        />
      ),
      api: '/issues/backlog',
      ...countProps,
      filter: backlogFilter,
    },
    ...architectureTab,
    development: {
      label: 'Development',
      view: (
        <IssueScreen
          groupBy={groupBy}
          type={type}
          selectedTab={'development'}
          status={'active'}
          api={'/issues/development'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      api: '/issues/development',
      ...countProps,
    },

    qa: {
      label: 'QA',
      view: (
        <IssueScreen
          groupBy={groupBy}
          type={type}
          selectedTab={'qa'}
          status={'qa'}
          api={'/issues/qa'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      api: '/issues/qa',
      ...countProps,
    },

    released: {
      label: 'Released',
      view: (
        <IssueScreen
          groupBy={groupBy}
          type={type}
          selectedTab={'released'}
          status={'released'}
          api={'/issues/released'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={filter}
          filterParams={filterParams}
        />
      ),
      api: '/issues/released',
      ...countProps,
    },

    all: {
      label: 'All',
      view: (
        <IssueScreen
          api={'/issues/all'}
          groupBy={groupBy}
          type={type}
          selectedTab={'all'}
          onChangeFilter={onChangeFilter}
          searchValue={searchValue}
          filter={{...filter, type: type}}
        />
      ),
      api: '/projectissues',
      ...countProps,
      filter: {...filter, type},
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        !module?._id && <GroupBy {...groupBy} />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            // {
            //   type: 'autoComplete',
            //   label: `${Labels.Feature}`,
            //   api: '/projectFeatures',
            //   suggestionField: 'feature',
            //   filter: {project_id: params?.project?._id},
            //   field: 'objective_ids',
            //   placeholder: `Select ${Labels.Feature}`,
            // },
            !module?._id && {
              type: 'autoComplete',
              label: 'Module',
              api: '/projectmodules',
              placeholder: 'Select Module',
              suggestionField: 'module',
              valueField: 'module',
              field: 'module_id',
              filter: {project_id: params?.project?._id},
            },
          ]}
        />,
      ]}
    />
  );
};
