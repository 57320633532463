import React from 'react';
import {GroupFilter} from '../../../components/filter/Filters';
import {TabView} from '../../../components/tab/TabView';
import {useFilter} from '../../../controllers/useFilter';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {Col, Image, Text} from '@unthinkable/react-core-components';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import images from '../../../images';
import {useGroupBy} from '../../../controllers/useGroupBy';
import {GroupBy} from '../../../components/table/GroupBy';
import {randomColor} from '../../../utils/util';

const {GreenBackGroundAdd} = images;

export const AllocationsRequestsGroupTable = props => {
  let {
    route: {params},
    navigation,
    filterValues,
    groupBy,
    tab,
  } = props;
  return (
    <Table
      params={{...params, tab}}
      addOnFilter={{...filterValues?.filter}}
      eventSourceId={[
        'orderresourcerequests',
        'replacement',
        'employeeAssigned',
      ]}
      api={`/allocationRequests`}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          render: ({row}) => {
            return (
              <>
                <Tag value={row?.project?.project} color={randomColor()} />
                <PrimaryColumnCard
                  items={[{value: row?.order?.order_number}]}
                />
              </>
            );
          },
          minWidth: 240,
        },
        {
          header: 'Skill',
          field: 'tool.name',
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Type',
          render: ({row, styles: {rowText = {}} = {}}) => {
            let modifyType = '';
            if (row?.status === 'allocated' || row?.status === 'pending') {
              modifyType = 'New';
            } else {
              modifyType = 'Replacement';
            }
            return <Text style={{...rowText}}>{modifyType}</Text>;
          },
        },
        {
          header: 'To Be Free',
          minWidth: 180,
          render: ({row}) => {
            return (
              row?.employee && (
                <Col style={{flexDirection: 'row'}} gap={10}>
                  <UserAvatar value={row?.employee} size={32} />
                  <PrimaryColumnCard
                    primaryTitle={row?.employee?.name}
                    items={[
                      {
                        value: row?.employee?.employee_code,
                      },
                      {
                        value: `${row?.employee?.experience} yr`,
                      },
                    ]}
                  />
                </Col>
              )
            );
          },
        },
        {
          header: 'To Be Assign',
          width: 160,
          render: ({row}) => {
            return row.replacement ? (
              <Col style={{flexDirection: 'row'}} gap={10}>
                <UserAvatar value={row.replacement} size={32} />
                <PrimaryColumnCard
                  primaryTitle={row?.replacement.name}
                  items={[
                    {
                      value: row?.replacement.employee_code,
                    },
                    {
                      value: `${row?.replacement.experience} yr`,
                    },
                  ]}
                />
              </Col>
            ) : (
              tab !== 'completed' && (
                <Image
                  source={GreenBackGroundAdd}
                  style={{width: 36, height: 24}}
                />
              )
            );
          },
          onPress: ({row}) => {
            if (row.status != 'pending') {
              navigation.navigate('update-employee-as-replacement', {
                row,
                formResign: true,
                replacement_id: row._id,
              });
            } else {
              let {
                order = {},
                skill_required = {},
                hours: employee_per_day_working_hour,
                skill_requirement,
                tool,
                from_date,
                to_date,
                project = {},
                project_id = {},
              } = row || {};
              let order_allocations = {
                skill_required,
                order,
                skill_requirement,
                recurring_till: order?.recurring_till,
                order_date: order?.order_date,
                employee_per_day_working_hour,
                product: order?.product,
                tool,
                order_date: from_date,
                recurring_till: to_date,
                project: project?.project || project_id,
              };
              navigation.navigate(
                order?.order_type == 'r'
                  ? 'order-resource-assignment'
                  : 'create-fixed-order-assignment',
                {
                  order_allocations,
                  order_resource_request: row?._id,
                },
              );
            }
          },
        },
        {
          header: 'Status',
          render: ({row, styles: {rowText = {}} = {}}) => {
            let modifyStatus = '';
            let color = '';
            if (row?.status === 'pending') {
              modifyStatus = 'New';
              color = '#FCF4D4';
            }
            if (row?.status === 'due') {
              modifyStatus = 'Due';
              color = '#FFEFE0';
            }
            if (row?.status === 'inprogress') {
              modifyStatus = 'In Progress';
              color = '#DFFBF6';
            }
            if (
              row?.completed_status === 'Successful' ||
              row?.status === 'allocated'
            ) {
              modifyStatus = 'Successful';
              color = '#F2ECFD';
            }
            if (row?.completed_status == 'Failed') {
              modifyStatus = 'Failed';
              color = '#FFECEB';
            }
            return <Tag value={modifyStatus} color={color} />;
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Complete',
          visible: ({row}) => {
            if (row.status === 'inprogress' || row.status === 'due') {
              return true;
            }
          },
          onPress: ({row}) => {
            navigation.navigate('update-replacement-status', {row});
          },
        },
      ]}
    />
  );
};

export const AllocationsRequestsTab = props => {
  const {route: {params} = {}, employeeMenu = false} = props;
  const {department} = params;
  const filterInfo = useFilter({
    filter: {
      'project.department': department,
      'project.project_status': 'a',
    },
  });
  const groupBy = useGroupBy({
    options: [
      {
        label: 'Project',
        field: 'project',
        groupRow: {
          data: '_children',
          defaultExpanded: true,
          leftContent: ({row}) => {
            return (
              <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
                <GroupContentItem
                  value={row?._children?.[0]?.project?.project}
                />
              </RowWithSeparator>
            );
          },
        },
      },
    ],
  });
  const {filterValues} = filterInfo;
  let tabs = {};
  tabs.allocations_requests = {
    label: 'Due Allocations',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="due"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
  };

  tabs.allocations_inprogress = {
    label: 'In Progress',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="inprogress"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
  };

  tabs.completed = {
    label: 'Completed Allocations',
    view: (
      <AllocationsRequestsGroupTable
        groupBy={groupBy}
        filterValues={filterValues}
        tab="completed"
      />
    ),
    addOnFilter: {
      ...filterValues.filter,
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <GroupBy {...groupBy} />,
        <GroupFilter
          {...filterInfo}
          filters={[
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'project',
              valueField: 'project',
              field: 'project',
            },
          ]}
        />,
      ]}
    />
  );
};
