import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {Col, Image, Row, Text, View} from '@unthinkable/react-core-components';
import {
  UserAvatar,
  UserAvatarGroup,
} from '../../../components/avatar/UserAvatar';
import {TextRenderer} from '../../../components/form/Renderers';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {RowWithSeparator} from '@unthinkable/react-layout';
import moment from 'moment';
import {TableHeader} from '../../../components/table/Headers';
import {Tag} from '../../../components/chip/Chip';
import {useTheme} from '@unthinkable/react-theme';
import {GroupFilter} from '../../../components/filter/Filters';
import {useFilter} from '../../../controllers/useFilter';
import {RatingRenderer} from '../../../components/form-editors/rating/RatingInput';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import {IconButton} from '../../../components/button/IconButton';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {getNumberOfLineStyle} from '@unthinkable/react-core-components/src/style-utility';

const RenderTrainee = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'row', alignItems: 'center'}}>
      <UserAvatar value={row?.employee} size={25} />
      <View style={{gap: 4, flexDirection: 'column'}}>
        <TextRenderer value={row?.employee?.name} />
        <GroupContentItem value={row?.employee?.official_email_id} />
        <GroupContentItem value={(row?.employee?.experience || 0) + ' yrs '} />
      </View>
    </View>
  );
};
const RenderBatch = ({row}) => {
  return (
    <View
      title={row.training_batch?.name}
      style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer
        style={{
          ...getNumberOfLineStyle({numberOfLines: 1}),
        }}
        value={row.training_batch?.name}
      />
      <Col>
        <GroupContentItem value={row?.training_batch?.training_program?.name} />
        <GroupContentItem
          value={`Batch ${row?.training_batch?.batch_number}`}
        />
      </Col>
    </View>
  );
};

const RenderType = ({row}) => {
  return (
    <View style={{gap: 4, flexDirection: 'column'}}>
      <TextRenderer
        value={row?.training_batch?.training_program?.course_type}
      />
      <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
        <GroupContentItem value={row?.training_batch?.batch_type} />
      </RowWithSeparator>
    </View>
  );
};

const RenderTrainer = ({row}) => {
  const {mentor, training_batch = {}} = row;
  const {topic_wise_mentors} = training_batch || {};
  let mentors = [mentor];

  if (training_batch?.mentorship_type == 'Topic-wise Trainers') {
    mentors = [...topic_wise_mentors.map(item => item.mentor)];
  }

  return <UserAvatarGroup value={mentors} />;
};

const ExtendedDateInfoRender = props => {
  let {row, onIconPress, styles} = props;
  let {to_date, extended_date} = row || {};
  let {EstChange} = useTheme('icons');
  return (
    <Row gap={1}>
      <Text
        style={{
          ...styles?.rowText,
          color: '#929da9',
        }}>
        {to_date ? moment(extended_date || to_date).format('DD MMM') : ''}
      </Text>
      {extended_date ? (
        <View
          onPress={() => onIconPress({row})}
          style={{marginLeft: 4, height: 16, width: 16}}>
          <Image source={EstChange} />
        </View>
      ) : (
        void 0
      )}
    </Row>
  );
};

const RenderStatus = ({row}) => {
  const valueMap = {
    active: 'Ongoing',
    extended: 'Ongoing',
    completed: 'Completed',
  };
  const colorMap = {
    active: 'Accent_1',
    extended: 'Accent_1',
    completed: 'Success',
  };
  return <Tag value={valueMap[row?.status]} color={colorMap[row?.status]} />;
};

const RenderFeedback = ({row, ...rest}) => {
  const {training_feedbacks = []} = row;
  const sum = training_feedbacks.reduce((acc, one) => {
    return acc + (one.overall_score || 0);
  }, 0);
  const avg_rating = sum / (training_feedbacks.length || 1);
  return (
    <View
      style={{
        alignItems: 'center',
      }}>
      <RatingRenderer value={avg_rating} />
      <TextRenderer
        {...rest}
        value={Number.parseFloat((avg_rating / 5) * 100).toFixed(2) + '%'}
      />
    </View>
  );
};

export const AllTraineesTable = props => {
  const {navigation, status, extraFilter} = props;
  const invoke = useInvoke({
    eventSourceId: ['EmployeeTrainings'],
    method: 'put',
  });
  return (
    <Table
      api="/allTrainees"
      filter={{
        status,
        ...extraFilter,
      }}
      limit={-1}
      eventSourceId={['EmployeeTrainings']}
      columns={[
        {
          header: 'Trainee',
          render: RenderTrainee,
          minWidth: 250,
        },
        {
          header: 'Course Batch',
          render: RenderBatch,
          minWidth: 200,
        },

        // {
        //   header: 'Reason',
        //   field: 'training_batch.reason',
        //   type: 'text',
        //   width: 120,
        // },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'text',
          width: 180,
        },
        {
          header: 'Start',
          field: 'from_date',
          type: 'date',
          width: 75,
          visible: status != 'completed',
        },
        {
          render: ExtendedDateInfoRender,
          header: 'End',
          onIconPress: ({row}) => {
            navigation.navigate('extended_date_details', {
              data: row?.extended_date_details || [],
              header: {
                name: row?.employee?.name,
              },
              training_batch: row.training_batch,
            });
          },
          visible: status != 'completed',
          width: 75,
        },
        {
          header: 'Trainer',
          render: RenderTrainer,
          width: 100,
        },
        // {
        //   header: 'Deployability',
        //   field: 'deployability',
        //   type: 'text',
        //   visible: status == 'completed',
        //   width: 160,
        // },
        // {
        //   header: 'Proficiency',
        //   field: 'proficiency',
        //   type: 'text',
        //   visible: status == 'completed',
        //   width: 140,
        // },
        {
          header: 'Feedback',
          render: RenderFeedback,
          onPress: ({row}) => {
            navigation.navigate('my-feedbacks', {
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
              },
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Drop',
          confirm: {
            title: 'Drop Trainee',
            message: 'Do you really want to drop this trainee?',
            confirmText: 'Drop',
          },
          onPress: ({row}) => {
            invoke({
              uri: '/employeeTrainings/' + row?.employee_training,
              props: {
                status: 'dropped',
              },
            });
          },
          visible: status == 'active',
        },
        {
          title: 'Extend',
          onPress: ({row}) => {
            navigation.navigate('extend-training', {
              ...props.route.params,
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
                to_date: row.to_date,
              },
            });
          },
          visible: status == 'active' || status == 'extended',
        },
        {
          title: 'Complete',
          onPress: ({row}) => {
            navigation.navigate('complete-employee-training', {
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
              },
            });
          },
          visible: status == 'active',
        },
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainees-overview', {
              employee: row.employee,
            }),
        },
      ]}
      {...props}
    />
  );
};

export const ActiveTraineesTable = props => {
  const {navigation, status, extraFilter} = props;
  const invoke = useInvoke({
    eventSourceId: ['EmployeeTrainings'],
    method: 'put',
  });
  return (
    <Table
      api="/allTrainees"
      filter={{
        status: {$in: ['active', 'extended']},
        ...extraFilter,
      }}
      limit={-1}
      eventSourceId={['EmployeeTrainings']}
      columns={[
        {
          header: 'Trainee',
          render: RenderTrainee,
          minWidth: 250,
        },
        {
          header: 'Course Batch',
          render: RenderBatch,
          minWidth: 200,
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'text',
          width: 180,
        },
        {
          header: 'Start',
          field: 'from_date',
          type: 'date',
          width: 75,
          visible: status != 'completed',
        },
        {
          render: ExtendedDateInfoRender,
          header: 'End',
          onIconPress: ({row}) => {
            navigation.navigate('extended_date_details', {
              data: row?.extended_date_details || [],
              header: {
                name: row?.employee?.name,
              },
              training_batch: row.training_batch,
            });
          },
          visible: status != 'completed',
          width: 75,
        },
        {
          header: 'Trainer',
          render: RenderTrainer,
          width: 100,
        },
        {
          header: 'Feedback',
          render: RenderFeedback,
          onPress: ({row}) => {
            navigation.navigate('my-feedbacks', {
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
              },
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Drop',
          confirm: {
            title: 'Drop Trainee',
            message: 'Do you really want to drop this trainee?',
            confirmText: 'Drop',
          },
          onPress: ({row}) => {
            invoke({
              uri: '/employeeTrainings/' + row?.employee_training,
              props: {
                status: 'dropped',
                dropped_on: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        {
          title: 'Extend',
          onPress: ({row}) => {
            navigation.navigate('extend-training', {
              ...props.route.params,
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
                to_date: row.to_date,
              },
            });
          },
        },
        {
          title: 'Complete',
          onPress: ({row}) => {
            navigation.navigate('complete-employee-training', {
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
              },
            });
          },
        },
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainees-overview', {
              employee: row.employee,
            }),
        },
      ]}
      {...props}
    />
  );
};

export const AllCompletedTrainees = props => {
  const {navigation, status, extraFilter} = props;
  return (
    <GroupTable
      {...props}
      filter={{
        status,
        ...extraFilter,
      }}
      api={`/allcompletedtrainees`}
      groupRow={{
        data: 'children',
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Trainings`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Trainee',
          render: RenderTrainee,
          minWidth: 250,
        },
        {
          header: 'Course Batch',
          render: RenderBatch,
          minWidth: 200,
        },
        {
          header: 'Type',
          render: RenderType,
          width: 150,
        },
        {
          header: 'Purpose',
          field: 'training_batch.purpose.name',
          type: 'text',
          width: 180,
        },
        {
          header: 'Start',
          field: 'from_date',
          type: 'date',
          width: 75,
          // visible: status != 'completed',
        },
        {
          render: ExtendedDateInfoRender,
          header: 'End',
          onIconPress: ({row}) => {
            navigation.navigate('extended_date_details', {
              data: row?.extended_date_details || [],
              header: {
                name: row?.employee?.name,
              },
              training_batch: row.training_batch,
            });
          },
          // visible: status != 'completed',
          width: 75,
        },
        {
          header: 'Trainer',
          render: RenderTrainer,
          width: 100,
        },
        {
          header: 'Feedback',
          render: RenderFeedback,
          onPress: ({row}) => {
            navigation.navigate('my-feedbacks', {
              employee_training: {
                _id: row.employee_training,
                employee: row.employee,
              },
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'All Trainings',
          onPress: ({row}) =>
            navigation.navigate('all-trainees-overview', {
              employee: row.employee,
            }),
        },
      ]}
    />
  );
};

const RenderDuration = ({row}) => {
  return (
    <GroupContentItem
      value={`${moment(row.training_batch?.from_date).format(
        'DD MMM YY',
      )} - ${moment(row.training_batch?.to_date).format('DD MMM YY')}`}
    />
  );
};

export const AllTraineesOverview = props => {
  const {
    route: {
      params: {employee},
    },
    navigation,
  } = props;

  const filterProps = useFilter();
  const {
    filterValues: {filter},
  } = filterProps;

  const {download} = useAppStateContext();
  const {Download} = useTheme('icons');

  return (
    <Table
      api="/employeeTrainings"
      renderHeader={() => (
        <TableHeader
          title="All Trainings"
          secondaryTitle={employee.name}
          actions={[
            <GroupFilter
              {...filterProps}
              filters={[
                {
                  label: 'Course Batch',
                  type: 'autoComplete',
                  field: 'training_batch',
                  api: '/trainingBatches',
                  suggestionField: 'name',
                  placeholder: 'Select Course Batch',
                },
              ]}
            />,
            <IconButton
              icon={Download}
              onPress={() => {
                download({
                  uri: '/exportAllTraineesOverview',
                  props: {
                    filter: {employee, ...filter},
                  },
                });
              }}
            />,
          ]}
        />
      )}
      filter={{employee, ...filter}}
      fields={{
        from_date: 1,
        to_date: 1,
        extended_date_details: 1,
        training_batch: {
          name: 1,
          training_program: {
            name: 1,
            course_type: 1,
          },
          skill_metrics: {
            name: 1,
          },
          batch_number: 1,
          mentorship_type: 1,
          topic_wise_mentors: {
            mentor: {
              name: 1,
              color: 1,
            },
          },
          mentor: {
            name: 1,
            color: 1,
          },
          mentors: {
            name: 1,
            color: 1,
          },
        },
        mentor: {
          name: 1,
          color: 1,
        },
        status: 1,
        training_feedbacks: {
          overall_score: 1,
        },
        employee: {
          name: 1,
        },
      }}
      columns={[
        {
          header: 'Course Batch',
          render: RenderBatch,
          minWidth: 200,
        },
        {
          field: 'training_batch.training_program.course_type',
          header: 'Type',
          type: 'colorTag',
          colorField: row =>
            row?.training_batch?.training_program?.course_type == 'Tech'
              ? '#FFF7D6'
              : '#F5E0FF',
          width: 120,
        },
        {
          header: 'Skill',
          field: 'training_batch.skill_metrics.name',
        },
        {
          header: 'Trainer',
          render: RenderTrainer,
          width: 120,
        },
        {render: RenderDuration, header: 'Duration'},
        {header: 'Status', render: RenderStatus},
        {
          header: 'Feedback',
          render: RenderFeedback,
          onPress: ({row}) => {
            navigation.navigate('my-feedbacks', {
              employee_training: row,
              training_batch: row.training_batch,
            });
          },
        },
      ]}
      {...props}
    />
  );
};
