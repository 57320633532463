import React from 'react';
import {BudgetApproverTab} from '../Expense/view/BudgetApproverTab';
import {
  BudgetRequestAccountTab,
  BudgetRequestTab,
} from '../Expense/view/BudgetRequestTab';
import {
  ExpenseApproverInvoiceTab,
  ExpenseApproverTab,
} from '../Expense/view/ExpenseApproverTab';
import {ExpenseRequestTab} from '../Expense/view/ExpenseRequestTab';
import {PurchaseOrderTab} from '../Expense/view/PurchaseOrderTab';
import {
  QuotationApproverFinanceTab,
  QuotationApproverTab,
} from '../Expense/view/QuotationApproverTab';
import {QuotationRequestTab} from '../Expense/view/QuotationRequestTab';
import {CategoryTable} from '../common/views/CategoryTable';
import {ExpenseApproverLimitTab} from '../common/views/ExpenseApproverLimitTable';
import {ReimbursementCategoryTable} from '../common/views/ReimbursementCategoryTable';
import {
  AllVendorsTabView,
  VendorExternalTabView,
  VendorTabView,
  UnapprovedExternalVendorsTabView
} from '../department/views/VendorTabView';
import {DepartmentPerformanceReportTabs} from '../employee/views/DepartmentPerformanceReportTable';
import {ExpenseAgeingTab} from '../employee/views/ExpenseAgeing';
import {
  AccountReimbursementTab,
  COORferredReimbursement,
  SelfReimbursementTab,
  TeamReimbursementTab,
} from '../reimbursement/views/ReimbursementTabs';
import {BankTables} from './view/BankTables';
import {FundTranferTab} from './view/FundTransferTab';
import {OnlineFundTransferTabs} from './view/OnlineFundTransferTab';
import {OnlinePaymentTabs} from './view/OnlinePaymentTabs';
import {PaymentTdsTab} from './view/PaymentTdsTable';
import {SalaryTdsTab} from './view/SalaryTdsTable';
import {BankTransactionTabs} from './view/TransactionTabs';
import {GSTTab, VendorGSTTab} from './view/VendorGstReport';
import {VendorInvoiceTab} from './view/VendorInvoiceTab';
import {PaymentTabs} from './view/VendorPaymentTabs';
import {VendorTdsTab} from './view/VendorTdsReportTable';
import {VoucherTdsTab} from './view/VoucherTdsTable';
import {TransactionCOOTabs} from '../common/views/TransactionCOOTabs';
import {MisSortSummaryReport} from '../Expense/view/MisSortSummaryReport';
import {ExpenseVarianceReport} from './view/ExpenseVarianceReport';
import {PrepaidInvoiceReport} from '../leadGeneration/views/PrepaidInvoiceReport';
import {ProvisionInvoiceReport} from '../leadGeneration/views/ProvisionExpenseReport';
import {AssetCategoryTable} from '../Expense/view/ActiveITAsset';
import {BudgetCategoryTab} from '../common/views/BudgetCategoryTabView';

export const ScreenMapping = [
  {
    id: 'myreimbursement',
    view: <SelfReimbursementTab />,
  },
  {
    id: 'expenserequest',
    view: <ExpenseRequestTab />,
  },
  {
    id: 'budgetrequest',
    view: <BudgetRequestTab />,
  },
  {
    id: 'quotationrequest',
    view: <QuotationRequestTab />,
  },
  {
    id: 'teamreimbursementrequest',
    view: <TeamReimbursementTab />,
  },
  {
    id: 'teamexpenserequest',
    view: props => {
      return <ExpenseApproverTab fromMyView={true} {...props} />;
    },
  },
  {
    id: 'teambudgetrequest',
    view: <BudgetApproverTab />,
  },
  {
    id: 'teamquotationrequest',
    view: props => {
      return <QuotationApproverTab fromMyView={true} {...props} />;
    },
  },
  {
    id: 'orgreimbursementrequest',
    view: <COORferredReimbursement />,
  },
  {
    id: 'orgexpenserequest',
    view: props => {
      return <ExpenseApproverTab reffer_to_coo={true} {...props} />;
    },
  },
  {
    id: 'orgbudgetrequest',
    view: <BudgetApproverTab orgView={true} />,
  },
  {
    id: 'orgquotationrequest',
    view: props => {
      return <QuotationApproverTab reffer_to_coo={true} {...props} />;
    },
  },
  {
    id: 'employee',
    view: <VendorTabView />,
  },
  {
    id: 'externalvendors',
    view: <VendorExternalTabView />,
  },
  {
    id: 'allvendors',
    view: <AllVendorsTabView />,
  },
  {
    id: 'unapprovedVendors',
    view: <UnapprovedExternalVendorsTabView />,
  },
  {
    id: 'budgetrequestaccount',
    view: <BudgetRequestAccountTab />,
  },
  {
    id: 'approved',
    view: <ExpenseApproverInvoiceTab />,
  },
  {
    id: 'approver',
    view: <QuotationApproverFinanceTab />,
  },
  {
    id: 'order',
    view: <PurchaseOrderTab />,
  },
  {
    id: 'reimbursements',
    view: <AccountReimbursementTab />,
  },
  {
    id: 'invoices',
    view: <VendorInvoiceTab />,
  },
  {
    id: 'gstinputreport',
    view: <VendorGSTTab />,
  },
  {
    id: 'prepaidinvoicereport',
    view: <PrepaidInvoiceReport />,
  },
  {
    id: 'provisioninvoicereport',
    view: <ProvisionInvoiceReport />,
  },
  {
    id: 'payment',
    view: <PaymentTabs />,
  },
  {
    id: 'onlinepayments',
    view: <OnlinePaymentTabs />,
  },
  {
    id: 'banktransactions',
    view: <BankTransactionTabs />,
  },
  {
    id: 'backtransactionscoo',
    view: <TransactionCOOTabs />,
  },
  {
    id: 'paymenttdsreport',
    view: <PaymentTdsTab />,
  },
  {
    id: 'fundtransfer',
    view: <FundTranferTab />,
  },
  {
    id: 'OnlineFundTransfer',
    view: <OnlineFundTransferTabs />,
  },
  {
    id: 'bank',
    view: <BankTables />,
  },
  {
    id: 'vendortdsreport',
    view: <VendorTdsTab />,
  },
  {
    id: 'vendortds',
    view: <GSTTab />,
  },
  {
    id: 'vouchertds',
    view: <VoucherTdsTab />,
  },
  {
    id: 'salarytdsreport',
    view: <SalaryTdsTab />,
  },
  {
    id: 'vendorageingreport',
    view: <ExpenseAgeingTab />,
  },
  {
    id: 'departmentPerformance',
    view: <DepartmentPerformanceReportTabs />,
  },
  {
    id: 'expenseapproverlimit',
    view: <ExpenseApproverLimitTab />,
  },
  {
    id: 'reimbursementcategory',
    view: <ReimbursementCategoryTable />,
  },
  {
    id: 'assetcategory',
    view: <AssetCategoryTable />,
  },
  {
    id: 'category',
    view: <CategoryTable />,
  },
  {
    id: 'missortsummaryreport',
    view: <MisSortSummaryReport />,
  },
  {
    id: 'expensevariancereport',
    view: <ExpenseVarianceReport />,
  },
  {
    id: 'budgetCategory',
    view: <BudgetCategoryTab />,
  },
];

// export const Screens = [
//   {
//     menuItemId: 'myrequests',
//     views: [
//       {
//         id: 'myreimbursement',
//         label: 'My Reimbursement',
//         view: <SelfReimbursementTab />,
//       },
//       {
//         id: 'expenserequest',
//         label: 'Expense Request',
//         view: <ExpenseRequestTab />,
//       },
//       {
//         id: 'budgetrequest',
//         label: 'Budget Request',
//         view: <BudgetRequestTab />,
//       },
//       {
//         id: 'quotationrequest',
//         label: 'Quotation Request',
//         view: <QuotationRequestTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'teamrequest',
//     views: [
//       {
//         id: 'teamreimbursementrequest',
//         label: 'Reimbursement Request',
//         view: <TeamReimbursementTab />,
//       },
//       {
//         id: 'teamexpenserequest',
//         label: 'Expense Request',
//         view: props => {
//           return <ExpenseApproverTab  fromMyView= {true} {...props} />;
//         },
//       },
//       {
//         id: 'teambudgetrequest',
//         label: 'Budget Request',
//         view: <BudgetApproverTab />,
//       },
//       {
//         id: 'teamquotationrequest',
//         label: 'Quotation Request',
//         view: props => {
//           return <QuotationApproverTab  fromMyView= {true} {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'orgrequest',
//     views: [
//       {
//         id: 'orgreimbursementrequest',
//         label: 'Reimbursement Request',
//         view: <COORferredReimbursement />,
//       },
//       {
//         id: 'orgexpenserequest',
//         label: 'Expense Request',
//         view: props => {
//           return <ExpenseApproverTab props={{...props, reffer_to_coo: true}} />;
//         },
//       },
//       {
//         id: 'orgbudgetrequest',
//         label: 'Budget Request',
//         view: <BudgetApproverTab />,
//       },
//       {
//         id: 'orgquotationrequest',
//         label: 'Quotation Request',
//         view: props => {
//           return (
//             <QuotationApproverTab props={{...props, reffer_to_coo: true}} />
//           );
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'vendors',
//     views: [
//       {
//         id: 'employee',
//         label: 'Employee',
//         view: <VendorTabView />,
//       },
//       {
//         id: 'externalvendors',
//         label: 'External',
//         view: <VendorExternalTabView />,
//       },
//       {
//         id: 'allvendors',
//         label: 'All',
//         view: <AllVendorsTabView />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'requestaccount',
//     views: [
//       {
//         id: 'budgetrequestaccount',
//         label: 'Budget Request Account',
//         view: <BudgetRequestAccountTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'approvedexpenses',
//     views: [
//       {
//         id: 'approved',
//         label: 'Approved Expenses',
//         view: <ExpenseApproverInvoiceTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'quotationapprover',
//     views: [
//       {
//         id: 'approver',
//         label: 'Quotation Approver',
//         view: <QuotationApproverFinanceTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'purchaseorder',
//     views: [
//       {
//         id: 'order',
//         label: 'Purchase Order',
//         view: <PurchaseOrderTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'accountreimbursements',
//     views: [
//       {
//         id: 'reimbursements',
//         label: 'Account Reimbursements',
//         view: <AccountReimbursementTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'vendorinvoices',
//     views: [
//       {
//         id: 'invoices',
//         label: 'Vendor Invoices',
//         view: <VendorInvoiceTab />,
//       },
//       {
//         id: 'gstinputreport',
//         label: 'GST Input Report',
//         view: <VendorGSTTab />,
//       },
//       // {
//       //   id:"deptExpenseReport",
//       //   label: "Department Expense Report",
//       //   // view:<DeptExpenseReportTab />
//       // }
//     ],
//   },
//   {
//     menuItemId: 'payments',
//     views: [
//       {
//         id: 'payment',
//         label: 'Payment',
//         view: <PaymentTabs />,
//       },
//       {
//         id: 'onlinepayments',
//         label: 'Online Payment',
//         view: <OnlinePaymentTabs />,
//       },
//       {
//         id: 'banktransactions',
//         label: 'Bank Transaction',
//         view: <BankTransactionTabs />,
//       },
//       {
//         id: 'paymenttdsreport',
//         label: 'Payment TDS Report',
//         view: <PaymentTdsTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'funds',
//     views: [
//       {
//         id: 'fundtransfer',
//         label: 'Fund Transfer',
//         view: <FundTranferTab />,
//       },
//       {
//         id: 'OnlineFundTransfer',
//         label: 'Online Fund Transfer',
//         view: <OnlineFundTransferTabs />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'banks',
//     views: [
//       {
//         id: 'bank',
//         label: 'Bank',
//         view: <BankTables />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'vendorreports',
//     views: [
//       {
//         id: 'vendortdsreport',
//         label: 'Vendor TDS Report',
//         view: <VendorTdsTab />,
//       },
//       {
//         id: 'vendortds',
//         label: 'TDS',
//         view: <GSTTab />,
//       },
//       {
//         id: 'vouchertds',
//         label: 'Voucher TDS',
//         view: <VoucherTdsTab />,
//       },
//       {
//         id: 'salarytdsreport',
//         label: 'Salary TDS Report',
//         view: <SalaryTdsTab />,
//       },
//       {
//         id: 'vendorageingreport',
//         label: 'Ageing',
//         view: <ExpenseAgeingTab />,
//       },
//       {
//         id: 'departmentPerformance',
//         label: 'Department Expense Analysis',
//         view: <DepartmentPerformanceReportTable />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'setup',
//     views: [
//       {
//         id: 'expenseapproverlimit',
//         label: 'Expense Approver Limit',
//         view: <ExpenseApproverLimitTab />,
//       },
//       {
//         id: 'reimbursementcategory',
//         label: 'Reimbursement Category',
//         view: <ReimbursementCategoryTable />,
//       },
//       {
//         id: 'category',
//         label: 'Payment Category',
//         view: <CategoryTable />,
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'myrequests',
//     label: 'My Requests',
//     showSingleView: true,
//   },
//   {
//     id: 'teamrequest',
//     label: 'Team Requests',
//     showSingleView: true,
//   },
//   {
//     id: 'orgrequest',
//     label: 'Organization Requests',
//   },
//   {
//     id: 'vendors',
//     label: 'Vendors',
//   },
//   {
//     id: 'requestaccount',
//     label: 'Budgets',
//   },
//   {
//     id: 'quotationapprover',
//     label: 'Quotations',
//   },
//   {
//     id: 'purchaseorder',
//     label: 'Purchase Order',
//   },
//   {
//     id: 'approvedexpenses',
//     label: 'Expenses',
//   },
//   {
//     id: 'accountreimbursements',
//     label: 'Reimbursements',
//   },
//   {
//     id: 'vendorinvoices',
//     label: 'Invoices',
//   },
//   {
//     id: 'payments',
//     label: 'Payment',
//   },
//   {
//     id: 'funds',
//     label: 'Fund Transfer',
//   },
//   {
//     id: 'banks',
//     label: 'Bank',
//   },
//   {
//     id: 'vendorreports',
//     label: 'Reports',
//   },
//   {
//     id: 'setup',
//     label: 'Setup',
//   },
// ];
